import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss'],
    standalone: false
})
export class TeamComponent implements OnInit {


  teamMembers = [
    {
      image: 'assets/_images/client/02.jpg',
      name: 'Daniela Streit',
      description: 'Inhaberin'
    },
    {
      image: 'assets/_images/client/01.jpg',
      name: 'Mitarbeiter',
      description: 'Gute Seele 1'
    },
    {
      image: 'assets/_images/client/03.jpg',
      name: 'Mitarbeiterin',
      description: 'Gute Seele 2'
    },
    {
      image: 'assets/images/hund.jpg',
      name: 'Bello',
      description: 'Hofhund'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
