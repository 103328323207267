<div class="container">
	<div class="row">
		<div class="col-lg-12 col-md-12 col-12">
			<div class="media align-items-center shadow rounded p-4 features">
				<div class="icons m-0 rounded h2 text-primary text-center px-3">
					<a routerLink="/oeffnungszeiten"><i class="uil uil-clock"></i></a>
				</div>
				<div class="content ml-4">
					<h5 class="mb-1 text-dark">Für alle Öffnungszeiten klicken Sie <a routerLink="/oeffnungszeiten">hier</a></h5>
				</div>
			</div>
		</div>
	</div>
</div>
