import {gql} from 'apollo-angular';

import { countryFragments } from './fragments';

export const country = gql`
    query country($id: ObjectID!) {
        country(id: $id) {
            ...Country
        }
    }

    ${countryFragments['country']}
`;

export const countrys = gql`
    query countrys($orderBy: String, $limit: Int){
        countrys(orderBy: $orderBy, limit: $limit){
            ...Country
        }
    }

    ${countryFragments['country']}
`;

export const countrysByHandle = gql`
    query countrysByHandle($handle: String!, $orderBy: String, $limit: Int){
        countrysByHandle(handle: $handle, orderBy: $orderBy, limit: $limit){
            ...Country
        }
    }

    ${countryFragments['country']}
`;
