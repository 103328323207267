import {gql} from 'apollo-angular';
import { housingFragments } from './fragments';
import { housingmodelFragments } from '../housingmodel/fragments';

export const housing = gql`
    query housing($id: ObjectID!) {
        housing(id: $id) {
            ...Housing

            housingmodel{
                ...Housingmodel
            }
        }
    }
    ${housingmodelFragments['housingmodel']}
    ${housingFragments['housing']}
`;

export const housings = gql`
    query housings{
        housings{
            ...Housing

            housingmodel{
                ...Housingmodel
            }
        }
    }
    ${housingmodelFragments['housingmodel']}
    ${housingFragments['housing']}
`;

export const housingsByHandle = gql`
    query housingsByHandle($handle: String!, $orderBy: String, $limit: Int){
        housingsByHandle(handle: $handle, orderBy: $orderBy, limit: $limit){
            ...Housing
        }
    }

    ${housingFragments['housing']}
`;

export const housingsByParam = gql`
    query housingsByParam($param: String!, $orderBy: String, $limit: Int){
        housingsByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Housing
        }
    }

    ${housingFragments['housing']}
`;

export const housingsByQuery = gql`
    query housingsByQuery($query: String!, $orderBy: String, $limit: Int){
        housingsByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...Housing
        }
    }

    ${housingFragments['housing']}
`;


export const housingsByParametersAndQuery = gql`
    query housingsByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        housingsByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Housing
        }
    }

    ${housingFragments['housing']}
`;

