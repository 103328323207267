import { gql } from 'apollo-angular';
import { projectgroupFragments } from './fragments';

export const addProjectgroup = gql`
    mutation addProjectgroup($data: ProjectgroupInput!) {
        addProjectgroup(data: $data) {
            ...Projectgroup
        }
    }

    ${projectgroupFragments['projectgroup']}
`;

export const deleteProjectgroup = gql`
    mutation deleteProjectgroup($id: ObjectID!) {
        deleteProjectgroup(id: $id) {
            ...Projectgroup
        }
    }

    ${projectgroupFragments['projectgroup']}
`;

export const updateProjectgroup = gql`
    mutation updateProjectgroup ($id: ObjectID!, $data: ProjectgroupInput!) {
        updateProjectgroup (id: $id, data: $data) {
            ...Projectgroup
        }
    }

    ${projectgroupFragments['projectgroup']}
`;



