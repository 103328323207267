import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/navigation/queries';
import * as Mutation from '../_graphql/navigation/mutations';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Navigation } from '../types';

@Injectable({
    providedIn: 'root',
})
export class FuseNavigationsService {

    // Private
    //private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _navigation: BehaviorSubject<Navigation | null> = new BehaviorSubject(null);
    private _navigations: BehaviorSubject<Navigation[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _apollo: Apollo,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    /**
     * Getter for navigation
     */
    get navigations$(): Observable<Navigation[]> {
        return this._navigations.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(limit?): Observable<Navigation> {
        return this._apollo.query({
            query    : Query.navigations,
            variables: {
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    console.log('-> result', result);
                    const navigations = result.data.navigations;
                    console.log('-> const navigations', navigations);
                    this._navigations.next(navigations);

                    const _compactNavigation = navigations.find(item => item._id === '61e1558d4eca7f0e304861a3') || [];
                    const _defaultNavigation = navigations.find(item => item._id === '61dfef564eca7f0e3048618c') || [];
                    const _futuristicNavigation = navigations.find(item => item._id === '61e155b94eca7f0e304861a4') || [];
                    const _horizontalNavigation = navigations.find(item => item._id === '61e155c74eca7f0e304861a5') || [];

                    const navigation = {
                        compact   : _compactNavigation?.pages,
                        default   : _defaultNavigation?.pages,
                        futuristic: _futuristicNavigation?.pages,
                        horizontal: _horizontalNavigation?.pages,
                    };
                    // Update the things
                    // console.log('-> const navigation', navigation);
                    this._navigation.next(navigation);
                    // Return the things
                    return navigation;
                }),
            );
    }

}
