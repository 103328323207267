import { gql } from 'apollo-angular';

import { deviceFragments } from './fragments';

export const addDevice = gql`
    mutation addDevice($data: DeviceInput!) {
        addDevice(data: $data) {
            ...Device
        }
    }

    ${deviceFragments['device']}
`;

export const deleteDevice = gql`
    mutation deleteDevice($id: ObjectID!) {
        deleteDevice(id: $id) {
            ...Device
        }
    }

    ${deviceFragments['device']}
`;

export const updateDevice = gql`
    mutation updateDevice ($id: ObjectID!, $data: DeviceInput!) {
        updateDevice (id: $id, data: $data) {
            ...Device
        }
    }

    ${deviceFragments['device']}
`;



