import {Component, OnInit, Input, AfterViewInit, AfterViewChecked} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: false
})
export class HeaderComponent implements OnInit, AfterViewInit, AfterViewChecked {

    @Input() navClass: string;
    sticky = false;

    constructor(
        private router: Router
    ) {
        router.events.forEach((event) => {
            if (event instanceof NavigationEnd) {
                this._activateMenuDropdown();
            }
        });
    }

    isCondensed = false;


    ngAfterViewInit() {
        this._activateMenuDropdown();

        const div = document.querySelector('#topnav');
        this.sticky = div.classList.contains('nav-sticky');
        // console.log('div.classList: ', div.classList);
        // console.log('sticky: ', this.sticky);
    }
    ngAfterViewChecked() {

    }

    ngOnInit(): void {
    }

    _activateMenuDropdown() {
        /**
         * Menu activation reset
         */
        const resetParent = (el) => {
            el.classList.remove('active');
            const parent = el.parentElement;

            /**
             * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
             * We should come up with non hard coded approach
             */
            if (parent) {
                parent.classList.remove('active');
                const parent2 = parent.parentElement;
                if (parent2) {
                    parent2.classList.remove('active');
                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove('active');
                        const parent4 = parent3.parentElement;
                        if (parent4) {
                            const parent5 = parent4.parentElement;
                            parent5.classList.remove('active');
                        }
                    }
                }
            }
        };
        const links = document.getElementsByClassName('nav-link-ref');
        let matchingMenuItem = null;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < links.length; i++) {
            // reset menu
            resetParent(links[i]);
        }
        for (let i = 0; i < links.length; i++) {
            if (window.location.pathname === links[i]['pathname']) {
                matchingMenuItem = links[i];
                break;
            }
        }

        if (matchingMenuItem) {
            matchingMenuItem.classList.add('active');
            const parent = matchingMenuItem.parentElement;

            /**
             * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
             * We should come up with non hard coded approach
             */
            if (parent) {
                parent.classList.add('active');
                const parent2 = parent.parentElement;
                if (parent2) {
                    parent2.classList.add('active');
                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.add('active');
                        const parent4 = parent3.parentElement;
                        if (parent4) {
                            const parent5 = parent4.parentElement;
                            parent5.classList.add('active');
                        }
                    }
                }
            }
        }
    }

    /**
     * Window scroll method
     */
    // tslint:disable-next-line: typedef
    windowScroll() {
        if (
            document.body.scrollTop > 50 ||
            document.documentElement.scrollTop > 50
        ) {
            document.getElementById('topnav').classList.add('nav-sticky');
        } else {
            document.getElementById('topnav').classList.remove('nav-sticky');
        }
        if (document.getElementById('back-to-top')) {
            if (document.body.scrollTop > 100 ||
                document.documentElement.scrollTop > 100) {
                document.getElementById('back-to-top').style.display = 'inline';
            } else {
                document.getElementById('back-to-top').style.display = 'none';
            }
        }
    }

    /**
     * Toggle menu
     */
    toggleMenu() {
        this.isCondensed = !this.isCondensed;
        if (this.isCondensed) {
            document.getElementById('navigation').style.display = 'block';
        } else {
            document.getElementById('navigation').style.display = 'none';
        }
    }

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
        event.preventDefault();
        const nextEl = event.target.nextSibling.nextSibling;
        if (nextEl && !nextEl.classList.contains('open')) {
            const parentEl = event.target.parentNode;
            if (parentEl) {
                parentEl.classList.remove('open');
            }
            nextEl.classList.add('open');
        } else if (nextEl) {
            nextEl.classList.remove('open');
        }
        return false;
    }
}
