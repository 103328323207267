import { gql } from 'apollo-angular';

import { taskFragments } from './fragments';

export const addTask = gql`
    mutation addTask($data: TaskInput!) {
        addTask(data: $data) {
            ...Task
        }
    }

    ${taskFragments['task']}
`;

export const deleteTask = gql`
    mutation deleteTask($id: ObjectID!) {
        deleteTask(id: $id) {
            ...Task
        }
    }

    ${taskFragments['task']}
`;

export const updateTask = gql`
    mutation updateTask ($id: ObjectID!, $data: TaskInput!) {
        updateTask (id: $id, data: $data) {
            ...Task
        }
    }

    ${taskFragments['task']}
`;



