import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import * as Query from '../_graphql/housingmodel/queries';
import * as Mutation from '../_graphql/housingmodel/mutations';
import { Device, Housingmodel } from '../types';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root'
})
export class HousingmodelsService {
    // Private
    private _housingmodel: BehaviorSubject<Housingmodel | null> = new BehaviorSubject(null);
    private _housingmodels: BehaviorSubject<Housingmodel[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private apollo: Apollo
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for housingmodel
     */
    get housingmodel$(): Observable<Housingmodel> {
        return this._housingmodel.asObservable();
    }
    /**
     * Getter for housingmodels
     */
    get housingmodels$(): Observable<Housingmodel[]> {
        return this._housingmodels.asObservable();
    }


    /**
     * Get housingmodel by id
     */
    getHousingmodelById(id: string): Observable<Housingmodel> {
        return this._housingmodels.pipe(
            take(1),
            map((housingmodels) => {
                // Find the housingmodel
                const housingmodel = housingmodels.find(item => item.id === id) || null;
                // Update the housingmodel
                this._housingmodel.next(housingmodel);
                // Return the housingmodel
                return housingmodel;
            }),
            switchMap((housingmodel) => {

                if (!housingmodel) {
                    return throwError('Could not found housingmodel with id of ' + id + '!');
                }

                return of(housingmodel);
            }),
        );
    }
    

    /**
     * ----------------------------------------------------
     * Get All Housingmodels
     * ----------------------------------------------------
     * @method getHousingmodels
     */
    getHousingmodels(param?, orderBy?, limit?): Observable<Housingmodel[]> {
        return this.apollo.query({
            query    : Query.housingmodels,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const housingmodels = result.data.housingmodels;
                    // Update the things
                    this._housingmodels.next(housingmodels);
                    // Return the things
                    return housingmodels;
                }),
            );
    }

    /**
     * Search housingmodels with given query
     *
     * @param query
     */
    getHousingmodelsByHandle(query: string): Observable<Housingmodel[]> {
        return this.apollo.query({
            query    : Query.housingmodelsByHandle,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const housingmodels = result.data.housingmodelsByHandle;
                    // Update the things
                    this._housingmodels.next(housingmodels);
                    // Return the things
                    return housingmodels;
                }),
            );
    }

    /**
     * Search housingmodels with given query
     *
     * @param params
     * @param query
     */
    housingmodelsByParametersAndQuery(params: string[], query: string): Observable<Housingmodel[]> {
        return this.apollo.query({
            query    : Query.housingmodelsByParametersAndQuery,
            variables: {
                params,
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const housingmodels = result.data.housingmodelsByParametersAndQuery;
                    // Update the things
                    this._housingmodels.next(housingmodels);
                    // Return the things
                    return housingmodels;
                }),
            );
    }

    /**
     * Create housingmodel
     *
     * @param housingmodelData
     */
    createHousingmodel(housingmodelData?: Housingmodel): Observable<Housingmodel> {
        const newHousingmodel = {
            id    : FuseMockApiUtils.guid(),
            title : 'Neues Werkzeug',
            serial: null,
            notes : null,
        };
        return this.housingmodels$.pipe(
            take(1),
            switchMap(housingmodels => this.apollo
                .mutate({
                    mutation : Mutation.addHousingmodel,
                    variables: {
                        data: housingmodelData || newHousingmodel,
                    },
                }).pipe(
                    map((result: any) => {
                        const housingmodel: Housingmodel = result.data.addHousingmodel;
                        // Update the housingmodels with the new housingmodel
                        this._housingmodels.next([housingmodel, ...housingmodels]);
                        // Return the new housingmodel
                        return housingmodel;
                    }),
                )),
        );
    }

    /**
     * Update housingmodel
     *
     * @param id
     * @param housingmodelData
     */
    updateHousingmodel(id: string, housingmodelData: Housingmodel): Observable<Housingmodel> {
        return this.housingmodels$
            .pipe(
                take(1),
                switchMap(housingmodels => this.apollo
                    .mutate({
                        mutation : Mutation.updateHousingmodel,
                        variables: {
                            id,
                            data: housingmodelData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedHousingmodel = housingmodelData;
                            // Find the index of the updated housingmodel
                            const index = housingmodels.findIndex(item => item.id === id);

                            // Update the housingmodel
                            const newHousingmodels = _.cloneDeep(housingmodels);
                            newHousingmodels[index] = updatedHousingmodel;

                            // Update the housingmodels
                            this._housingmodels.next(newHousingmodels);

                            // Return the updated housingmodel
                            return updatedHousingmodel;
                        }),
                        switchMap(updatedHousingmodel => this.housingmodel$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {

                                // Update the housingmodel if it's selected
                                this._housingmodel.next(updatedHousingmodel);

                                // Return the updated housingmodel
                                return updatedHousingmodel;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete housingmodel
     * @param id
     */
    trashHousingmodel(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateHousingmodel,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete housingmodel
     * @param id
     */
    untrashHousingmodel(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateHousingmodel,
                variables     : {
                    id,
                    data: {
                        deleted: false,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete the housingmodel
     *
     * @param id
     */
    deleteHousingmodel(id: string): Observable<boolean> {
        return this.housingmodels$.pipe(
            take(1),
            switchMap(housingmodels => this.apollo
                .mutate({
                    mutation: Mutation.deleteHousingmodel,
                    variables: {
                        id
                    }
                }).pipe(
                map((result: any) => {

                    // Find the index of the deleted housingmodel
                    const index = housingmodels.findIndex(item => item.id === id);

                    // Delete the housingmodel
                    housingmodels.splice(index, 1);

                    // Update the housingmodels
                    this._housingmodels.next(housingmodels);

                    // Return the deleted status
                    return true;
                }),
            )),
        );
    }

}
