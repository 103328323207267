import { gql } from 'apollo-angular';
import { navigationFragments } from './fragments';

export const navigations = gql`
    query navigations{
        navigations{
            ...Navigation
        }
    }
    ${navigationFragments['navigation']}
`;
