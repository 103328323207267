import { gql } from 'apollo-angular';
import { tagFragments } from './fragments';

export const addTag = gql`
    mutation addTag($data: TagInput!) {
        addTag(data: $data) {
            ...Tag
        }
    }

    ${tagFragments['tag']}
`;

export const deleteTag = gql`
    mutation deleteTag($id: ObjectID!) {
        deleteTag(id: $id) {
            ...Tag
        }
    }

    ${tagFragments['tag']}
`;

export const updateTag = gql`
    mutation updateTag ($id: ObjectID!, $data: TagInput!) {
        updateTag (id: $id, data: $data) {
            ...Tag
        }
    }

    ${tagFragments['tag']}
`;



