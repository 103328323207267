import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const toolFragments: {
    [ key: string ]: DocumentNode
} = {
    tool: gql`
        fragment Tool on Tool{
            _id
            id
            title
            serial
            notes
        }

    `,
};
