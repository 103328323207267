import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-email-confirmation',
    templateUrl: './email-confirmation.component.html',
    styleUrls: ['./email-confirmation.component.css'],
    standalone: false
})
export class EmailConfirmationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
