import {gql} from 'apollo-angular';
import { mediaFragments } from './fragments';
import { userFragments } from '../user/fragments';

export const mediasByHandle = gql`
    query mediasByHandle($handle: String!, $orderBy: String, $limit: Int){
        mediasByHandle(handle: $handle, orderBy: $orderBy, limit: $limit){
            ...Media
        }
    }

    ${mediaFragments['media']}
`;

export const mediasByParam = gql`
    query mediasByParam($param: String!, $orderBy: String, $limit: Int){
        mediasByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Media
        }
    }

    ${mediaFragments['media']}
`;

export const mediasByQuery = gql`
    query mediasByQuery($query: String!, $orderBy: String, $limit: Int){
        mediasByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...Media
        }
    }

    ${mediaFragments['media']}
`;


export const mediasByParametersAndQuery = gql`
    query mediasByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        mediasByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Media
        }
    }

    ${mediaFragments['media']}
`;


export const medias = gql`
    query medias($orderBy: String, $limit: Int){
        medias(orderBy: $orderBy, limit: $limit){
            ...Media
            createUserId {
                ...UserWithoutContact
            }
        }
    }
    ${mediaFragments['media']}
    ${userFragments['userWithoutContact']}
`;

export const media = gql`
    query media($id: ObjectID!) {
        media(id: $id) {
            ...Media
            createUserId {
                ...UserWithoutContact
            }
        }
    }
    ${mediaFragments['media']}
    ${userFragments['userWithoutContact']}
`;

export const mediasByType = gql`
    query mediasByType($mediatype: String, $orderBy: String, $limit: Int){
        mediasByType(mediatype: $mediatype, orderBy: $orderBy, limit: $limit){
            ...Media

            createUserId {
                ...UserWithoutContact
            }
        }
    }
    ${mediaFragments['media']}
    ${userFragments['userWithoutContact']}
`;
