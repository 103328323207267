import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
// import { SwiperModule } from "swiper";
import { NgxTypedJsModule } from "ngx-typed-js";
import { FlatpickrModule } from "angularx-flatpickr";
import { NgxMasonryModule } from "ngx-masonry";
import { ScrollspyDirective } from "./shared/scrollspy.directive";

import { LoginComponent } from "./auth/login/login.component";
import { RegistrationComponent } from "./auth/registration/registration.component";
import { MasterPageComponent } from "./core/_components/master-page/master-page.component";
import { AuthCoverLoginComponent } from "./auth/auth-cover-login/auth-cover-login.component";
import { AuthCoverRePasswordComponent } from "./auth/auth-cover-re-password/auth-cover-re-password.component";
import { AuthCoverSignupComponent } from "./auth/auth-cover-signup/auth-cover-signup.component";
import { AuthLoginComponent } from "./auth/auth-login/auth-login.component";
import { AuthLoginThreeComponent } from "./auth/auth-login-three/auth-login-three.component";
import { AuthRePasswordComponent } from "./auth/auth-re-password/auth-re-password.component";
import { AuthRePasswordThreeComponent } from "./auth/auth-re-password-three/auth-re-password-three.component";
import { AuthSignupComponent } from "./auth/auth-signup/auth-signup.component";
import { AuthSignupThreeComponent } from "./auth/auth-signup-three/auth-signup-three.component";
import { EmailAlertComponent } from "./email/email-alert/email-alert.component";
import { EmailConfirmationComponent } from "./email/email-confirmation/email-confirmation.component";
import { EmailInvoiceComponent } from "./email/email-invoice/email-invoice.component";
import { EmailPasswordResetComponent } from "./email/email-password-reset/email-password-reset.component";
import { FeatherModule } from "angular-feather";

import { allIcons } from "angular-feather/icons";
import { HeaderComponent } from "./shared/header/header.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { SwitcherComponent } from "./shared/switcher/switcher.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HomeComponent } from "./core/home/home.component";
import { GraphQLModule } from "../graphql.module";
import { AckerbauComponent } from "./core/ackerbau/ackerbau.component";
import { AgrarserviceComponent } from "./core/agrarservice/agrarservice.component";
import { HofcafeComponent } from "./core/hofcafe/hofcafe.component";
import { BilderComponent } from "./core/bilder/bilder.component";
import { BlumenComponent } from "./core/blumen/blumen.component";
import { HofladenComponent } from "./core/hofladen/hofladen.component";
import { OeffnungszeitenComponent } from "./core/oeffnungszeiten/oeffnungszeiten.component";
import { TeamComponent } from "./shared/components/team/team.component";
import { DatenschutzComponent } from "./core/datenschutz/datenschutz.component";
import { ImpressumComponent } from "./core/impressum/impressum.component";
import { OrderByPipe } from "./shared/pipes/orderBy.pipe";
import { SociallinkHofgutComponent } from "./shared/components/sociallink-hofgut/sociallink-hofgut.component";
import { OeffnungszeitenlinkHofgutComponent } from "./shared/components/oeffnungszeitenlink-hofgut/oeffnungszeitenlink-hofgut.component";
import { LightboxModule } from "ngx-lightbox";
import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import { Apollo } from "apollo-angular";
import { provideHttpClient } from "@angular/common/http";

// the second parameter 'fr' is optional
registerLocaleData(localeDe, "de");

@NgModule({
  declarations: [
    OrderByPipe,

    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegistrationComponent,
    MasterPageComponent,
    AuthCoverLoginComponent,
    AuthCoverRePasswordComponent,
    AuthCoverSignupComponent,
    AuthLoginComponent,
    AuthLoginThreeComponent,
    AuthRePasswordComponent,
    AuthRePasswordThreeComponent,
    AuthSignupComponent,
    AuthSignupThreeComponent,
    EmailAlertComponent,
    EmailConfirmationComponent,
    EmailInvoiceComponent,
    EmailPasswordResetComponent,
    SwitcherComponent,
    ScrollspyDirective,
    HomeComponent,
    AckerbauComponent,
    AgrarserviceComponent,
    BilderComponent,
    BlumenComponent,
    HofcafeComponent,
    HofladenComponent,
    OeffnungszeitenComponent,
    TeamComponent,
    DatenschutzComponent,
    ImpressumComponent,
    SociallinkHofgutComponent,
    OeffnungszeitenlinkHofgutComponent
  ],
  imports     : [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    NgxScrollTopModule,
    // RouterModule.forRoot([], { relativeLinkResolution: "legacy" }),
    RouterModule.forRoot([], {}),
    // NgxYoutubePlayerModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    // SwiperModule,
    NgxTypedJsModule,
    FlatpickrModule.forRoot(),
    NgxMasonryModule,
    LightboxModule,
    // Apollo
    GraphQLModule
  ],
  exports     : [
    FeatherModule,
    ScrollspyDirective
  ],
  schemas     : [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers   : [
    Apollo,
    provideHttpClient(),
    { provide: LOCALE_ID, useValue: "de-DE" }
  ],
  bootstrap   : [AppComponent]
})
export class AppModule {
}
