import { gql } from 'apollo-angular';

import { calendarFragments } from './fragments';

export const calendar = gql`
    query calendar($id: ObjectID!) {
        calendar(id: $id) {
            ...Calendar
        }
    }

    ${calendarFragments['calendar']}
`;

export const calendars = gql`
    query calendars($orderBy: String, $limit: Int){
        calendars(orderBy: $orderBy, limit: $limit){
            ...Calendar
        }
    }

    ${calendarFragments['calendar']}
`;

export const calendarsByHandle = gql`
    query calendarsByHandle($handle: String!, $orderBy: String, $limit: Int){
        calendarsByHandle(handle: $handle, orderBy: $orderBy, limit: $limit){
            ...Calendar
        }
    }

    ${calendarFragments['calendar']}
`;
