import { gql } from 'apollo-angular';

import { devicemodelFragments } from './fragments';

export const addDevicemodel = gql`
    mutation addDevicemodel($data: DevicemodelInput!) {
        addDevicemodel(data: $data) {
            ...Devicemodel
        }
    }

    ${devicemodelFragments['devicemodel']}
`;

export const updateDevicemodel = gql`
    mutation updateDevicemodel ($id: ObjectID!, $data: DevicemodelInput!) {
        updateDevicemodel (id: $id, data: $data) {
            ...Devicemodel
        }
    }

    ${devicemodelFragments['devicemodel']}
`;

export const deleteDevicemodel = gql`
    mutation deleteDevicemodel ($id: ObjectID!) {
        deleteDevicemodel (id: $id) {
            ...Devicemodel
        }
    }

    ${devicemodelFragments['devicemodel']}
`;



