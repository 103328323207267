import { gql } from 'apollo-angular';
import { housingFragments } from './fragments';

export const addHousing = gql`
    mutation addHousing($data: HousingInput!) {
        addHousing(data: $data) {
            ...Housing
        }
    }
    ${housingFragments['housing']}
`;

export const updateHousing = gql`
    mutation updateHousing($id: ObjectID!, $data: HousingInput!) {
        updateHousing(id: $id, data: $data) {
            ...Housing
        }
    }
    ${housingFragments['housing']}
`;



export const deleteHousing = gql`
    mutation deleteHousing($id: ObjectID!) {
        deleteHousing(id: $id) {
            ...Housing
        }
    }
${housingFragments['housing']}`;
