import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import * as Query from '../_graphql/tool/queries';
import * as Mutation from '../_graphql/tool/mutations';
import { Device, Tool } from '../types';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root'
})
export class ToolsService {
    // Private
    private _tool: BehaviorSubject<Tool | null> = new BehaviorSubject(null);
    private _tools: BehaviorSubject<Tool[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private apollo: Apollo
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for tool
     */
    get tool$(): Observable<Tool> {
        return this._tool.asObservable();
    }
    /**
     * Getter for tools
     */
    get tools$(): Observable<Tool[]> {
        return this._tools.asObservable();
    }


    /**
     * Get tool by id
     */
    getToolById(id: string): Observable<Tool> {
        return this._tools.pipe(
            take(1),
            map((tools) => {
                // Find the tool
                const tool = tools.find(item => item.id === id) || null;
                // Update the tool
                this._tool.next(tool);
                // Return the tool
                return tool;
            }),
            switchMap((tool) => {

                if (!tool) {
                    return throwError('Could not found tool with id of ' + id + '!');
                }

                return of(tool);
            }),
        );
    }
    

    /**
     * ----------------------------------------------------
     * Get All Tools
     * ----------------------------------------------------
     * @method getTools
     */
    getTools(param?, orderBy?, limit?): Observable<Tool[]> {
        return this.apollo.query({
            query    : Query.tools,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const tools = result.data.tools;
                    // Update the things
                    this._tools.next(tools);
                    // Return the things
                    return tools;
                }),
            );
    }

    /**
     * Search tools with given query
     *
     * @param query
     */
    getToolsByHandle(query: string): Observable<Tool[]> {
        return this.apollo.query({
            query    : Query.toolsByHandle,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const tools = result.data.toolsByHandle;
                    // Update the things
                    this._tools.next(tools);
                    // Return the things
                    return tools;
                }),
            );
    }

    /**
     * Search tools with given query
     *
     * @param params
     * @param query
     */
    toolsByParametersAndQuery(params: string[], query: string): Observable<Tool[]> {
        return this.apollo.query({
            query    : Query.toolsByParametersAndQuery,
            variables: {
                params,
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const tools = result.data.toolsByParametersAndQuery;
                    // Update the things
                    this._tools.next(tools);
                    // Return the things
                    return tools;
                }),
            );
    }

    /**
     * Create tool
     *
     * @param toolData
     */
    createTool(toolData?: Tool): Observable<Tool> {
        const newTool = {
            id    : FuseMockApiUtils.guid(),
            title : 'Neues Werkzeug',
            serial: null,
            notes : null,
        };
        return this.tools$.pipe(
            take(1),
            switchMap(tools => this.apollo
                .mutate({
                    mutation : Mutation.addTool,
                    variables: {
                        data: toolData || newTool,
                    },
                }).pipe(
                    map((result: any) => {
                        const tool: Tool = result.data.addTool;
                        // Update the tools with the new tool
                        this._tools.next([tool, ...tools]);
                        // Return the new tool
                        return tool;
                    }),
                )),
        );
    }

    /**
     * Update tool
     *
     * @param id
     * @param toolData
     */
    updateTool(id: string, toolData: Tool): Observable<Tool> {
        return this.tools$
            .pipe(
                take(1),
                switchMap(tools => this.apollo
                    .mutate({
                        mutation : Mutation.updateTool,
                        variables: {
                            id,
                            data: toolData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedTool = toolData;
                            // Find the index of the updated tool
                            const index = tools.findIndex(item => item.id === id);

                            // Update the tool
                            const newTools = _.cloneDeep(tools);
                            newTools[index] = updatedTool;

                            // Update the tools
                            this._tools.next(newTools);

                            // Return the updated tool
                            return updatedTool;
                        }),
                        switchMap(updatedTool => this.tool$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {

                                // Update the tool if it's selected
                                this._tool.next(updatedTool);

                                // Return the updated tool
                                return updatedTool;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete tool
     * @param id
     */
    trashTool(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateTool,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete tool
     * @param id
     */
    untrashTool(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateTool,
                variables     : {
                    id,
                    data: {
                        deleted: false,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete the tool
     *
     * @param id
     */
    deleteTool(id: string): Observable<boolean> {
        return this.tools$.pipe(
            take(1),
            switchMap(tools => this.apollo
                .mutate({
                    mutation: Mutation.deleteTool,
                    variables: {
                        id
                    }
                }).pipe(
                map((result: any) => {

                    // Find the index of the deleted tool
                    const index = tools.findIndex(item => item.id === id);

                    // Delete the tool
                    tools.splice(index, 1);

                    // Update the tools
                    this._tools.next(tools);

                    // Return the deleted status
                    return true;
                }),
            )),
        );
    }

}
