import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const contactFragments: {
    [ key: string ]: DocumentNode
} = {
    contact: gql`
        fragment Contact on Contact{
            _id
            id
            avatar
            background
            firstName
            lastName
            name
            emails {
                email
                label
            }
            phoneNumbers {
                country
                phoneNumber
                label
            }
            title
            company
            birthday
            address
            notes
            tags
        }

    `,

};
/*
export const oldcontactFragments: {
    [ key: string ]: DocumentNode
} = {
    contact: gql`
        fragment Contact on Contact{
            _id
            id
            customerNumber
            contactArt
            contactType
            title
            anrede
            name
            firstName
            lastName
            ort
            gender
            namenszusatz
            organisation
            position
            kreditorenDebitorenNr
            ZUGFeRD2
            leitwegId
            zahlungsinformation {
                _id
                iban
                steuernummer
                uStId
                bic
                uStBefreit
            }
            konditionen {
                _id
                skontoTage
                skontoProzent
                zahlungsZielTage
                kundenRabatt
                kundenRabattEinheit
            }
            birthday
            emails {
                _id
                title
                value
                etikett
                accessRights
            }
            telefon {
                _id
                title
                value
                etikett
                accessRights
            }
            telefax {
                _id
                title
                value
                etikett
                accessRights
            }
            adressen {
                _id
                strasse
                hausnummer
                zusatz
                plz
                ort
                land
                etikett
                accessRights
            }
            lng
            lat
            tags
            show
            deleted
            createdBy {
                name
            }
            updatedAt
            createdAt
        }

    `,

};
*/
