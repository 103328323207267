import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Device } from '../types';
import { Apollo } from 'apollo-angular';
import * as Query from '../_graphql/device/queries';
import * as Mutation from '../_graphql/device/mutations';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root',
})
export class DevicesService {
    // Private
    private _device: BehaviorSubject<Device | null> = new BehaviorSubject(null);
    private _devices: BehaviorSubject<Device[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private apollo: Apollo,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for device
     */
    get device$(): Observable<Device> {
        return this._device.asObservable();
    }

    /**
     * Getter for devices
     */
    get devices$(): Observable<Device[]> {
        return this._devices.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get devices
     */
    allDevices(param?, orderBy?, limit?): Observable<Device[]> {
        return this.apollo.query({
            query    : Query.devices,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const devices = result.data.devices;
                    // Update the things
                    this._devices.next(devices);
                    // Return the things
                    return devices;
                }),
            );
    }

    /**
     * Search devices with given query
     *
     * @param query
     */
    getDevicesByQuery(query: string): Observable<Device[]> {
        return this.apollo.query({
            query    : Query.devicesByQuery,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const devices = result.data.devicesByQuery;
                    // Update the things
                    this._devices.next(devices);
                    // Return the things
                    return devices;
                }),
            );
    }

    /**
     * Search devices with given query
     *
     * @param params
     * @param query
     */
    devicesByParametersAndQuery(params: string[], query: string): Observable<Device[]> {
        return this.apollo.query({
            query    : Query.devicesByParametersAndQuery,
            variables: {
                params,
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const devices = result.data.devicesByParametersAndQuery;
                    // Update the things
                    this._devices.next(devices);
                    // Return the things
                    return devices;
                }),
            );
    }

    /**
     * Get device by id
     */
    getDeviceById(id: string): Observable<Device> {
        return this._devices.pipe(
            take(1),
            map((devices) => {
                // Find the device
                const device = devices.find(item => item.id === id) || null;
                // Update the device
                this._device.next(device);
                // Return the device
                return device;
            }),
            switchMap((device) => {

                if (!device) {
                    return throwError('Could not found device with id of ' + id + '!');
                }

                return of(device);
            }),
        );
    }

    /**
     * Create device
     */
    createDevice(): Observable<Device> {
        const newDevice = {
            id    : FuseMockApiUtils.guid(),
            serial: null,
            notes : null,
        };
        return this.devices$.pipe(
            take(1),
            switchMap(devices => this.apollo
                .mutate({
                    mutation : Mutation.addDevice,
                    variables: {
                        data: newDevice,
                    },
                }).pipe(
                    map((result: any) => {
                        // console.log('newDevice: ', newDevice);
                        const device: Device = result.data.addDevice;
                        // console.log('device: ', device);
                        // Update the devices with the new device
                        this._devices.next([device, ...devices]);
                        // Return the new device
                        return device;
                    }),
                )),
        );
    }

    /**
     * Update device
     *
     * @param id
     * @param deviceData
     */
    updateDevice(id: string, deviceData: Device): Observable<Device> {
        return this.devices$
            .pipe(
                take(1),
                switchMap(devices => this.apollo
                    .mutate({
                        mutation : Mutation.updateDevice,
                        variables: {
                            // id: id,
                            id,
                            data: deviceData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedDevice = deviceData;
                            // Find the index of the updated device
                            const index = devices.findIndex(item => item._id === id);

                            // Update the device
                            const newDevices = _.cloneDeep(devices);
                            newDevices[index] = updatedDevice;

                            // Update the devices
                            this._devices.next(newDevices);

                            // Return the updated device
                            return updatedDevice;
                        }),
                        switchMap(updatedDevice => this.device$.pipe(
                            take(1),
                            filter(item => item && item._id === id),
                            tap(() => {

                                // Update the device if it's selected
                                this._device.next(updatedDevice);

                                // Return the updated device
                                return updatedDevice;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete the device
     *
     * @param id
     */
    deleteDevice(id: string): Observable<boolean> {
        return this.devices$.pipe(
            take(1),
            switchMap(devices => this._httpClient.delete('api/apps/devices/device', { params: { id } }).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted device
                    const index = devices.findIndex(item => item.id === id);

                    // Delete the device
                    devices.splice(index, 1);

                    // Update the devices
                    this._devices.next(devices);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Delete device
     * @param id
     */
    trashDevice(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateDevice,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                },
                refetchQueries: [{
                    query: Query.devices,
                }],
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete device
     * @param id
     */
    _deleteDevice(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.deleteDevice,
                variables     : {
                    id,
                },
                refetchQueries: [{
                    query: Query.devices,
                }],
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }
}
