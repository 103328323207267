import { gql } from 'apollo-angular';
import { changelogFragments } from './fragments';

export const addChangelog = gql`
    mutation addChangelog($data: ChangelogInput!) {
        addChangelog(data: $data) {
            ...Changelog
        }
    }

    ${changelogFragments['changelog']}
`;

export const updateChangelog = gql`
    mutation updateChangelog($id: ObjectID!, $data: ChangelogInput) {
        updateChangelog(id: $id, data: $data) {
            ...Changelog
        }
    }

    ${changelogFragments['changelog']}
`;

export const deleteChangelog = gql`
    mutation deleteChangelog($id: ObjectID!) {
        deleteChangelog(id: $id) {
            ...Changelog
        }
    }

    ${changelogFragments['changelog']}
`;
