import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/building/queries';
import * as Mutation from '../_graphql/building/mutations';

import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class BuildingService {
	buildings; // List of buildings
	building: any = {};

	constructor(
		private apollo: Apollo,
	) {
	}

	/**
	 * ----------------------------------------------------
	 * Get One Building
	 * ----------------------------------------------------
	 * @method getBuilding
	 */
	getBuilding(id) {
		return this.apollo
			.watchQuery({
				query    : Query.building,
				variables: {
					id,
				},
			})
			.valueChanges
			.pipe(
				map((result: any) => result.data.building),
			);
	}

	/**
	 * ----------------------------------------------------
	 * Get All buildings
	 * ----------------------------------------------------
	 * @method getBuilding
	 */
	getBuildings(username?, param?, orderBy?, limit?) {
		return this.apollo.watchQuery({
			query    : Query.buildings,
			variables: {
				username,
				param,
				orderBy,
				limit,
			},
		})
			.valueChanges
			.pipe(
				map((result: any) => result.data.buildings),
			);
	}

	/**
	 * ----------------------------------------------------
	 * Get All buildings
	 * ----------------------------------------------------
	 * @method getBuilding
	 */
	getBuildingByParam(param, orderBy?, limit?) {
		return this.apollo.watchQuery({
			query    : Query.buildingsByParam,
			variables: {
				param,
				orderBy,
				limit,
			},
		})
			.valueChanges
			.pipe(
				map((result: any) => result.data.buildingsByParam),
			);
	}

	/**
	 * Create building
	 * @param buildingData
	 */
	createBuilding(buildingData) {
		this.apollo
			.mutate({
				mutation      : Mutation.addBuilding,
				variables     : {
					data: buildingData,
				},
				refetchQueries: [{
					query: Query.buildings,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the query', error);
			});
	}

	/**
	 * Update building
	 * @param id
	 * @param buildingData
	 */
	updateBuilding(id, buildingData) {
		this.apollo
			.mutate({
				mutation      : Mutation.updateBuilding,
				variables     : {
					id,
					data: buildingData,
				},
				refetchQueries: [{
					query: Query.buildings,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
				return data;
			}, (error) => {
				console.log(' there was an error sending the query', error);
			});
	}

	/**
	 * Delete building
	 * @param id
	 */
	trashBuilding(id: string) {
		this.apollo
			.mutate({
				mutation      : Mutation.updateBuilding,
				variables     : {
					id,
					data: {
						deleted: true,
					},
				},
				refetchQueries: [{
					query: Query.buildings,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}

	/**
	 * Delete building
	 * @param id
	 */
	/*
	deleteBuilding(id: string) {
		this.apollo
			.mutate({
				mutation: Mutation.deleteBuilding,
				variables: {
					id
				},
				refetchQueries: [ {
					query: Query.buildings
				} ]
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}
	 */

}
