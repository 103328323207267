import {gql} from 'apollo-angular';

import {DocumentNode} from 'graphql';

export const tagFragments: {
    [key: string]: DocumentNode
} = {
    tag: gql`
        fragment Tag on Tag{
            _id
            id
            title
            handle
            color
        }
    `,
};
