<!-- counter Start -->
<section class="section bg-light">
	<div class="container"></div>
</section>
<section class="section">
	<div class="container pt-200">
		<div class="row align-items-center">
			<div class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
				<div class="section-title mr-lg-5">
					<h4 class="title mb-4">
						Herzlich Willkommen bei <span class="text-primary font-weight-bold">Blumen, Kreativem und Dekorativem!</span>
					</h4>
					<p class="text-muted">
						Die Schönheit von Blumen, mit Ihrer Vielfalt an Farben und Formen, als Strauß gebunden, oder auch als Pflanze, haben mich schon als Kind berührt, mich motiviert, den Blumengarten meiner Mutter, als Jugendliche zu gestalten und pflegen und über die Jahre hindurch bis heute dazu geführt, Blumen schließlich selber Anzubauen als Schnittblumen zum selber Schneiden, und auch zugekaufte Blumen vom Händler oder anderen Anbauern anzubieten und kreativ zu verwenden, als Blumenstrauß, Tischdekoration oder zum Kränze binden und Arrangieren
						Wenn Blumen auch Euer Herz berühren, Euch begeistern, faszinieren dann besucht  <br>
						•	unser Blumenfeld zum Blumen selber Schneiden, von Anfang April- Ende September   Anfahrt siehe Skizze unten <br>
						•	unsere Workshops für Kränze, Hoops, (gerne auf Anfrage für Junggesellinnenabschied, sonstige Anlässe, Gruppen ab 5 Personen <br>
						•	Oder lasst Euch eine Blumenstrauß auf Bestellung binden, oder Blumen Mittwochs im Hofladen kaufen </p>
						<h4>Workshops</h4>
					<p>
						Herbst / Winter 23
						22.9.23 Fr <br>
						09.30-11.30 und 17-19.00Uhr Herbstkränze binden <br><br>
						23.9.23 Sa <br>
						09.30-11.30 Herbstkränze binden <br><br>
						06.10.23 Fr <br>
						09.30-11.30 und 17-19.00Uhr Herbstkränze binden <br><br>
						07.10.23 Sa <br>
						9.30-11.30 Herbstkränze binden <br><br>

						Tel: 07738/922852 Dienstag: 7.30-14.00Uhr, Mittwoch: ab 7.00Uhr,

					</p>
					<!-- a href="javascript:void(0)" class="btn btn-outline-primary">Start Now <i
							class="mdi mdi-chevron-right"></i>
					</a -->
				</div>
			</div>
			<!--end col-->

			<div class="col-md-6 order-1 order-md-2">
				<img src="../../../assets/images/willkommen_blumen.jpg" class="img-fluid" alt="">
			</div>
			<!--end col-->
		</div>
		<!--end row-->
	</div>
	<!--end container-->

	<app-sociallink-hofgut></app-sociallink-hofgut>
</section>
<!--end section-->

<section class="section" style="padding-top: 40px">
	<div class="container">
		<div class="row">
			<ul class="col container-filter list-unstyled categories-filter text-center mb-0" id="filter">
				<li class="list-inline-item"><a class="categories border d-block text-dark rounded active"
												(click)="activeCategory('all')"
												[ngClass]="{'active': galleryFilter ==='all'}" data-filter="*">Alle</a>
				</li>
				<li class="list-inline-item" *ngFor="let item of categoryList">
					<a class="categories border d-block text-dark rounded"
					   (click)="activeCategory(item.type)"
					   [ngClass]="{'active': galleryFilter === item.type}"
					>{{item.title}}</a>
				</li>
			</ul>
		</div>
		<!--end row-->

		<div class="row projects-wrapper">
			<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 business" *ngFor="let item of filteredImages | orderBy: 'title'">
				<div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
					<img src="{{item.image}}" class="img-fluid work-image" alt="">
					<div class="card-body">
						<div class="content">
							<a href="javascript:void(0)" class="badge badge-primary mr-1" *ngFor="let type of item.types">{{type}}</a>
							<h5 class="mt-3 text-dark title" [innerHTML]="item.title"></h5>
							<!-- p class="text-muted">This is required when, for example, the final text is not yet
								available.</p>
							<a href="javascript:void(0)" class="text-primary h6">Read More
								<i-feather name="arrow-right" class="fea icon-sm"></i-feather>
							</a -->
						</div>
					</div>
				</div>
			</div>
			<!--end col-->

		</div>
		<!--end row-->
	</div>
	<!--end container-->
</section>
<!--end section-->
