import { gql } from 'apollo-angular';
import { thingFragments } from './fragments';

export const things = gql`
    query things($param: String, $orderBy: String, $limit: Int){
        things(param: $param, orderBy: $orderBy, limit: $limit){
            ...Thing
        }
    }
    
    ${thingFragments['thing']}
`;

export const thingsByTag = gql`
    query thingsByTag($tag: String!, $orderBy: String, $limit: Int){
        thingsByTag(tag: $tag, orderBy: $orderBy, limit: $limit){
            ...Thing
        }
    }
    
    ${thingFragments['thing']}
`;


export const thingsByParam = gql`
    query thingsByParam($param: String!, $orderBy: String, $limit: Int){
        thingsByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Thing
        }
    }
    
    ${thingFragments['thing']}
`;

export const thingsByQuery = gql`
    query thingsByQuery($query: StringOrInt!, $orderBy: String, $limit: Int){
        thingsByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...Thing
        }
    }
    
    ${thingFragments['thing']}
`;

export const thing = gql`
    query thing($id: ObjectID!) {
        thing(id: $id) {
            ...Thing
        }
    }
    
    ${thingFragments['thing']}
`;
