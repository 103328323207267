import { gql } from 'apollo-angular';

import { toolFragments } from './fragments';

export const addTool = gql`
    mutation addTool($data: ToolInput!) {
        addTool(data: $data) {
            ...Tool
        }
    }

    ${toolFragments['tool']}
`;


export const deleteTool = gql`
    mutation deleteTool($id: ObjectID!) {
        deleteTool(id: $id) {
            ...Tool
        }
    }

    ${toolFragments['tool']}
`;


export const updateTool = gql`
    mutation updateTool ($id: ObjectID!, $data: ToolInput!) {
        updateTool (id: $id, data: $data) {
            ...Tool
        }
    }

    ${toolFragments['tool']}
`;



