import { gql } from 'apollo-angular';

import { appFragments } from './fragments';

export const app = gql`
    query app {
        app {
            ...App
        }
    }

    ${appFragments['app']}
`;
