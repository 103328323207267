import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';

/*
Registering Swiper Web Component
*/
import { register } from "swiper/element/bundle";
import { SwiperOptions } from "swiper/types";
register();

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit {
    navClass = 'bg-white';
    currentDate = new Date();
    date = new Date("2023-09-06T22:00:00.000Z")
    meldungEnde = new Date("2024-12-23T22:00:00.000Z")
    ferienBeginn = new Date("2025-02-27T22:00:00.000Z")
    ferienEnde = new Date("2025-03-11T22:00:00.000Z")
    jahresEndeMeldungBeginn = new Date("2024-12-15T22:00:00.000Z")
    jahresEndeMeldungEnde = new Date("2025-01-06T22:00:00.000Z")

    sliderItems = [
        {
            image: '../../../assets/images/slider/slider_1.jpg'
        },
        {
            image: '../../../assets/images/slider/slider_2.jpg'
        },
        {
            image: '../../../assets/images/slider/slider_3.jpg'
        },
        {
            image: '../../../assets/images/slider/slider_4.jpg'
        },
        {
            image: '../../../assets/images/slider/slider_5.jpg'
        },
    ];

    navItems = [
        {
            title: 'Brot',
            path: '/brot',
            image: '../../../assets/images/brot.jpg'
        },
        {
            title: 'Blumen',
            path: '/blumen',
            image: '../../../assets/images/blumen.jpg'
        },
        {
            title: 'Besonderes',
            path: '/besonderes',
            image: '../../../assets/images/besonderes.jpg'
        },
        {
            title: 'Agrarservice',
            path: '/agrarservice',
            image: '../../../assets/images/agrarservice.jpg'
        },
        {
            title: 'Ackerbau',
            path: '/ackerbau',
            image: '../../../assets/images/ackerbau.jpg'
        },
        /*
        {
            title: 'Bilder',
            path: '/bilder',
            image: '../../../assets/images/bilder.jpg'
        },
        */
    ];

    /* public config: SwiperOptions = {
        a11y: {enabled: true},
        direction: 'horizontal',
        autoplay: {
            delay: 8000,
        },
        speed: 700,
        slidesPerView: 1,
        keyboard: true,
        navigation: true,
        pagination: false
    };
     */

    sliderConfig: SwiperOptions = {

    }

    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: false,
        pullDrag: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            900: {
                items: 3
            }
        },
        nav: false
    };


    constructor() {
        console.log('Date: ', this.currentDate);
    }

    ngOnInit(): void {
    }


}


