import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const devicemodelFragments: {
    [ key: string ]: DocumentNode
} = {
    devicemodel: gql`
        fragment Devicemodel on Devicemodel{
            _id
            id
            title
            type
            hersteller
            servicepartner
            klasse
            umdns
            ceKennzeichen
            gsKennzeichen
            stkIntervall
            mtkIntervall
            manuals
            changelog
        }

    `,
};
