import {gql} from 'apollo-angular';

import { priceFragments } from './fragments';

export const price = gql`
  query price($id: ObjectID!) {
    price(id: $id) {
      ...Price
    }
  }

  ${priceFragments['price']}
`;

export const pricesByHandle = gql`
  query pricesByHandle($handle: String!, $orderBy: String, $limit: Int){
    pricesByHandle(handle: $handle, orderBy: $orderBy, limit: $limit){
      ...Price
    }
  }

  ${priceFragments['price']}
`;

export const pricesByParam = gql`
  query pricesByParam($param: String!, $orderBy: String, $limit: Int){
    pricesByParam(param: $param, orderBy: $orderBy, limit: $limit){
      ...Price
    }
  }

  ${priceFragments['price']}
`;

export const pricesByQuery = gql`
  query pricesByQuery($query: String!, $orderBy: String, $limit: Int){
    pricesByQuery(query: $query, orderBy: $orderBy, limit: $limit){
      ...Price
    }
  }

  ${priceFragments['price']}
`;


export const pricesByParametersAndQuery = gql`
  query pricesByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
    pricesByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
      ...Price
    }
  }

  ${priceFragments['price']}
`;

export const prices = gql`
  query prices($orderBy: String, $limit: Int){
    prices(orderBy: $orderBy, limit: $limit){
      ...Price
    }
  }

  ${priceFragments['price']}
`;
