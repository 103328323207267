import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-email-invoice',
    templateUrl: './email-invoice.component.html',
    styleUrls: ['./email-invoice.component.css'],
    standalone: false
})
export class EmailInvoiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
