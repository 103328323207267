<!-- counter Start -->
<section class="section bg-light">
	<div class="container"></div>
</section>
<section class="section">
	<div class="container pt-200">
		<div class="row align-items-center">
			<div class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
				<div class="section-title mr-lg-5">
					<h4 class="title mb-4">
						<span class="text-primary font-weight-bold">Ackerbau</span>
					</h4>
					<p class="text-muted">
						Neben der Futter- und Getreideernte sowie der Kommunaltechnik liegt unser Leistungsschwerpunkt
						auf dem Ackerbau. Dazu zählen Mulchen, Pflügen, Gülleausbringung, Düngestreuen, Grubbern,
						Kreiselegge, Saatbeetkombinationen sowie die Aussaat von Mais und Engsaat. Dank unserer modernen
						Technik und unserem Fachwissen stehen wir Ihnen nicht nur beratend zur Seite, sondern
						unterstützen Ihren landwirtschaftlichen Betrieb leistungsstark und nutzbringend.
					</p>
					<!-- a href="javascript:void(0)" class="btn btn-outline-primary">Start Now <i
							class="mdi mdi-chevron-right"></i>
					</a -->
				</div>
			</div>
			<!--end col-->

			<div class="col-md-6 order-1 order-md-2">
				<img src="../../../assets/images/willkommen_ackerbau.jpg" class="img-fluid" alt="">
			</div>
			<!--end col-->
		</div>
		<!--end row-->
	</div>
	<!--end container-->
</section>
<!--end section-->
