import {gql} from 'apollo-angular';

import { projectgroupFragments } from './fragments';

export const projectgroups = gql`
    query projectgroups($param: StringOrInt, $orderBy: String, $limit: Int){
        projectgroups(param: $param, orderBy: $orderBy, limit: $limit){
            ...Projectgroup
        }
    }

    ${projectgroupFragments['projectgroup']}
`;

export const projectgroupsByTag = gql`
    query projectgroupsByTag($tag: String!, $orderBy: String, $limit: Int){
        projectgroupsByTag(tag: $tag, orderBy: $orderBy, limit: $limit){
            ...Projectgroup
        }
    }

    ${projectgroupFragments['projectgroup']}
`;

export const projectgroupsByQuery = gql`
    query projectgroupsByQuery($query: StringOrInt!, $orderBy: String, $limit: Int){
        projectgroupsByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...Projectgroup
        }
    }

    ${projectgroupFragments['projectgroup']}
`;

export const projectgroupsByParam = gql`
    query projectgroupsByParam($param: StringOrInt!, $orderBy: String, $limit: Int){
        projectgroupsByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Projectgroup
        }
    }

    ${projectgroupFragments['projectgroup']}
`;

export const projectgroupsByParamAndValue = gql`
    query projectgroupsByParamAndValue($param: String!, $value: StringOrInt!, $orderBy: String, $limit: Int){
        projectgroupsByParamAndValue(param: $param, value: $value, orderBy: $orderBy, limit: $limit){
            ...Task
        }
    }
    ${projectgroupFragments['projectgroup']}
`;


export const projectgroupsByParametersAndQuery = gql`
    query projectgroupsByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        projectgroupsByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Task
        }
    }
    ${projectgroupFragments['projectgroup']}
`;

export const projectgroup = gql`
    query projectgroup($id: ObjectID!) {
        projectgroup(id: $id) {
            ...Projectgroup
        }
    }

    ${projectgroupFragments['projectgroup']}
`;
