import { gql } from 'apollo-angular';
import { pageFragments } from './fragments';

export const addPage = gql`
    mutation addPage($data: PageInput!) {
        addPage(data: $data) {
            ...Page
        }
    }

    ${pageFragments['page']}
`;

export const updatePage = gql`
    mutation updatePage($id: ObjectID!, $data: PageInput) {
        updatePage(id: $id, data: $data) {
            ...Page
        }
    }

    ${pageFragments['page']}
`;

export const deletePage = gql`
    mutation deletePage($id: ObjectID!) {
        deletePage(id: $id) {
            ...Page
        }
    }

    ${pageFragments['page']}
`;
