import { gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';

export const appFragments: {
    [ key: string ]: DocumentNode
} = {
    app: gql`
        fragment App on App{
            _id
            id
            title
            popups {
                _id
                title
                beginn
                ende
                media {
                    _id
                    title
                    src
                    alt
                }
            }

        }

    `
};
