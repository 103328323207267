import { gql } from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const taskFragments: {
	[key: string]: DocumentNode
} = {
	task: gql`
        fragment Task on Task{
            _id
            id
            taskType
            type
            title
            notes
            description
            completed
            dueDate
            priority
            start
            end
            duration
            pauses {
                description
                start
                end
                running
                deleted
                users
            }
            users
            tags
            assignedTo
        }

	`,
};
