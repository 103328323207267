import { gql } from 'apollo-angular';
import { housingmodelFragments } from './fragments';

export const addHousingmodel = gql`
    mutation addHousingmodel($data: HousingmodelInput!) {
        addHousingmodel(data: $data) {
            ...Housingmodel
        }
    }
    ${housingmodelFragments['housingmodel']}
`;

export const updateHousingmodel = gql`
    mutation updateHousingmodel($id: ObjectID!, $data: HousingmodelInput!) {
        updateHousingmodel(id: $id, data: $data) {
            ...Housingmodel
        }
    }
    ${housingmodelFragments['housingmodel']}
`;

export const deleteHousingmodel = gql`
    mutation deleteHousingmodel($id: ObjectID!) {
        deleteHousingmodel(id: $id) {
            ...Housingmodel
        }
    }
${housingmodelFragments['housingmodel']}`;
