import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/teaser/queries';
import * as Mutation from '../_graphql/teaser/mutations';

import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class TeasersService {
	teasers; // List of teasers
	teaser: any = {};

	constructor(
		private apollo: Apollo,
	) {
	}

	/**
	 * ----------------------------------------------------
	 * Get One Teaser
	 * ----------------------------------------------------
	 * @method getTeaser
	 */
	getTeaser(id) {
		return this.apollo
			.watchQuery({
				query    : Query.teaser,
				variables: {
					id,
				},
			})
			.valueChanges
			.pipe(
				map((result: any) => result.data.teaser),
			);
	}

	/**
	 * ----------------------------------------------------
	 * Get All teasers
	 * ----------------------------------------------------
	 * @method getTeaser
	 */
	getTeasers(username?, param?, orderBy?, limit?) {
		return this.apollo.watchQuery({
			query    : Query.teasers,
			variables: {
				username,
				param,
				orderBy,
				limit,
			},
		})
			.valueChanges
			.pipe(
				map((result: any) => result.data.teasers),
			);
	}

	/**
	 * Create teaser
	 * @param teaserData
	 */
	createTeaser(teaserData) {
		this.apollo
			.mutate({
				mutation      : Mutation.addTeaser,
				variables     : {
					data: teaserData,
				},
				refetchQueries: [{
					query: Query.teasers,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the query', error);
			});
	}

	/**
	 * Update teaser
	 * @param id
	 * @param teaserData
	 */
	updateTeaser(id, teaserData) {
		this.apollo
			.mutate({
				mutation      : Mutation.updateTeaser,
				variables     : {
					id,
					data: teaserData,
				},
				refetchQueries: [{
					query: Query.teasers,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
				return data;
			}, (error) => {
				console.log(' there was an error sending the query', error);
			});
	}

	/**
	 * Delete teaser
	 * @param id
	 */
	trashTeaser(id: string) {
		this.apollo
			.mutate({
				mutation      : Mutation.updateTeaser,
				variables     : {
					id,
					data: {
						deleted: true,
					},
				},
				refetchQueries: [{
					query: Query.teasers,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}

	/**
	 * Delete teaser
	 * @param id
	 */
	/*
	deleteTeaser(id: string) {
		this.apollo
			.mutate({
				mutation: Mutation.deleteTeaser,
				variables: {
					id
				},
				refetchQueries: [ {
					query: Query.teasers
				} ]
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}
	 */

}
