import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-impressum',
    templateUrl: './impressum.component.html',
    styleUrls: ['./impressum.component.scss'],
    standalone: false
})
export class ImpressumComponent implements OnInit {
    navClass = 'bg-white';

    constructor() {
    }

    ngOnInit(): void {
    }

}
