import {gql} from 'apollo-angular';

import { fileFragments } from './fragments';

export const file = gql`
    query file($id: ObjectID!) {
        file(id: $id) {
            ...File
        }
    }

    ${fileFragments['file']}
`;

export const files = gql`
    query files($orderBy: String, $limit: Int){
        files(orderBy: $orderBy, limit: $limit){
            ...File
        }
    }

    ${fileFragments['file']}
`;

export const filesByCategory = gql`
    query filesByCategory($category: String, $orderBy: String, $limit: Int){
        filesByCategory(category: $category, orderBy: $orderBy, limit: $limit){
            ...File
        }
    }

    ${fileFragments['file']}
`;

export const filesByFolderId = gql`
    query filesByFolderId($folderId: String, $orderBy: String, $limit: Int){
        filesByFolderId(folderId: $folderId, orderBy: $orderBy, limit: $limit){
            ...File
        }
    }

    ${fileFragments['file']}
`;
