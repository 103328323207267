import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';
import { mediaFragments } from '../media/fragments';
import { priceFragments } from '../price/fragments';

export const housingFragments: {
    [ key: string ]: DocumentNode
} = {
    housing: gql`
        fragment Housing on Housing{
            _id
            title
            infos {
                title
                shorttitle
                text
                items {
                    value
                }
            }
            medias{
                ...Media
            }
            preise{
                ...Price
            }
        }

        ${mediaFragments['media']}
        ${priceFragments['price']}
    `,
};
