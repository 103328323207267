import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/article/queries';
import * as Mutation from '../_graphql/article/mutations';

import { map, switchMap } from "rxjs/operators";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { Article } from "../types/article.type";

@Injectable({
	providedIn: 'root',
})
export class ArticlesService {
	articles?: Article[]; // List of articles
	article?: Article;

	
	// Private
	private _article: BehaviorSubject<Article | null> = new BehaviorSubject( null);
	private _articles: BehaviorSubject<Article[] | null> = new BehaviorSubject(null);

	constructor(
		private apollo: Apollo,
	) {
	}
	
	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for article
	 */
	get article$(): Observable<Article | {}> {
		return this._article.asObservable();
	}

	/**
	 * Getter for articles
	 */
	get articles$(): Observable<Article[] | null> {
		return this._articles.asObservable();
	}

	/**
	 * ----------------------------------------------------
	 * Get One Article
	 * ----------------------------------------------------
	 * @method getArticle
	 */
	getArticle(id) {
		return this.apollo
			.watchQuery({
				query    : Query.getArticleDetail,
				variables: {
					id,
				},
			})
			.valueChanges
			.pipe(
				map((result: any) => result.data.getArticleDetail),
			);
	}

	/**
	 * ----------------------------------------------------
	 * Get One Article by Navtitle
	 * ----------------------------------------------------
	 * @method getArticles
	 */
	getArticleByNavtitle(navTitle?: string): Observable<Article> {
		return this.apollo.query({
			query    : Query.articleByNavTitle,
			variables: {
				navTitle
			},
		})
			.pipe(
				map((result: any) => {
					console.log(result)
					const article = result.data.articleByNavTitle;

					// Update the things
					this._article.next(article);
					console.log('article: ', article)
					// Return the things
					return article;
				}),
				switchMap((article) => {

					if (!article) {
						return throwError('Could not found article with thar NavTitle!');
					}

					return of(article);
				}),
			);
	}

	/**
	 * ----------------------------------------------------
	 * Get All articles
	 * ----------------------------------------------------
	 * @method getArticle
	 */
	getArticles(username?: string, param?: string, orderBy?: string, limit?: number) {
		return this.apollo.watchQuery({
			query    : Query.articles,
			variables: {
				username,
				param,
				orderBy,
				limit,
			},
		})
			.valueChanges
			.pipe(
				map((result: any) => result.data.articles),
			);
	}

	/**
	 * Create article
	 * @param articleData
	 */
	createArticle(articleData) {
		this.apollo
			.mutate({
				mutation      : Mutation.addArticle,
				variables     : {
					data: articleData,
				},
				refetchQueries: [{
					query: Query.articles,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the query', error);
			});
	}

	/**
	 * Update article
	 * @param id
	 * @param articleData
	 */
	updateArticle(id, articleData) {
		this.apollo
			.mutate({
				mutation      : Mutation.updateArticle,
				variables     : {
					id,
					data: articleData,
				},
				refetchQueries: [{
					query: Query.articles,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
				return data;
			}, (error) => {
				console.log(' there was an error sending the query', error);
			});
	}

	/**
	 * Delete article
	 * @param id
	 */
	trashArticle(id: string) {
		this.apollo
			.mutate({
				mutation      : Mutation.updateArticle,
				variables     : {
					id,
					data: {
						deleted: true,
					},
				},
				refetchQueries: [{
					query: Query.articles,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}

	/**
	 * Delete article
	 * @param id
	 */
	/*
	deleteArticle(id: string) {
		this.apollo
			.mutate({
				mutation: Mutation.deleteArticle,
				variables: {
					id
				},
				refetchQueries: [ {
					query: Query.articles
				} ]
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}
	 */

}
