import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-bilder',
    templateUrl: './bilder.component.html',
    styleUrls: ['./bilder.component.scss'],
    standalone: false
})
export class BilderComponent implements OnInit {
    navClass = 'bg-white';
    filteredImages;
    galleryFilter = 'all';

    categoryList = [
        {
            title: 'April',
            type: 'april'
        },
        {
            title: 'Mai',
            type: 'mai'
        },
        {
            title: 'Juni',
            type: 'juni'
        },
        {
            title: 'Juli',
            type: 'juli'
        },
        {
            title: 'August',
            type: 'august'
        },
    ];

    imageList = [
        {
            image: 'assets/_images/work/1.jpg',
            title: '200% Extended Facebook Reach',
            variant: 'primary',
            type: 'april',
        },
        {
            image: 'assets/_images/work/2.jpg',
            title: 'SEO Success For Insulation',
            variant: 'warning',
            type: 'mai',
        },
        {
            image: 'assets/_images/work/3.jpg',
            title: 'Mobile-Friendly Campaigns',
            variant: 'success',
            type: 'juni',
        },
        {
            image: 'assets/_images/work/4.jpg',
            title: 'HVAC SEO Campaign',
            variant: 'info',
            type: 'juli',
        },
        {
            image: 'assets/_images/work/5.jpg',
            title: 'Social Media & Small Business',
            variant: 'primary',
            type: 'april',
        },
        {
            image: 'assets/_images/work/6.jpg',
            title: 'PPC Success For HVAC Company',
            variant: 'warning',
            type: 'mai',
        },
        {
            image: 'assets/_images/work/7.jpg',
            title: 'Ecommerce PPC Case Studys',
            variant: 'success',
            type: 'juni',
        },
        {
            image: 'assets/_images/work/8.jpg',
            title: 'Iphone mockup',
            variant: 'info',
            type: 'juli',
        },
        {
            image: 'assets/_images/work/9.jpg',
            title: 'Iphone mockup',
            variant: 'info',
            type: 'august',
        }];

    constructor() {
    }

    ngOnInit(): void {
        this.filteredImages = this.imageList;

    }

    activeCategory(category) {
        this.galleryFilter = category;
        if (this.galleryFilter === 'all') {
            this.filteredImages = this.imageList;
        } else {
            this.filteredImages = this.imageList.filter(x => x.type === this.galleryFilter);
        }
    }

}
