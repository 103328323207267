import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const vehicleFragments: {
    [ key: string ]: DocumentNode
} = {
    vehicle: gql`
        fragment Vehicle on Vehicle{
            _id
            id
            type
            title
            kennzeichen
            rufname
        }

    `,
};
