import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const changelogFragments: {
  [ key: string ]: DocumentNode
} = {
  changelog: gql`
    fragment Changelog on Changelog {
      _id
      objectId
      changes {
        field
        originalField
        changedField
      }
      createdAt
    }`
};
