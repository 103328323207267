import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/tag/queries';
import * as Mutation from '../_graphql/tag/mutations';

import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

import { Tag } from '../types';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root'
})
export class TagsService {
    // Private
    private _tag: BehaviorSubject<Tag | null> = new BehaviorSubject(null);
    private _tags: BehaviorSubject<Tag[] | null> = new BehaviorSubject(null);

    tags: Observable<Tag[]>;
    tag: Observable<Tag>;

    constructor(
        private apollo: Apollo
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for tag
     */
    get tag$(): Observable<Tag> {
        return this._tag.asObservable();
    }
    /**
     * Getter for tags
     */
    get tags$(): Observable<Tag[]> {
        return this._tags.asObservable();
    }


    /**
     * Get tag by id
     */
    getTagById(id: string): Observable<Tag> {
        return this._tags.pipe(
            take(1),
            map((tags) => {
                // Find the tag
                const tag = tags.find(item => item.id === id) || null;
                // Update the tag
                this._tag.next(tag);
                // Return the tag
                return tag;
            }),
            switchMap((tag) => {

                if (!tag) {
                    return throwError('Could not found tag with id of ' + id + '!');
                }

                return of(tag);
            }),
        );
    }
    

    /**
     * ----------------------------------------------------
     * Get All Tags
     * ----------------------------------------------------
     * @method getTags
     */
    getTags(param?, orderBy?, limit?): Observable<Tag[]> {
        return this.apollo.query({
            query    : Query.tags,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const tags = result.data.tags;
                    // Update the things
                    this._tags.next(tags);
                    // Return the things
                    return tags;
                }),
            );
    }

    /**
     * Search tags with given query
     *
     * @param query
     */
    getTagsByHandle(query: string): Observable<Tag[]> {
        return this.apollo.query({
            query    : Query.tagsByHandle,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const tags = result.data.tagsByHandle;
                    // Update the things
                    this._tags.next(tags);
                    // Return the things
                    return tags;
                }),
            );
    }

    /**
     * Create tag
     *
     * @param tagData
     */
    createTag(tagData: Tag): Observable<Tag> {

        return this.tags$.pipe(
            take(1),
            switchMap(tags => this.apollo
                .mutate({
                    mutation : Mutation.addTag,
                    variables: {
                        data: tagData,
                    },
                }).pipe(
                    map((result: any) => {
                        const tag: Tag = result.data.addTag;
                        // Update the tags with the new tag
                        this._tags.next([tag, ...tags]);
                        // Return the new tag
                        return tag;
                    }),
                )),
        );
    }


    /**
     * Update tag
     *
     * @param id
     * @param tagData
     */
    updateTag(id: string, tagData: Tag): Observable<Tag> {
        return this.tags$
            .pipe(
                take(1),
                switchMap(tags => this.apollo
                    .mutate({
                        mutation : Mutation.updateTag,
                        variables: {
                            id,
                            data: tagData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedTag = tagData;
                            // Find the index of the updated tag
                            const index = tags.findIndex(item => item.id === id);

                            // Update the tag
                            const newTags = _.cloneDeep(tags);
                            newTags[index] = updatedTag;

                            // Update the tags
                            this._tags.next(newTags);

                            // Return the updated tag
                            return updatedTag;
                        }),
                        switchMap(updatedTag => this.tag$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {

                                // Update the tag if it's selected
                                this._tag.next(updatedTag);

                                // Return the updated tag
                                return updatedTag;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete tag
     * @param id
     */
    trashTag(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateTag,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete the tag
     *
     * @param id
     */
    deleteTag(id: string): Observable<boolean> {
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this.apollo
                .mutate({
                    mutation: Mutation.deleteTag,
                    variables: {
                        id
                    }
                }).pipe(
                map((result: any) => {

                    // Find the index of the deleted tag
                    const index = tags.findIndex(item => item.id === id);

                    // Delete the tag
                    tags.splice(index, 1);

                    // Update the tags
                    this._tags.next(tags);

                    // Return the deleted status
                    return true;
                }),
            )),
        );
    }

}
