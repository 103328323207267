import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/file/queries';
import * as Mutation from '../_graphql/file/mutations';

import { map } from 'rxjs/operators';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { ContentsService } from './contents.service';
import { Content } from '../types';

const url = environment.serverUri + '/fileUpload';


@Injectable( {
    providedIn: 'root'
} )
export class FileService {
    files; // List of files
    file: any = {};
    content: Content;

    constructor(
        private apollo: Apollo,
        private http: HttpClient,
        private _contentService: ContentsService
    ) {
    }

    /**
     * ----------------------------------------------------
     * Get One pages
     * ----------------------------------------------------
     * @method getPage
     */
    getFile( id ) {
        this.file = this.apollo
            .watchQuery( {
                query: Query.file,
                variables: {
                    id
                }
            } )
            .valueChanges
            .pipe(
                map( ( result: any ) => result.data.file ),
            );
        return this.file;
    }

    /**
     * ----------------------------------------------------
     * Get All files
     * ----------------------------------------------------
     * @method getFiles
     */
    getFiles( orderBy?, limit? ) {
        this.files = this.apollo.watchQuery( {
            query: Query.files,
            variables: {
                orderBy,
                limit
            }
        } )
            .valueChanges
            .pipe(
                map( ( result: any ) => result.data.files ),
            );
        return this.files;
    }

    /**
     * ----------------------------------------------------
     * Get files by Category
     * ----------------------------------------------------
     * @method getFilesByCategory
     */

    // TODO: category soll zu categoryId werden

    getFilesByCategory( category, orderBy?, limit? ) {
        this.files = this.apollo.watchQuery( {
            query: Query.filesByCategory,
            variables: {
                category,
                orderBy,
                limit
            }
        } )
            .valueChanges
            .pipe(
                map( ( result: any ) => result.data.filesByCategory ),
            );
        return this.files;
    }

    /**
     * Create file
     * @param fileData
     */
    createFile( fileData ) {
        return this.apollo
            .mutate( {
                mutation: Mutation.addFile,
                variables: {
                    data: fileData
                },
                refetchQueries: [ {
                    query: Query.files
                } ],
            } );
    }

    /**
     * Update file
     * @param id
     * @param fileData
     */
    updateFile( id, fileData ) {
        this.apollo
            .mutate( {
                mutation: Mutation.updateFile,
                variables: {
                    id,
                    data: fileData,
                },
                optimisticResponse: {},
                refetchQueries: [ {
                    query: Query.files
                } ]
            } )
            .subscribe( ( {data} ) => {
                console.log( 'updateFile: ', data );
            }, ( error ) => {
                console.log( ' there was an error sending the query', error );
            } );
    }

    /**
     * Delete file
     * @param id
     */
    deleteFile( id: string ) {
        this.apollo
            .mutate( {
                mutation: Mutation.deleteFile,
                variables: {
                    id
                },
                refetchQueries: [ {
                    query: Query.files
                } ]
            } )
            .subscribe( ( {data} ) => {
                console.log( data );
            }, ( error ) => {
                console.log( 'there was an error sending the delete query ', error );
            } );
    }

    downloadFile( fileId ): Observable<Blob> {
        const options = {responseType: 'blob' as 'json'};
        // return this.http.get<Blob>('http://localhost:3000/downloads', { responseType: ResponseContentType.Blob });
        // return this.http.get<Blob>(
        // this.baseApiUrl + this.baseTag + '?companyId=' + companyId + '&fileId=' + fileId + '&fileName=' + fileName, options);
        return this.http.get<Blob>( 'http://localhost:3000/downloads&fileId=${String(fileId)}', options );
    }

    public upload( files: Set<File>, uploadFolder?, filetype?, contentID? ): { [ key: string ]: { progress: Observable<number> } } {
        const folder = uploadFolder || 'uploads';
        // this will be the our resulting map
        const status: { [ key: string ]: { progress: Observable<number> } } = {};

        files.forEach( file => {
            // create a new multipart-form for every file
            // console.log( 'upload.service append file: ', file );
            const formData: FormData = new FormData();
            formData.append( 'folder', folder );
            formData.append( 'file', file, file.name );

            // create a http-post request and pass the form
            // tell it to report the upload progress
            const req = new HttpRequest(
                'POST', url, formData, {
                    reportProgress: true,
                }
            );
            // console.log( 'formData', formData );

            // create a new progress-subject for every file
            const progress = new Subject<number>();

            // send the http-request and subscribe for progress-updates
            this.http.request( req )
                .subscribe( event => {
                    if (event.type === HttpEventType.UploadProgress) {

                        // calculate the progress percentage
                        const percentDone = Math.round( 100 * event.loaded / event.total );

                        // pass the percentage into the progress-stream
                        progress.next( percentDone );
                    } else if (event instanceof HttpResponse) {

                        // Close the progress-stream if we get an answer form the API
                        // The upload is complete
                        progress.complete();

                        if (filetype === 'file' && contentID) {
                            this._contentService.getContent( contentID ).subscribe( ( data ) => {
                                this.content = data;
                            } );
                        }

                        // console.log( 'File to write: ', file );

                        const fileData = {
                            title: file.name,
                            filename: file.name,
                            size: file.size,
                            path: '${String(folder)}/{String(file.name)}',
                            mimetype: file.type
                        };

                        // console.log( 'Filedata to write: ', fileData );
                        // Write to Database

                        /*
                         this.createFile( fileData ).subscribe( ( {data} ) => {
                         // @ts-ignore
                         this.file = data.addFile;
                         console.log( 'addFile: ', this.file );
                         console.log( 'this.content: ', this.content );
                         // this.content.files.push( this.file );
                         }, ( error ) => {
                         console.log( 'there was an error sending the query', error );
                         } );
                         */
                    }
                } );

            // Save every progress-observable in a map of all observables
            status[ file.name ] = {
                progress: progress.asObservable()
            };
        } );

        // return the map of progress.observables
        return status;
    }

}
