import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';
import { userFragments } from '../user/fragments';
import { contentFragments } from '../content/fragments';

export const sectionFragments: {
    [ key: string ]: DocumentNode
} = {
    section: gql`
        fragment Section on Section{
            _id
            title
            edit
            show

            contents{
                ...Content
            }
            createdBy {
                ...User
            }
        }

        ${contentFragments['content']}
        ${userFragments['user']}
    `,
};
