import { gql } from 'apollo-angular';
import { thingFragments } from './fragments';

export const addThing = gql`
    mutation addThing($data: ThingInput!) {
        addThing(data: $data) {
            ...Thing
        }
    }
    
    ${thingFragments['thing']}
`;

export const deleteThing = gql`
    mutation deleteThing($id: ObjectID!) {
        deleteThing(id: $id) {
            ...Thing
        }
    }
    
    ${thingFragments['thing']}
`;

export const updateThing = gql`
    mutation updateThing ($id: ObjectID!, $data: ThingInput!) {
        updateThing (id: $id, data: $data) {
            ...Thing
        }
    }
    
    ${thingFragments['thing']}
`;



