import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';
import { userFragments } from '../user/fragments';
import { mediaFragments } from '../media/fragments';

export const locationFragments: {
    [ key: string ]: DocumentNode
} = {
    location: gql`
        fragment Location on Location{
            _id
            title
            lat
            lng
            show
            medias{
                ...Media
            }
            createdBy {
                ...User
            }
        }

        ${mediaFragments['media']}
        ${userFragments['user']}
    `,
};
