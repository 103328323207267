import { gql } from 'apollo-angular';
import { tagFragments } from './fragments';

export const tag = gql`
    query tag($id: ObjectID!) {
        tag(id: $id) {
            ...Tag
        }
    }
    
    ${tagFragments['tag']}
`;

export const tags = gql`
    query tags($orderBy: String, $limit: Int){
        tags(orderBy: $orderBy, limit: $limit){
            ...Tag
        }
    }
    
    ${tagFragments['tag']}
`;

export const tagsByHandle = gql`
    query tagsByHandle($handle: String!, $orderBy: String, $limit: Int){
        tagsByHandle(handle: $handle, orderBy: $orderBy, limit: $limit){
            ...Tag
        }
    }
    
    ${tagFragments['tag']}
`;
