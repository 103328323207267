import {gql} from 'apollo-angular';
import { deviceFragments } from './fragments';

export const devices = gql`
    query devices($orderBy: String, $limit: Int){
        devices(orderBy: $orderBy, limit: $limit){
            ...Device
        }
    }

    ${deviceFragments['device']}
`;

export const devicesByHandle = gql`
    query devicesByHandle($handle: String!, $orderBy: String, $limit: Int){
        devicesByHandle(handle: $handle, orderBy: $orderBy, limit: $limit){
            ...Device
        }
    }

    ${deviceFragments['device']}
`;

export const devicesByParam = gql`
    query devicesByParam($param: String!, $orderBy: String, $limit: Int){
        devicesByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Device
        }
    }

    ${deviceFragments['device']}
`;

export const devicesByQuery = gql`
    query devicesByQuery($query: String!, $orderBy: String, $limit: Int){
        devicesByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...Device
        }
    }

    ${deviceFragments['device']}
`;


export const devicesByParametersAndQuery = gql`
    query devicesByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        devicesByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Device
        }
    }

    ${deviceFragments['device']}
`;

export const device = gql`
    query device($id: ObjectID!) {
        device(id: $id) {
            ...Device
        }
    }

    ${deviceFragments['device']}
`;
