import {gql} from 'apollo-angular';

import {DocumentNode} from 'graphql';
import {userFragments} from '../user/fragments';
import {tagFragments} from '../tag/fragments';
import {mediaFragments} from '../media/fragments';
import {categoryFragments} from '../category/fragments';

export const eventFragments: {
    [key: string]: DocumentNode
} = {
    event: gql`
        fragment Event on Event{
            _id
            id
            calendarId
            title
            category
            text
            articletext
            image
            sorting
            datetimeArchiv
            seasons
            start
            end
            color {
                primary
                secondary
            }
            actions {
                label
                cssClass
                a11yLabel
            }
            allDay
            cssClass
            resizable {
                beforeStart
                afterEnd
            }
            draggable
            meta {
                location
                notes
            }
            show
            veranstalter
            wetter

            categories {
                ...Category
            }
            tags {
                ...Tag
            }
            artikel{
                _id
                title
                subtitle
                text
                medias{
                    ...Media
                }
            }
            media{
                ...Media
            }
            medias{
                ...Media
            }
            createUserId {
                ...User
            }
        }

        ${categoryFragments['category']}
        ${tagFragments['tag']}
        ${mediaFragments['media']}
        ${userFragments['user']}
    `,
};
