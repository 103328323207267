import {gql} from 'apollo-angular';

import { buildingFragments } from './fragments';

export const buildings = gql`
    query buildings($orderBy: String, $limit: Int){
        buildings(orderBy: $orderBy, limit: $limit){
            ...Building
        }
    }

    ${buildingFragments['building']}
`;

export const buildingsByParam = gql`
    query buildingsByParam($param: String!, $orderBy: String, $limit: Int){
        buildingsByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Building
        }
    }

    ${buildingFragments['building']}
`;

export const buildingsByQuery = gql`
    query buildingsByQuery($query: String!, $orderBy: String, $limit: Int){
        buildingsByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...Building
        }
    }

    ${buildingFragments['building']}
`;


export const buildingsByParametersAndQuery = gql`
    query buildingsByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        buildingsByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Building
        }
    }

    ${buildingFragments['building']}
`;

export const building = gql`
    query building($id: ObjectID!) {
        building(id: $id) {
            ...Building
        }
    }

    ${buildingFragments['building']}
`;
