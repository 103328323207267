import { HttpLink } from "apollo-angular/http";
import { APOLLO_OPTIONS } from "apollo-angular";
import { ApolloLink, InMemoryCache, split } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { NgModule } from "@angular/core";


import { HttpHeaders } from "@angular/common/http";
import { environment } from "./environments/environment";

const port = environment.port;
const uri = environment.server_uri;

const graphql_uri = environment.graphql_uri;
const graphql_endPoint = environment.graphql_endPoint;

const websocket_uri = environment.websocket_uri;

export function provideApollo(
  httpLink: HttpLink
) {

  const token = localStorage.getItem("token");
  const authorization = token ? `Bearer ${token}` : null;

  const headers = new HttpHeaders();
  headers.append("Authorization", authorization);

  const http = httpLink.create({
    uri: graphql_endPoint
    // headers
  });

  const ws = new WebSocketLink({
    uri    : websocket_uri,
    options: {
      reconnect       : true,
      connectionParams: {
        authToken: localStorage.getItem(token)
      }
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        }
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const omitTypename = (key, value) => {
    return key === "__typename" ? undefined : value;
  };

  const omitTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename
      );
    }
    return forward(operation);
  });

  const httpLinkWithErrorHandling = ApolloLink.from([
    errorLink,
    omitTypenameLink,
    http
  ]);

  // Get the authentication token from local storage if it exists

  const auth = setContext(() => ({
    headers: {
      Accept       : "charset=utf-8",
      Authorization: `Bearer ${token}`
    }
  }));

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    }
    , ws
    , ApolloLink.from([
      auth,
      httpLinkWithErrorHandling
    ])
  );

  return {
    link,
    cache: new InMemoryCache()
  };
}

@NgModule({
  exports  : [
    // HttpClientModule
    // ApolloModule,
    // HttpLinkModule
  ],
  providers: [{
    provide   : APOLLO_OPTIONS,
    useFactory: provideApollo,
    deps      : [
      HttpLink
    ]
  }]
})

export class GraphQLModule {
}
