import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/user/queries';
import * as Mutation from '../_graphql/user/mutations';

import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

import { Country, Tag, User } from '../types';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class UsersService {

	// Private
	private _user: BehaviorSubject<User | null> = new BehaviorSubject(null);
	private _users: BehaviorSubject<User[] | null> = new BehaviorSubject(null);
	private _countries: BehaviorSubject<Country[] | null> = new BehaviorSubject(null);
	private _tags: BehaviorSubject<Tag[] | null> = new BehaviorSubject(null);

	users: Observable<User[]>;
	user: Observable<User>;

	constructor(
		private _apollo: Apollo,
		private _httpClient: HttpClient
	) {
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for user
	 */
	get user$(): Observable<User> {
		return this._user.asObservable();
	}

	/**
	 * Getter for users
	 */
	get users$(): Observable<User[]> {
		return this._users.asObservable();
	}

	/**
	 * Getter for countries
	 */
	get countries$(): Observable<Country[]> {
		return this._countries.asObservable();
	}

	/**
	 * Getter for tags
	 */
	get tags$(): Observable<Tag[]> {
		return this._tags.asObservable();
	}

	/**
	 * ----------------------------------------------------
	 * Get One Users
	 * ----------------------------------------------------
	 * @method getUser
	 */
	getUser(id) {
		this.user = this._apollo
			.watchQuery({
				query    : Query.user,
				variables: {
					id,
				},
			})
			.valueChanges
			.pipe(
				map((result: any) => result.data.user),
			);
		return this.user;
	}

	/**
	 * Get user by id
	 */
	getUserById(id: string): Observable<User> {
		return this._users.pipe(
			take(1),
			map((users) => {
				// Find the user
				const user = users.find(item => item.id === id) || null;
				// Update the user
				this._user.next(user);
				// Return the user
				return user;
			}),
			switchMap((user) => {

				if (!user) {
					return throwError('Could not found user with id of ' + id + '!');
				}

				return of(user);
			}),
		);
	}

	/**
	 * ----------------------------------------------------
	 * Get All Users
	 * ----------------------------------------------------
	 * @method getAllUsers
	 */
	getAllUsers(param?, orderBy?, limit?): Observable<User[]> {
		console.log('getAllUsers: ', this._users)
		return this._apollo.query({
			query    : Query.users,
			variables: {
				param,
				orderBy,
				limit,
			}
		})
			.pipe(
				map((result: any) => {
					const users = result.data.users;
					console.log('users: ', this._users)
					// Update the things
					this._users.next(users);
					// Return the things
					return users;
				}),
			);
	}

	getUsers() {
		console.log('getUsers')
		return this._apollo.watchQuery({
			query: Query.users,
			variables:
				{}
		})
			.valueChanges
			.pipe(
				map(res => {
					console.log('res.data: ', res.data)
				})
			)
	}

	/**
	 * Search users with given query
	 *
	 * @param query
	 */
	getUsersByQuery(query: string): Observable<User[]> {
		return this._apollo.query({
			query    : Query.usersByQuery,
			variables: {
				query,
			},
		})
			.pipe(
				map((result: any) => {
					const users = result.data.usersByQuery;
					// Update the things
					this._users.next(users);
					// Return the things
					return users;
				}),
			);
	}

	/**
	 * Search vehicles with given query
	 *
	 * @param params
	 * @param query
	 */
	getUsersByParametersAndQuery(params: string[], query: string): Observable<User[]> {
		return this._apollo.query({
			query    : Query.usersByParametersAndQuery,
			variables: {
				params,
				query,
			},
		})
			.pipe(
				map((result: any) => {
					const users = result.data.usersByParametersAndQuery;
					// Update the things
					this._users.next(users);
					// Return the things
					return users;
				}),
			);
	}

	/**
	 * ----------------------------------------------------
	 * Get All Users
	 * ----------------------------------------------------
	 * @method getUsers
	 */
	getTimetrackingUsers(orderBy?, limit?) {
		return this._apollo.watchQuery({
			query    : Query.timetrackingUsers,
			variables: {
				orderBy,
				limit,
			},
		})
			.valueChanges
			.pipe(
				map((result: any) =>
					result.data.timetrackingUsers,
				),
			);
	}

	/**
	 * ----------------------------------------------------
	 * Get All Users
	 * ----------------------------------------------------
	 * @method getUsers
	 */
	getChatUsers(orderBy?, limit?) {
		this.users = this._apollo.watchQuery({
			query    : Query.chatUsers,
			variables: {
				orderBy,
				limit,
			},
		})
			.valueChanges
			.pipe(
				map((result: any) => result.data.chatUsers),
			);
		return this.users;
	}

	/**
	 * Create user
	 */
	createUser(): Observable<User> {
		const newUser = {
			id       : FuseMockApiUtils.guid(),
			avatar   : null,
			firstName: null,
			lastName : 'Neuer Kontakt',
			email    : null,
			birthday : null,
			admin    : false,
			roles    : ['user'],
		};
		console.log('preMutation newUser: ', newUser);
		return this.users$.pipe(
			take(1),
			switchMap(users => this._apollo
				.mutate({
					mutation : Mutation.addUser,
					variables: {
						data: newUser,
					},
				}).pipe(
					map((result: any) => {
						console.log('newUser: ', newUser);
						const user: User = result.data.addUser;
						console.log('user: ', user);
						// Update the users with the new user
						this._users.next([user, ...users]);
						// Return the new user
						return user;
					}),
				)),
		);
	}


	/**
	 * Update user
	 *
	 * @param id
	 * @param userData
	 */
	updateUser(id: string, userData: User): Observable<User> {
		return this.users$
			.pipe(
				take(1),
				switchMap(users => this._apollo
					.mutate({
						mutation : Mutation.updateUser,
						variables: {
							id,
							data: userData,
						},
					}).pipe(
						map((result: any) => {

							const updatedUser = userData;
							// Find the index of the updated user
							const index = users.findIndex(item => item._id === id);

							// Update the user
							const newUsers = _.cloneDeep(users);
							newUsers[index] = updatedUser;

							// Update the users
							this._users.next(newUsers);

							// Return the updated user
							return updatedUser;
						}),
						switchMap(updatedUser => this.user$.pipe(
							take(1),
							filter(item => item && item._id === id),
							tap(() => {

								// Update the user if it's selected
								this._user.next(updatedUser);

								// Return the updated user
								return updatedUser;
							}),
						)),
					)),
			);
	}

	/**
	 * Delete user
	 * @param id
	 */
	trashUser(id: string) {
		this._apollo
			.mutate({
				mutation : Mutation.updateUser,
				variables: {
					id,
					data: {
						deleted: true,
					},
				},
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}

	/**
	 * Delete the user
	 *
	 * @param id
	 */
	deleteUser(id: string): Observable<boolean> {
		return this.users$.pipe(
			take(1),
			switchMap(users => this._apollo
				.mutate({
					mutation : Mutation.deleteUser,
					variables: {
						id,
					},
				}).pipe(
					map((result: any) => {

						// Find the index of the deleted user
						const index = users.findIndex(item => item.id === id);

						// Delete the user
						users.splice(index, 1);

						// Update the users
						this._users.next(users);

						// Return the deleted status
						return true;
					}),
				)),
		);
	}

	/**
	 * Update the avatar of the given user
	 *
	 * @param id
	 * @param avatar
	 */
	uploadAvatar(id: string, avatar: File): Observable<User> {
		return this.users$.pipe(
			take(1),
			switchMap(users => this._httpClient.post<User>('api/apps/users/avatar', {
				id,
				avatar,
			}, {
				headers: {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'Content-Type': avatar.type,
				},
			}).pipe(
				map((updatedUser) => {

					// Find the index of the updated user
					const index = users.findIndex(item => item.id === id);

					// Update the user
					users[index] = updatedUser;

					// Update the users
					this._users.next(users);

					// Return the updated user
					return updatedUser;
				}),
				switchMap(updatedUser => this.user$.pipe(
					take(1),
					filter(item => item && item.id === id),
					tap(() => {

						// Update the user if it's selected
						this._user.next(updatedUser);

						// Return the updated user
						return updatedUser;
					}),
				)),
			)),
		);
	}

}
