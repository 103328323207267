import { gql } from 'apollo-angular';
import { categoryFragments } from './fragments';
import { userFragments } from '../user/fragments';

export const categories = gql`
    query categories {
        categories {
            ...Category
            subCategories {
                ...Category
            }
            createdBy {
                ...UserWithoutContact
            }
        }
    }

    ${categoryFragments['category']}
    ${userFragments['userWithoutContact']}
`;
export const categorys = gql`
    query categorys {
        categorys {
            ...Category
            subCategories {
                ...Category
            }
            createdBy {
                ...UserWithoutContact
            }
        }
    }

    ${categoryFragments['category']}
    ${userFragments['userWithoutContact']}
`;

export const getCategoryDetail = gql`
    query getCategoryDetail($id: ObjectID!) {
        category(id: $id) {
            ...Category
            subCategories {
                ...Category
            }
            createdBy {
                ...UserWithoutContact
            }
        }
    }

    ${categoryFragments['category']}
    ${userFragments['userWithoutContact']}
`;

export const categorysByHandle = gql`
    query categorysByHandle($handle: String!, $orderBy: String, $limit: Int){
        categorysByHandle(handle: $handle, orderBy: $orderBy, limit: $limit){
            ...Category
        }
    }

    ${categoryFragments['category']}
`;

export const categorysByParam = gql`
    query categorysByParam($param: String!, $orderBy: String, $limit: Int){
        categorysByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Category
        }
    }

    ${categoryFragments['category']}
`;

export const categorysByQuery = gql`
    query categorysByQuery($query: String!, $orderBy: String, $limit: Int){
        categorysByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...Category
        }
    }

    ${categoryFragments['category']}
`;


export const categorysByParametersAndQuery = gql`
    query categorysByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        categorysByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Category
        }
    }

    ${categoryFragments['category']}
`;

export const category = gql`
    query category($id: ObjectID!) {
        category(id: $id) {
            ...Category
        }
    }

    ${categoryFragments['category']}
`;
