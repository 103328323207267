import { gql } from 'apollo-angular';
import { fileFragments } from './fragments';

export const addFile = gql`
    mutation addFile($data: FileInput!) {
        addFile(data: $data) {
            ...File
        }
    }

    ${fileFragments['file']}
`;

export const updateFile = gql`
    mutation updateFile($id: ObjectID!, $data: FileInput!) {
        updateFile(id: $id, data: $data) {
            ...File
        }
    }

    ${fileFragments['file']}
`;

export const deleteFile = gql`
    mutation deleteFile($id: ObjectID!) {
        deleteFile(id: $id) {
            ...File
        }
    }

    ${fileFragments['file']}
`;
