import {gql} from 'apollo-angular';

import {DocumentNode} from 'graphql';

export const countryFragments: {
    [key: string]: DocumentNode
} = {
    country: gql`
        fragment Country on Country{
            _id
            id
            title
            handle
            color
        }
    `,
};
