import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-hofcafe',
    templateUrl: './hofcafe.component.html',
    styleUrls: ['./hofcafe.component.scss'],
    standalone: false
})
export class HofcafeComponent implements OnInit {
    navClass = 'bg-white';
    filteredImages;
    galleryFilter = 'all';

    categoryList = [
        {
            title: 'Hofkaffee',
            type: 'hofkaffee'
        },
        {
            title: 'Hofgarten',
            type: 'hofgarten'
        }
    ];

    itemList = [
        {
            image: 'assets/images/besonderes/besonderes_hofkaffee_1.jpg',
            title: 'Impression aus dem Hofkaffee',
            types: ['hofkaffee']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofgarten_1.jpg',
            title: 'Impression aus dem Hofgarten',
            types: ['hofgarten']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofkaffee_2.jpg',
            title: 'Impression aus dem Hofkaffee',
            types: ['hofkaffee']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofgarten_2.jpg',
            title: 'Impression aus dem Hofgarten',
            types: ['hofgarten']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofkaffee_3.jpg',
            title: 'Impression aus dem Hofkaffee',
            types: ['hofkaffee']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofgarten_3.jpg',
            title: 'Impression aus dem Hofgarten',
            types: ['hofgarten']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofkaffee_4.jpg',
            title: 'Impression aus dem Hofkaffee',
            types: ['hofkaffee']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofgarten_4.jpg',
            title: 'Impression aus dem Hofgarten',
            types: ['hofgarten']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofkaffee_5.jpg',
            title: 'Impression aus dem Hofkaffee',
            types: ['hofkaffee']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofgarten_5.jpg',
            title: 'Impression aus dem Hofgarten',
            types: ['hofgarten']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofkaffee_6.jpg',
            title: 'Impression aus dem Hofkaffee',
            types: ['hofkaffee']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofgarten_6.jpg',
            title: 'Impression aus dem Hofgarten',
            types: ['hofgarten']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofkaffee_7.jpg',
            title: 'Impression aus dem Hofkaffee',
            types: ['hofkaffee']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofgarten_7.jpg',
            title: 'Impression aus dem Hofgarten',
            types: ['hofgarten']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofkaffee_8.jpg',
            title: 'Impression aus dem Hofkaffee',
            types: ['hofkaffee']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofgarten_8.jpg',
            title: 'Impression aus dem Hofgarten',
            types: ['hofgarten']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofgarten_9.jpg',
            title: 'Impression aus dem Hofgarten',
            types: ['hofgarten']
        },
        {
            image: 'assets/images/besonderes/besonderes_hofgarten_10.jpg',
            title: 'Impression aus dem Hofgarten',
            types: ['hofgarten']
        },
    ];

    constructor() {
    }

    ngOnInit(): void {
        this.filteredImages = this.itemList;

    }

    activeCategory(category) {
        this.galleryFilter = category;
        if (this.galleryFilter === 'all') {
            this.filteredImages = this.itemList;
        } else {
            this.filteredImages = this.itemList.filter(x => x.types.includes(this.galleryFilter));
        }
    }

}
