<!-- Hero Start -->
<swiper-container class = "swiper-slider-hero position-relative d-block vh-100"
                  slides-per-view="1" delay="10000" loop="true" css-mode="true"
                  autoplay=""


>
  <!--
  true,
                  mouseDrag: true,
                  touchDrag: false,
                  pullDrag: false,
                  navSpeed: 700,
                  navText: ['', ''],
responsive: {
  0: {
    items: 1
  },
  600: {
      items: 2
  },
  900: {
    items: 3
    }
  },
nav: false
  -->
  <swiper-slide class = "swiper-slide d-flex align-items-center overflow-hidden" *ngFor = "let item of sliderItems">
    <div class = "slide-inner slide-bg-image d-flex align-items-center"
         [style.background] = "'url('+ item.image +') center center'"
         [style.background-repeat] = "'no-repeat'"
    >
      <!--div class="bg-overlay"></div -->
      <div class = "container">
        <div class = "row justify-content-center">
          <div class = "col-12">
            <!-- div class="title-heading text-center">
              <p class="para-desc mx-auto text-white-50">
                Herzlich Willkommen auf dem
              </p>
              <h1 class="heading text-white title-dark mb-4">Hofgut Streit</h1>
              <div class="mt-4 pt-2">
                <a href="javascript:void(0)" class="btn btn-primary">Kontakt</a>
              </div>
            </div -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </div><!-- end slide-inner -->
  </swiper-slide> <!-- end swiper-slide -->

  <div class = "swiper-button-next border rounded-circle text-center"></div>
  <div class = "swiper-button-prev border rounded-circle text-center"></div>
</swiper-container>
<section class = "section bg-light pb-0">
  <div class = "container">
    <div class = "row justify-content-center">
      <div class = "col-lg-12">
        <div class = "features-absolute">

          <div class = "row">
            <div class = "col-lg-12 col-12 mb-0 mb-md-4 pb-0 pb-md-2 mt-100">
              <div class="row mt-20" *ngIf = "currentDate > jahresEndeMeldungBeginn && currentDate < jahresEndeMeldungEnde">
                <div class="col-lg-12">
                  <p class = "mt-10">
                    <strong>
                      Liebe Kunden! <br>
                      Wir bedanken uns bei Euch ganz herzlich für Eure Treue! <br>
                      Wir wünschen Euch eine besinnliche Weihnachtszeit und einen guten Start ins neue Jahr 2025!
                    </strong>
                  </p>
                </div>
              </div>
              <!-- h4 class = "footer-head mt-40 mb-10 text-primary">Öffnungszeiten</h4 -->
              <h4 class = "footer-head mt-20 mb-10 text-primary">Öffnungszeiten</h4>
              <div class = "row">
                <div class = "col-lg-12">
                  <h6 class = "mb-0">Backstube · Hofladen · Hofgarten</h6>

                  <p class = "mt-10" *ngIf = "currentDate <= meldungEnde">
                    <strong class = "text-danger">BITTE BEACHTEN: </strong>
                  </p>
                  <p class = "text-danger mt-10" *ngIf = "currentDate <= meldungEnde">
                    <!--  *ngIf = "currentDate <= meldungEnde" -->
                    Am Montag, den 23. Dezember hat der Hofladen von 14:00 bis 19:00 geöffnet. <br>
                    Wir backen alle unsere Brotsorten als Laib oder Baguette in 500g oder 1000g. Außerdem auch Linzertorte und Gebäck. <br><br>
                    Wir bieten auf Bestellung Geschenkkörbe, Gutscheine, frische Blumen und Blumensträuße. <br><br>
                    Bestellungen bitte telefonisch unter <a href="tel:00497738922852">+49&nbsp;(0)7738&nbsp;/&nbsp;92&nbsp;28&nbsp;52</a>, per E-Mail an <a href = "mailto:hofladen@hofgut-streit.de">hofladen&#64;hofgut-streit.de</a> auf <a href = "https://www.facebook.com/hofgutstreit" target="_blank">Facebook</a> oder
                    <a href = "https://www.instagram.com/hofgutstreit" target="_blank">Instagram</a>
                  </p>
                  <p class = "text-danger mt-10" *ngIf = "currentDate > ferienBeginn && currentDate < ferienEnde">
                    <strong class = "text-danger">BITTE BEACHTEN: </strong> Wir haben Ferien!
                  </p>
                  <p class = "text-danger" *ngIf = "currentDate > ferienBeginn && currentDate < ferienEnde">
                    Wir gönnen uns eine kleine Auszeit und haben das Hofcafé und den Hofladen geschlossen. <br>
                    Ab dem 12. März 2025 um 09:00 Uhr sind wir wieder für euch da.
                  </p>
                  <p class = "text-muted">
                    <!-- Wir haben für euch unsere Öffnungszeiten erweitert.-->
                    Fühlt euch im Hofcafé & Hofladen wohl und geniesst unser Angebot. Unsere Öffnungszeiten:
                  </p>
                  <p class = "text-muted">
                  </p>
                </div>

                <div class = "col-lg-12"  *ngIf = "currentDate<= date">
                  <h6 class="mb-0">Mittwoch 06. September</h6>
                  <p class = "text-primary mb-30">
                  <strong>geschlossen</strong>
                </p>
                </div>
                <div class = "col-lg-4">
                  <h6 class = "mb-0">Mittwoch</h6>
                  <p class = "text-primary mb-0">
                    <strong>09.00 bis 12.30 Uhr</strong><br>
                  </p>
                  <p class = "text-muted">
                    Frühstücksbuffet, Kaffee & Kuchen
                  </p>
                  <p class = "text-primary mb-0">
                    <strong>11.00 bis 18.00 Uhr</strong><br>
                    Verkauf von Backwaren & Kuchen, gesamtes Sortiment
                  </p>
                  <p class = "text-muted">
                    Hofcafé & Hofladen
                  </p>
                </div>

                <div class = "col-lg-8">
                  <h6 class = "mb-0">Samstags 1-2 mal pro Monat</h6>
                  <p class = "text-primary mb-0">
                    <!-- strong>28. Januar 2023</strong -->
                  </p>
                  <p class = "text-muted">
                    Im neuen Jahr bieten wir euch ein- bis zweimal im Monat ein Frühstücksbuffet. <!-- br>
                    Los geht´s am <strong>28. Januar 2023</strong -->
                  </p>

                  <app-oeffnungszeitenlink-hofgut></app-oeffnungszeitenlink-hofgut>
                </div>
                <!-- div class = "col-lg-4">
                  <h6 class = "mb-0">Donnerstag</h6>
                  <p class = "text-primary mb-0">
                    <strong>09.30 bis 18.00 Uhr</strong>
                  </p>
                  <p class = "text-muted">
                    Hofcafé mit Frühstück (bis 13 Uhr)
                  </p>
                </div -->
                <!-- div class = "col-lg-4">
                  <h6 class = "mb-0">Freitag</h6>
                  <p class = "text-primary mb-0">
                    <strong>09.30 bis 18.00 Uhr</strong>
                  </p>
                  <p class = "text-muted">
                    Hofcafé mit Frühstück (bis 13 Uhr)
                  </p>
                </div -->
                <div class = "col-lg-12" *ngIf = "currentDate<= ferienBeginn">
                  <strong>BITTE BEACHTEN: </strong> Wir haben Ferien!
                  <!-- p>
                    {{  date  | date: 'dd. MMMM yyyy - HH:mm' }}<br>
                    {{  currentDate  | date: 'dd. MMMM yyyy - HH:mm' }}<br>
                    {{  ferienBeginn  | date: 'dd. MMMM yyyy - HH:mm' }}<br>
                    {{  ferienEnde  | date: 'dd. MMMM yyyy - HH:mm'  }}<br>
                  </p -->
                  <p>
                    <strong>Am 24. Dezember backen wir auf Bestellung verschiedene Baguettes. Diese können zwischen
                      09:00 und 12:00 Uhr abgeholt werden.</strong><br>
                    Ab dem 24. Dezember 2022 haben wir das Hofcafé und den Hofladen für ein paar Tage geschlossen. <br>
                    Ab dem 11. Januar 2023 sind wir wieder für euch da.
                  </p>
                </div>
                <div class = "col-lg-12">
                  <p class = "text-muted">
                    In unserer Gefriertruhe vor dem Hofladen findet ihr auch ausserhalb unserer Öffnungszeiten diverse
                    Backwaren zur Selbstbedienung.
                  </p>
                </div>
              </div>
              <!--end icon-->

            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
<!-- counter Start -->
<section class = "section">
  <div class = "container">
    <div class = "row align-items-center">
      <div class = "col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class = "section-title mr-lg-5">
          <h4 class = "title mb-4">
            Willkommen auf dem <span class = "text-primary font-weight-bold">Hofgut&nbsp;Streit</span>
          </h4>
          <p class = "text-muted">
            Der selbe Service. Die selbe Qualität. Seit 15 Jahren.
            Nur ab jetzt unter einem neuen Namen.
            Aus „Danielas Hofladen“ wird „Hofgut Streit".

            Unter dieser neuen Dachmarke fassen wir all unsere Stärken zusammen: <br>
            den Hofladen mit der Backstube, das neu angelegte Schnittblumenfeld und das landwirtschaftliche
            Lohnunternehmen als Agrarservice.
          </p>
          <p class = "text-muted">
            Unser Hofgut ist idyllisch in der Ortsmitte von Steißlingen gelegen und ist ein besonderer Ort
            für Genießer und Naturliebhaber. Schon von Weitem können Sie den himmlischen Duft von unseren
            frischgebackenen Steinofenbroten riechen, die wir in unserer Backstube
            herstellen und in unserem wunderschönen Hofladen verkaufen. Dort finden Sie zudem ein Sortiment an eigenen
            sowie an ausgewählten Produkten von Landwirten aus der Region.
            In unserem kleinen Café können Sie die Seele baumeln lassen und die Ruhe genießen. Nehmen Sie
            sich ein bisschen Natur mit nach Hause und besuchen Sie unser farbenfrohes Schnittblumenfeld –
            dort können Sie sich Ihren eigenen Blumenstrauß zusammenstellen.
          </p>
          <p class = "text-muted">
            Zusätzlich betreiben wir ein landwirtschaftliches Lohnunternehmen, so dass wir nicht nur über
            ein enormes Fachwissen, sondern auch über ein breites Dienstleistungsspektrum verfügen.
          </p>
          <p class = "text-muted">
            Ein besonderes Ambiente wartet auf unserem Hofgut auf Sie. Kommen Sie vorbei - Sie werden
            begeistert sein!
          </p>
          <!-- a href="javascript:void(0)" class="btn btn-outline-primary">Start Now <i
              class="mdi mdi-chevron-right"></i>
          </a -->
          <!-- p>
            <strong>
              Unser Hofladen hat momentan Sommerpause <br>
              Am 01.09.2021 haben wir wieder geöffnet. <br><br>
              Daniela und das Hofladenteam
            </strong>
          </p -->
        </div>
      </div>
      <!--end col-->

      <div class = "col-md-6 order-1 order-md-2">
        <img src = "../../../assets/images/aboutus_hofgut.jpg" class = "img-fluid" alt = "">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

