import { gql } from "apollo-angular";

import { DocumentNode } from "graphql";

export const contentFragments: {
	[key: string]: DocumentNode
} = {
	content: gql`
        fragment Content on Content{
            _id
            id
            title
            showTitle
            subtitle
            description
            header
            subheader
            contenttype
            text
            text2
            image
            showMedias
            showLink
            liste {
                icon
                listTitle
                listText
                listLink
            }
        }
	`

};
