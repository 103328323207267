import { gql } from 'apollo-angular';
import { userFragments } from './fragments';
import { contactFragments } from '../contact/fragments';

export const users = gql`
    query users{
        users{
            ...UserWithoutContact

            contact {
                ...Contact
            }
        }
    }
    
    ${contactFragments['contact']}
    ${userFragments['userWithoutContact']}
`;

export const usersByParam = gql`
    query usersByParam($param: String!, $orderBy: String, $limit: Int){
        usersByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...User
        }
    }
    
    ${userFragments['user']}
`;

export const usersByQuery = gql`
    query usersByQuery($query: String!, $orderBy: String, $limit: Int){
        usersByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...User
        }
    }
    ${userFragments['user']}
`;

export const usersByParametersAndQuery = gql`
    query usersByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        usersByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...User
        }
    }
    
    ${userFragments['user']}
`;

export const timetrackingUsers = gql`
    query timetrackingUsers{
        timetrackingUsers{
            ...User
        }
    }
    
    ${userFragments['user']}
`;

export const chatUsers = gql`
    query chatUsers{
        chatUsers{
            ...User
            contact {
                ...Contact
            }
        }
    }

    ${contactFragments['contact']}
    ${userFragments['user']}
`;

export const user = gql`
    query user($id: ObjectID!) {
        user(id: $id) {
            ...User
            contact {
                ...Contact
            }
        }
    }

    ${contactFragments['contact']}
    ${userFragments['user']}
`;
