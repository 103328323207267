import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Project } from '../types';
import { Apollo } from 'apollo-angular';
import * as Query from '../_graphql/project/queries';
import * as Mutation from '../_graphql/project/mutations';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root',
})
export class ProjectsService {
    // Private
    private _data: BehaviorSubject<any> = new BehaviorSubject(null);
    private _project: BehaviorSubject<Project | null> = new BehaviorSubject(null);
    private _projects: BehaviorSubject<Project[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private apollo: Apollo,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<any>
    {
        return this._data.asObservable();
    }

    /**
     * Getter for project
     */
    get project$(): Observable<Project> {
        return this._project.asObservable();
    }

    /**
     * Getter for projects
     */
    get projects$(): Observable<Project[]> {
        return this._projects.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getData(): Observable<any>
    {
        return this._httpClient.get('api/dashboards/project').pipe(
            tap((response: any) => {
                this._data.next(response);
            })
        );
    }

    /**
     * Get projects
     */
    allProjects(param?, orderBy?, limit?): Observable<Project[]> {
        return this.apollo.query({
            query    : Query.projects,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const projects = result.data.projects;
                    // Update the things
                    this._projects.next(projects);
                    // Return the things
                    return projects;
                }),
            );
    }

    /**
     * Search projects with given query
     *
     * @param query
     */
    getProjectsByQuery(query: string): Observable<Project[]> {
        return this.apollo.query({
            query    : Query.projectsByQuery,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const projects = result.data.projectsByQuery;
                    // Update the things
                    this._projects.next(projects);
                    // Return the things
                    return projects;
                }),
            );
    }

    /**
     * Search projects with given query
     *
     * @param params
     * @param query
     */
    projectsByParametersAndQuery(params: string[], query: string): Observable<Project[]> {
        return this.apollo.query({
            query    : Query.projectsByParametersAndQuery,
            variables: {
                params,
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const projects = result.data.projectsByParametersAndQuery;
                    // Update the things
                    this._projects.next(projects);
                    // Return the things
                    return projects;
                }),
            );
    }

    /**
     * Get project by id
     */
    getProjectById(id: string): Observable<Project> {
        return this._projects.pipe(
            take(1),
            map((projects) => {
                // Find the project
                const project = projects.find(item => item.id === id) || null;
                // Update the project
                this._project.next(project);
                // Return the project
                return project;
            }),
            switchMap((project) => {

                if (!project) {
                    return throwError('Could not found project with id of ' + id + '!');
                }

                return of(project);
            }),
        );
    }

    /**
     * Create project
     */
    createProject(): Observable<Project> {
        const newProject = {
            id    : FuseMockApiUtils.guid(),
            title: null,
            ende : null,
        };
        return this.projects$.pipe(
            take(1),
            switchMap(projects => this.apollo
                .mutate({
                    mutation : Mutation.addProject,
                    variables: {
                        data: newProject,
                    },
                }).pipe(
                    map((result: any) => {
                        // console.log('newProject: ', newProject);
                        const project: Project = result.data.addProject;
                        // console.log('project: ', project);
                        // Update the projects with the new project
                        this._projects.next([project, ...projects]);
                        // Return the new project
                        return project;
                    }),
                )),
        );
    }

    /**
     * Update project
     *
     * @param id
     * @param projectData
     */
    updateProject(id: string, projectData: Project): Observable<Project> {
        return this.projects$
            .pipe(
                take(1),
                switchMap(projects => this.apollo
                    .mutate({
                        mutation : Mutation.updateProject,
                        variables: {
                            // id: id,
                            id,
                            data: projectData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedProject = projectData;
                            // Find the index of the updated project
                            const index = projects.findIndex(item => item._id === id);

                            // Update the project
                            const newProjects = _.cloneDeep(projects);
                            newProjects[index] = updatedProject;

                            // Update the projects
                            this._projects.next(newProjects);

                            // Return the updated project
                            return updatedProject;
                        }),
                        switchMap(updatedProject => this.project$.pipe(
                            take(1),
                            filter(item => item && item._id === id),
                            tap(() => {

                                // Update the project if it's selected
                                this._project.next(updatedProject);

                                // Return the updated project
                                return updatedProject;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete the project
     *
     * @param id
     */
    deleteProject(id: string): Observable<boolean> {
        return this.projects$.pipe(
            take(1),
            switchMap(projects => this._httpClient.delete('api/apps/projects/project', { params: { id } }).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted project
                    const index = projects.findIndex(item => item.id === id);

                    // Delete the project
                    projects.splice(index, 1);

                    // Update the projects
                    this._projects.next(projects);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Delete project
     * @param id
     */
    trashProject(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateProject,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                },
                refetchQueries: [{
                    query: Query.projects,
                }],
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete project
     * @param id
     */
    _deleteProject(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.deleteProject,
                variables     : {
                    id,
                },
                refetchQueries: [{
                    query: Query.projects,
                }],
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }
}
