import { gql } from 'apollo-angular';
import { categoryFragments } from './fragments';

export const addCategory = gql`
    mutation addCategory($data: CategoryInput!) {
        addCategory(data: $data){
            ...Category
        }
    }

    ${categoryFragments['category']}
`;

export const updateCategory = gql`
    mutation updateCategory($id: ObjectID!, $data: CategoryInput) {
        updateCategory(id: $id, data: $data) {
            ...Category
        }
    }

    ${categoryFragments['category']}
`;

export const deleteCategory = gql`
    mutation deleteCategory($id: ObjectID!) {
        deleteCategory(id: $id) {
            ...Category
        }
    }

    ${categoryFragments['category']}
`;
