<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass" #home>
	<div class="container">
		<!-- Logo container-->
		<div>
			<a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">
				<img src="../../../assets/images/hofgut_streit_logo.jpg" class="l-dark" width="112" alt=""
					 *ngIf="!sticky">
				<img src="../../../assets/images/hofgut_streit_logo_small.jpg" class="l-dark" width="112" alt=""
					 *ngIf="sticky">
				<img src="../../../assets/images/hofgut_streit_logo.jpg" class="l-light" width="112" alt="">
			</a>
			<a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
				<img src="../../../assets/images/hofgut_streit_logo.jpg" class="l-dark" height="103" alt="">
				<img src="../../../assets/images/hofgut_streit_logo.jpg" class="l-light" height="103" alt="">
			</a>
		</div>
		<!-- div class="buy-button">
			<ng-template [ngIf]="navClass === 'nav-light'">
				<a href="javascript: void(0);">
					<i-feather name="facebook" class="icons"></i-feather>
				</a>
				<a href="javascript: void(0);">
					<i-feather name="instagram" class="icons"></i-feather>
				</a>
			</ng-template>
			<ng-template [ngIf]="navClass !== 'nav-light'">
				<a href="https://www.facebook.com/LohnunternehmenStreit.de" class="nav-link-ref ml-2">
					<i-feather name="facebook" class="icons"></i-feather>
				</a>
				<a href="javascript: void(0);" class="nav-link-ref ml-2">
					<i-feather name="instagram" class="icons"></i-feather>
				</a>
			</ng-template>

		</div -->

		<!--end login button-->
		<!-- End Logo container-->
		<div class="menu-extras">
			<div class="menu-item">
				<!-- Mobile menu toggle-->
				<a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
					<div class="lines">
						<span></span>
						<span></span>
						<span></span>
					</div>
				</a>
				<!-- End mobile menu toggle-->
			</div>
		</div>

		<div id="navigation">
			<!-- Navigation Menu-->
			<ul class="navigation-menu" [class]="navClass">
				<li><a routerLink="/" class="nav-link-ref">Home</a></li>
				<li><a routerLink="/hofladen" class="nav-link-ref">Hofladen</a></li>
				<li><a routerLink="/hofcafe" class="nav-link-ref">Hofcafé</a></li>
				<li><a routerLink="/blumen" class="nav-link-ref">Blumen</a></li>
				<li><a routerLink="/agrarservice" class="nav-link-ref">Agrarservice</a></li>
				<li><a routerLink="/ackerbau" class="nav-link-ref">Ackerbau</a></li>
				<li><a routerLink="/oeffnungszeiten" class="nav-link-ref">Öffnungszeiten</a></li>
				<!-- li><a routerLink="/bilder" class="nav-link-ref">Bilder</a></li -->
			</ul>
			<!--end navigation menu-->

			<!-- div class="buy-menu-btn d-none">
				<a href="javascript: void(0);" class="nav-link-ref ml-2">
				<i-feather name="facebook" class="icons"></i-feather>
			</a>
				<a href="javascript: void(0);" class="nav-link-ref ml-2">
					<i-feather name="instagram" class="icons"></i-feather>
				</a>
			</div-->

			<!--end login button-->
			<!--end login button-->
		</div>
		<!--end navigation-->
	</div>
	<!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->
