import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';
import { userFragments } from '../user/fragments';
import { mediaFragments } from '../media/fragments';
import { categoryFragments } from '../category/fragments';

export const fileFragments: {
    [ key: string ]: DocumentNode
} = {
    file: gql`
        fragment File on File{
            _id
            id
            title
            name
            type
            description
            filename
            path
            folderId
            contents
            filename
            mimetype
            encoding
            groups
            categories {
                ...Category
            }
            previewimage{
                ...Media
            }
            createdBy {
                ...User
            }
            updatedAt
            createdAt
        }

        ${categoryFragments['category']}
        ${mediaFragments['media']}
        ${userFragments['user']}
    `,
};
