import {gql} from 'apollo-angular';
import {teaserFragments} from './fragments';

export const teaser = gql`
    query teaser($id: ObjectID!) {
        teaser(id: $id) {
            ...Teaser
        }
    }
    
    ${teaserFragments['teaser']}
`;

export const teasers = gql`
    query teasers($show: Boolean, $orderBy: String, $limit: Int){
        teasers(show: $show, orderBy: $orderBy, limit: $limit){
            ...Teaser
        }
    }
    
    ${teaserFragments['teaser']}
`;
