import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@services";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";

// import { FuseAlertType } from "../../../../../s2desk/src/@fuse/components/alert";

@Component({
    selector: "app-auth-login",
    templateUrl: "./auth-login.component.html",
    styleUrls: ["./auth-login.component.css"],
    standalone: false
})
export class AuthLoginComponent implements OnInit {
  // @ViewChild("signInNgForm") signInNgForm: NgForm;

  /*
  alert: { type: FuseAlertType; message: string } = {
    type: "success",
    message: ""
  };
   */
  signInForm: FormGroup;
  showAlert: boolean = false;


  constructor(
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _router: Router) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Create the form
    this.signInForm = this._formBuilder.group({
      email     : ["", [Validators.required, Validators.email, Validators.minLength(6)]],
      password  : ["", Validators.required],
      rememberMe: [false]
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   */
  signIn(): void {
    console.log("signInForm.value: ", this.signInForm.value);
    // Return if the form is invalid
    if (this.signInForm.invalid) {
      return;
    }

    // Disable the form
    this.signInForm.disable();

    // Hide the alert
    this.showAlert = false;

    // Sign in
    this._authService.signIn(this.signInForm.value)
      .subscribe(
        ({ data }) => {

          this._authService.setUser(data.loginUser.user);
          // Store the access token in the local storage
          this._authService.setToken(data.loginUser.token);
          // Set the redirect url.
          // The '/signed-in-redirect' is a dummy url to catch the request and redirect the user
          // to the correct page after a successful sign in. This way, that url can be set via
          // routing file and we don't have to touch here.
          const redirectURL = this._activatedRoute.snapshot.queryParamMap.get("redirectURL") || "/signed-in-redirect";

          // Navigate to the redirect url
          this._router.navigateByUrl(redirectURL);

        },
        (errors) => {
          console.log("there was an error sending the query", errors);

          // Re-enable the form
          this.signInForm.enable();

          // Reset the form
          this.signInForm.reset();

          // Set the alert
          /*
          this.alert = {
            type   : "error",
            message: errors
          };
           */

          // Show the alert
          this.showAlert = true;
        }
      );

  }
}
