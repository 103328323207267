import {gql} from 'apollo-angular';

import {DocumentNode} from 'graphql';

export const navigationFragments: {
    [key: string]: DocumentNode
} = {
    navigation: gql`
        fragment Navigation on Navigation{
            _id
            id
            title
            pages {
                _id
                title
                subtitle
                type
                hidden
                active
                disabled
                children {
                    id
                    title
                    type
                    icon
                    link
                    children {
                        id
                        title
                        type
                        icon
                        link
                    }
                }
            }
        }
    `,
};
