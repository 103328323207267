import { gql } from 'apollo-angular';
import { eventFragments } from './fragments';

export const addEvent = gql`
    mutation addEvent($data: EventInput!) {
        addEvent(data: $data) {
            ...Event
        }
    }
    ${eventFragments['event']}
`;

export const updateEvent = gql`
    mutation updateEvent($id: ObjectID!, $data: EventInput!) {
        updateEvent(id: $id, data: $data) {
            ...Event
        }
    }
    ${eventFragments['event']}
`;

export const deleteEvent = gql`
    mutation deleteEvent($id: ObjectID!) {
        deleteEvent(id: $id) {
            ...Event
        }
    }
    ${eventFragments['event']}
`;

export const updateEvents = gql`
    mutation updateEvents($data: [EventInput!]) {
        updateEvents(data: $data) {
            ...Event
        }
    }
    ${eventFragments['event']}
`;
