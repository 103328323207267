import { gql } from 'apollo-angular';
import { changelogFragments } from './fragments';
import { contentFragments } from '../content/fragments';
import { sectionFragments } from '../section/fragments';
import { articleFragments } from '../article/fragments';
import { mediaFragments } from '../media/fragments';
import { contentelementFragments } from '../contentelement/fragments';
import { userFragments } from '../user/fragments';
import { teaserFragments } from '../teaser/fragments';
import { categoryFragments } from '../category/fragments';
import { fileFragments } from '../file/fragments';

export const changelogs = gql`
    query changelogs($orderBy: String, $limit: Int){
        changelogs(orderBy: $orderBy, limit: $limit) {
            ...Changelog
            user {
                ...User
            }
        }
    }
    ${changelogFragments['changelog']}
    ${userFragments['user']}
`;

export const changelogsByObjectId = gql`
    query changelogsByObjectId($objectId: ObjectID!, $orderBy: String, $limit: Int){
        changelogsByObjectId(objectId: $objectId, orderBy: $orderBy, limit: $limit) {
            ...Changelog
            user {
                ...User
            }
        }
    }
    ${changelogFragments['changelog']}
    ${userFragments['user']}
`;

export const changelog = gql`
    query changelog($id: ObjectID!) {
        changelog(id: $id) {
            ...Changelog
            user {
                ...User
            }
        }
    }
    ${changelogFragments['changelog']}
    ${userFragments['user']}
`;
