import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-ackerbau',
    templateUrl: './ackerbau.component.html',
    styleUrls: ['./ackerbau.component.scss'],
    standalone: false
})
export class AckerbauComponent implements OnInit {
    navClass = 'bg-white';

    constructor() {
    }

    ngOnInit(): void {
    }

}
