import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';
import { mediaFragments } from '../media/fragments';

export const housingmodelFragments: {
    [ key: string ]: DocumentNode
} = {
    housingmodel: gql`
        fragment Housingmodel on Housingmodel{
            _id
            title
            header
            subheader
            beschreibung
            ausstattung{
                title
                shorttitle
                text
                items {
                    value
                }
            }
            mitbringen{
                title
                shorttitle
                text
                items {
                    value
                }
            }
            wichtig{
                title
                shorttitle
                text
                items {
                    value
                }
            }
            teasermedia{
                ...Media
            }
            medias{
                ...Media
            }
        }

        ${mediaFragments['media']}
    `,
};
