import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-auth-cover-re-password',
    templateUrl: './auth-cover-re-password.component.html',
    styleUrls: ['./auth-cover-re-password.component.css'],
    standalone: false
})
export class AuthCoverRePasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
