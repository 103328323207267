<!-- counter Start -->
<section class="section bg-light">
	<div class="container"></div>
</section>
<section class="section">
	<div class="container pt-200">
		<div class="row align-items-center">
			<div class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
				<div class="section-title mr-lg-5">
					<h4 class="title mb-4">
						<span class="text-primary font-weight-bold">Agrarservice</span>
					</h4>
					<p class="text-muted">
						Als landwirtschaftliches Lohnunternehmen bieten wir ein breites Spektrum an Dienstleistungen in
						den Regionen Bodensee, Hegau sowie dem Schwarzwald- Baar-Kreis an.
					</p>
					<p class="text-muted">
						Sie profitieren nicht nur von unserer langjährigen Erfahrung - mit dem Einsatz neuester
						Agrartechnik arbeiten wir zudem effizient, ökonomisch sowie umweltbewusst.
					</p>
					<!-- a href="javascript:void(0)" class="btn btn-outline-primary">Start Now <i
							class="mdi mdi-chevron-right"></i>
					</a -->
				</div>
			</div>
			<!--end col-->

			<div class="col-md-6 order-1 order-md-2">
				<img src="../../../assets/images/willkommen_agrarservice.jpg" class="img-fluid" alt="">
			</div>
			<!--end col-->
		</div>
		<!--end row-->
	</div>
	<!--end container-->
	<div class="container mt-100 mt-60">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-12">
				<div class="media align-items-center shadow rounded p-4 features">
					<div class="icons m-0 rounded h2 text-primary text-center px-3">
						<a href="https://www.facebook.com/LohnunternehmenStreit.de" target="_blank"><i
								class="uil uil-facebook"></i></a>
					</div>
					<div class="icons m-0 ml-2 rounded h2 text-primary text-center px-3">
						<a href="https://www.instagram.com/lohnunternehmen_streit" target="_blank"><i
								class="uil uil-instagram"></i></a>
					</div>
					<div class="content ml-4"><h5 class="mb-1"><a
							href="javascript:void(0)" class="text-dark">Besuchen sie unser Lohnunternehmen auf Facebook
						und Instagram!</a></h5>
						<p class="text-muted mb-0">Klicken sie einfach auf das entsprechende Icon.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!--end section-->

<section class="section bg-white pt-0 pb-0">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 text-center">
				<iframe class="youtubevideo" height="315"
						src="https://www.youtube-nocookie.com/embed/XLbe-64Vb8I"
						title="YouTube video player"
						frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
		</div>
	</div>
</section>

<section class="section">
	<div class="container">

		<div class="row">
			<ul class="col container-filter list-unstyled categories-filter text-center mb-0" id="filter">
				<li class="list-inline-item"><a class="categories border d-block text-dark rounded active"
												(click)="activeCategory('all')"
												[ngClass]="{'active': galleryFilter ==='all'}" data-filter="*">Alle</a>
				</li>
				<li class="list-inline-item" *ngFor="let item of categoryList">
					<a class="categories border d-block text-dark rounded"
					   (click)="activeCategory(item.type)"
					   [ngClass]="{'active': galleryFilter === item.type}"
					>{{item.title}}</a>
				</li>
			</ul>
		</div>
		<!--end row-->

		<div class="row projects-wrapper">
			<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 business" *ngFor="let item of filteredImages; let i=index">
				<div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
					<img src="{{item.image}}" (click)="openModal(content)" class="img-fluid work-image" alt="">
					<div class="card-body">
						<div class="content">
							<a href="javascript:void(0)" (click)="openModal(content)"
							   class="badge badge-primary">{{ item.type }}</a>
							<h5 class="mt-3"><a href="javascript:void(0)" (click)="openModal(content)"
												class="text-dark title">
								{{ item.title }}
								<i-feather name="zoom-in" class="fea icon-sm"></i-feather>
							</a>
							</h5>
						</div>
					</div>
				</div>
				<!-- Modal Content Start -->
				<ng-template #content let-modal>
					<div class="modal-header">
						<h5 class="modal-title" id="modal-basic-title">{{ item.title }}</h5>
						<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Schließen')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="bg-white p-3 rounded box-shadow">
							<p class="text-muted mb-0" [innerHTML]="item.description"></p>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-dark" (click)="modal.close('Schließen')">
							Schließen
						</button>
					</div>
				</ng-template>
				<!-- Modal Content End -->
			</div>
			<!--end col-->

		</div>
		<!--end row-->
	</div>
	<!--end container-->
</section>
<!--end section-->
