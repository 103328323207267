<!-- Hero Start -->
<swiper-container class = "swiper-slider-hero position-relative d-block vh-100">
  <swiper-slide class = "swiper-slide d-flex align-items-center overflow-hidden" *ngFor = "let item of sliderItems">
    <div class = "slide-inner slide-bg-image d-flex align-items-center"
         [style.background] = "'url('+ item.image +') center center'"
         [style.background-repeat] = "'no-repeat'"
    >
      <!--div class="bg-overlay"></div -->
      <div class = "container">
        <div class = "row justify-content-center">
          <div class = "col-12">
            <!-- div class="title-heading text-center">
              <p class="para-desc mx-auto text-white-50">
                Herzlich Willkommen auf dem
              </p>
              <h1 class="heading text-white title-dark mb-4">Hofgut Streit</h1>
              <div class="mt-4 pt-2">
                <a href="javascript:void(0)" class="btn btn-primary">Kontakt</a>
              </div>
            </div -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </div><!-- end slide-inner -->
  </swiper-slide> <!-- end swiper-slide -->

  <div class = "swiper-button-next border rounded-circle text-center"></div>
  <div class = "swiper-button-prev border rounded-circle text-center"></div>
</swiper-container>
<section class = "section">
  <div class = "container">
    <div class = "row justify-content-center">
      <div class = "col-lg-12">
        <div class = "features-absolute">

          <div class = "row">
            <div class = "col-lg-12 col-12 mb-0 mb-md-4 pb-0 pb-md-2 mt-100">

              <!-- h4 class = "footer-head mt-40 mb-10 text-primary">Öffnungszeiten</h4 -->
              <h4 class = "footer-head mt-20 mb-10 text-primary">Öffnungszeiten</h4>

              <!-- SCHLEIFE -->
              <div class = "row" *ngFor = "let item of latestOeffnungsItems; index as i; odd as isOdd; even as isEven;"
                   [ngClass]="{'bg-light' : isOdd}">
                <div class = "col-lg-3">
                  <h6>{{item.date | date:'EEEE dd. MMMM'}}</h6>
                </div>
                <div class = "col-lg-3">
                  <p class = "text-primary mb-10">
                    <strong>{{item.time1}}</strong><br>
                  </p>
                </div>
                <div class = "col-lg-6">
                  <p class = "text-muted">
                    {{item.text1}}
                  </p>
                </div>
                <div class = "col-lg-3" *ngIf="item.time2 || item.text2">
                  <h6 class = "mb-0"></h6>
                </div>
                <div class = "col-lg-3" *ngIf="item.time2 || item.text2">
                  <p class = "text-primary mb-0">
                    <strong>{{item.time2}}</strong><br>
                  </p>
                </div>
                <div class = "col-lg-6" *ngIf="item.time2 || item.text2">
                  <p class = "text-muted">
                    {{item.text2}}
                  </p>
                </div>
              </div>
              <!-- end SCHLEIFE -->

              <!--end icon-->
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
<!-- counter Start -->

