<!-- counter Start -->
<section class = "section bg-light">
  <div class = "container"></div>
</section>
<section class = "section">
  <div class = "container pt-200">
    <div class = "row align-items-center">
      <div class = "col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class = "section-title mr-lg-5">
          <h4 class = "title mb-4">
            Willkommen in unserem <span class = "text-primary font-weight-bold">Hofladen!</span>
          </h4>

          <!--p class = "mt-10" *ngIf = "currentDate <= meldungEnde">
            <strong class = "text-danger">BITTE BEACHTEN: </strong>
          </p -->
          <p class = "text-danger" *ngIf = "currentDate <= meldungEnde">
            <!--  *ngIf = "currentDate <= meldungEnde" -->
            Am Montag, den 23. Dezember hat der Hofladen von 14:00 bis 19:00 geöffnet. <br>
            Wir backen alle unsere Brotsorten als Laib oder Baguette in 500g oder 1000g. Außerdem auch Linzertorte und Gebäck. <br><br>
            Wir bieten auf Bestellung Geschenkkörbe, Gutscheine, frische Blumen und Blumensträuße. <br><br>
            Bestellungen bitte telefonisch unter <a href="tel:00497738922852">+49&nbsp;(0)7738&nbsp;/&nbsp;92&nbsp;28&nbsp;52</a>, per E-Mail an <a href = "mailto:hofladen@hofgut-streit.de">hofladen&#64;hofgut-streit.de</a> auf <a href = "https://www.facebook.com/hofgutstreit" target="_blank">Facebook</a> oder
            <a href = "https://www.instagram.com/hofgutstreit" target="_blank">Instagram</a>
          </p>
          <p class = "text-muted">
            Ein Traum wurde verwirklicht, und so eröffneten wir vor 17 Jahren Daniela`s Hofladen mit eigener Backstube.
            Dieselbe Qualität, denselben Service haben wir mittlerweile alles unter dem Dachnamen
            <span class = "text-primary font-weight-bold">Hofgut Streit</span> zusammengeführt.
          </p>
          <p class = "text-muted">
            Ein herrlicher Duft von frisch Gebackenem empfängt sie bereits im Hof. In unserer Hofbackstube in den Schamottesteinbacköfen werden alle zum Verkauf angebotenen Brote, Backwaren, Kuchen und Torten hergestellt und gebacken.
          </p>
          <p class = "text-muted">
            Da uns Qualität wichtig ist, verzichten wir auf Zusatz -und Konservierungsstoffe, verwenden naturbelassenes Steinsalz und beziehen unser Mehl aus der Steigmühle Leiber in Engen-Anselfingen.
          </p>
          <p class = "text-muted">
            Außerdem bieten wir weitere Produkte von Landwirten aus der Region und Baden Württemberg zum Verkauf an, wie: Eier, Nudeln, Honig, Äpfel, Apfelchips, Hägele´s Knoblauchwürze, Kartoffeln,…
          </p>
          <p class = "text-muted">
            Wir freuen uns Sie bei uns begrüßen zu dürfen.
          </p>
          <p class = "text-muted">
            Wir nehmen gerne Ihre Bestellung entgegen, damit sie sicher auch alles bekommen, was sie möchten. Größere Mengen bitte eine Woche vorher, jedoch bis spätestens am Dienstag 08.30 Uhr bestellen.
            Tel: <a href="tel:00497738922852">+49&nbsp;(0)7738&nbsp;/&nbsp;92&nbsp;28&nbsp;52</a> Dienstag: 07.30-14.00 Uhr, Mittwoch: ab 07.00 Uhr,
            <a href = "mailto:hofladen@hofgut-streit.de">hofladen&#64;hofgut-streit.de</a>, <a href = "https://www.facebook.com/hofgutstreit" target="_blank">Facebook</a> und
            <a href = "https://www.instagram.com/hofgutstreit" target="_blank">Instagram</a>
          </p>
          <!-- p>
            <strong>
              Unser Hofladen hat momentan Sommerpause <br>
              Am 01.09.2021 haben wir wieder geöffnet. <br><br>
              Daniela und das Hofladenteam
            </strong>
          </p -->
          <!-- p>
            Unser Hofladen hat für Sie geöffnet: <br>
            <strong>Mittwoch 11.00 Uhr bis 18.00 Uhr</strong>
          </p -->
          <p class = "text-danger" *ngIf = "currentDate > ferienBeginn && currentDate < ferienEnde">
            <strong>BITTE BEACHTEN: </strong> Wir haben Ferien!
          </p>
          <p class = "text-danger" *ngIf = "currentDate > ferienBeginn && currentDate < ferienEnde">
            Wir gönnen uns eine kleine Auszeit und haben das Hofcafé und den Hofladen geschlossen. <br>
            Ab dem 12. März 2025 um 09:00 Uhr sind wir wieder für euch da.
          </p>
          <p>
            Geöffnet: <br>
            <strong>Mittwoch 11.00 bis 18.00 Uhr</strong><br>
            <strong>Samstags 14-tägig 09.00-16.00 Uhr</strong><br>
            Hofcafé & Hofladen <br> <br>

            <!--strong>Donnerstag 09.30 bis 18.00 Uhr</strong> <br>
            Hofcafé mit Frühstück (bis 13 Uhr) <br> <br -->

            <!-- strong>Freitag 09.30 bis 18.00 Uhr</strong> <br>
            Hofcafé mit Frühstück (bis 13 Uhr)<br -->
          </p>
          <p *ngIf = "currentDate<= ferienBeginn">
            <strong>BITTE BEACHTEN: </strong> Wir haben Ferien!<br>
            <strong>Am 24. Dezember backen wir auf Bestellung verschiedene Baguettes. Diese können zwischen 09:00 und
              12:00 Uhr abgeholt werden.</strong><br>
            Ab dem 24. Dezember 2022 haben wir das Hofcafé und den Hofladen für ein paar Tage geschlossen. <br>
            Ab dem 11. Januar 2023 sind wir wieder für euch da.
          </p>

          <!-- p>
            In unserer Gefriertruhe vor dem Hofladen findet ihr auch ausserhalb unserer Öffnungszeiten diverse
            Backwaren zur Selbstbedienung.
          </p -->
          <!-- a href="javascript:void(0)" class="btn btn-outline-primary">Start Now <i
              class="mdi mdi-chevron-right"></i>
          </a -->
        </div>
      </div>
      <!--end col-->

      <div class = "col-md-6 order-1 order-md-2">
        <img src = "../../../assets/images/willkommen_brot.jpg" class = "img-fluid" alt = "">
        <!-- span class="copyright ng-star-inserted"> © Camping- und Ferienpark Orsingen</span -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <app-sociallink-hofgut></app-sociallink-hofgut>
</section>
<!--end section-->

<!-- Counter Start -->
<!-- section class="section bg-light">
	<div class="container">

		<div class="row mt-4 pt-2 position-relative" id="counter" style="z-index: 1;">
			<div class="col-md-3 col-6 mt-4 pt-2">
				<div class="counter-box text-center">
					<img src="assets/_images/illustrator/Asset190.svg" class="avatar avatar-small" alt="">
					<h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="97">3</span>%</h2>
					<h6 class="counter-head text-muted">Eier</h6>
				</div>
			</div>

			<div class="col-md-3 col-6 mt-4 pt-2">
				<div class="counter-box text-center">
					<img src="assets/_images/illustrator/Asset189.svg" class="avatar avatar-small" alt="">
					<h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="15" [from]="1" [duration]="4"></span>+
					</h2>
					<h6 class="counter-head text-muted">Milch</h6>
				</div>
			</div>

			<div class="col-md-3 col-6 mt-4 pt-2">
				<div class="counter-box text-center">
					<img src="assets/_images/illustrator/Asset192.svg" class="avatar avatar-small" alt="">
					<h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="2" [from]="0" [duration]="4"></span>K
					</h2>
					<h6 class="counter-head text-muted">Mehl</h6>
				</div>
			</div>

			<div class="col-md-3 col-6 mt-4 pt-2">
				<div class="counter-box text-center">
					<img src="assets/_images/illustrator/Asset187.svg" class="avatar avatar-small" alt="">
					<h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="98" [from]="3" [duration]="4"></span>%
					</h2>
					<h6 class="counter-head text-muted">Stunden</h6>
				</div>
			</div>

		</div>
	</div>
</section -->
<!--end section-->
<!-- Counter End -->

<section class = "section" style = "padding-top:40px">
  <div class = "container">

    <div class = "row">
      <ul class = "col container-filter list-unstyled categories-filter text-center mb-0" id = "filter">
        <li class = "list-inline-item"><a class = "categories border d-block text-dark rounded active"
                                          (click) = "activeCategory('all')"
                                          [ngClass] = "{'active': galleryFilter ==='all'}" data-filter = "*">Alle</a>
        </li>
        <li class = "list-inline-item" *ngFor = "let item of categoryList">
          <a class = "categories border d-block text-dark rounded"
             (click) = "activeCategory(item.type)"
             [ngClass] = "{'active': galleryFilter === item.type}"
          >{{item.title}}</a>
        </li>
      </ul>
    </div>
    <!--end row-->

    <div class = "row projects-wrapper">
      <div class = "col-lg-4 col-md-6 col-12 mt-4 pt-2 business"
           *ngFor = "let item of filteredImages | orderBy: 'title'">
        <div class = "card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
          <img *ngIf = "item.type === 'modal'" src = "{{item.image}}" (click) = "openModal(content)"
               class = "img-fluid work-image" alt = "">
          <img *ngIf = "item.type !== 'modal'" src = "{{item.image}}" class = "img-fluid work-image" alt = "">
          <div class = "card-body">
            <div class = "content">
              <a *ngIf = "item.type === 'modal'" href = "javascript:void(0)" (click) = "openModal(content)"
                 class = "badge badge-primary">{{ item.type }}</a>
              <a *ngIf = "item.type !== 'modal'" href = "javascript:void(0)"
                 class = "badge badge-primary">{{ item.type }}</a>
              <h5 *ngIf = "item.type === 'modal'" class = "mt-3"><a href = "javascript:void(0)"
                                                                    (click) = "openModal(content)"
                                                                    class = "text-dark title">
                {{ item.title }}
                <i-feather name = "zoom-in" class = "fea icon-sm"></i-feather>
              </a>
              </h5>
              <h5 *ngIf = "item.type !== 'modal'" class = "mt-3"><a href = "javascript:void(0)"
                                                                    class = "text-dark title">
                {{ item.title }}
              </a>
              </h5>
            </div>
          </div>
        </div>

        <!-- Modal Content Start -->
        <ng-template #content let-modal>
          <div class = "modal-header">
            <h5 class = "modal-title" id = "modal-basic-title">{{ item.title }}</h5>
            <button type = "button" class = "close" aria-label = "Close" (click) = "modal.dismiss('Schließen')">
              <span aria-hidden = "true">&times;</span>
            </button>
          </div>
          <div class = "modal-body">
            <div class = "bg-white p-3 rounded box-shadow">
              <p class = "text-muted mb-0" [innerHTML] = "item.description"></p>
            </div>
          </div>
          <div class = "modal-footer">
            <button type = "button" class = "btn btn-outline-dark" (click) = "modal.close('Schließen')">Schließen
            </button>
          </div>
        </ng-template>
        <!-- Modal Content End -->
      </div>
      <!--end col-->

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
