
<!-- app-switcher></app-switcher -->

<router-outlet></router-outlet>

<!-- Back to top -->
<a class = "btn btn-icon btn-soft-primary back-to-top" id = "back-to-top"
   ngxScrollTop>
  <i-feather name = "arrow-up" class = "icons"></i-feather>
</a>
<!-- Back to top -->

<modal-outlet></modal-outlet>

<app-carousel></app-carousel>  

