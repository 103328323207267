<div class="container mt-100 mt-60">
	<div class="row">
		<div class="col-lg-12 col-md-12 col-12">
			<div class="media align-items-center shadow rounded p-4 features">
				<div class="icons m-0 rounded h2 text-primary text-center px-3">
					<a href="https://www.facebook.com/hofgutstreit" target="_blank"><i class="uil uil-facebook"></i></a>
				</div>
				<div class="icons m-0 ml-2 rounded h2 text-primary text-center px-3">
					<a href="https://www.instagram.com/hofgutstreit" target="_blank"><i class="uil uil-instagram"></i></a>
				</div>
				<div class="content ml-4"><h5 class="mb-1"><a
						href="javascript:void(0)" class="text-dark">Besuchen sie das Hofgut Streit auf Facebook und Instagram!</a></h5>
					<p class="text-muted mb-0">Klicken sie einfach auf das entsprechende Icon.</p>
				</div>
			</div>
		</div>
	</div>
</div>
