import {gql} from 'apollo-angular';

import { articleFragments } from './fragments';

import { locationFragments } from '../location/fragments';
import { tagFragments } from '../tag/fragments';
import { eventFragments } from '../event/fragments';
import { teaserFragments } from '../teaser/fragments';
import { mediaFragments } from '../media/fragments';
import { categoryFragments } from '../category/fragments';

export const articles = gql`
    query articles($orderBy: String, $limit: Int){
        articles(orderBy: $orderBy, limit: $limit){
            ...Article
            relatedArticle{
                ...Article
            }
            teaser {
                ...Teaser
            }
        }
    }

    ${articleFragments['article']}
    ${teaserFragments['teaser']}
`;

export const articlesShown = gql`
    query articlesShown($limit: Int){
        articlesShown(limit: $limit){
            ...Article

            relatedArticle{
                ...Article
            }
        }
    }

    ${articleFragments['article']}
`;

export const getArticleDetail = gql`
    query article($id: ObjectID!) {
        article(id: $id) {
            ...Article

            relatedArticle{
                ...Article
            }
            teaser {
                ...Teaser
            }
        }
    }

    ${articleFragments['article']}
    ${teaserFragments['teaser']}
`;


export const articleByNavTitle = gql`
    query articleByNavTitle($navTitle: String){
        articleByNavTitle(navTitle: $navTitle){
            ...Article
            categories {
                ...Category
            }
            events {
                ...Event
            }
            locations{
                ...Location
            }
            medias {
                ...Media
            }
            relatedArticle{
                ...Article
            }
            tags {
                ...Tag
            }
        }
    }

    ${articleFragments['article']}
    ${categoryFragments['category']}
    ${eventFragments['event']}
    ${locationFragments['location']}
    ${mediaFragments['media']}
    ${tagFragments['tag']}
`;
