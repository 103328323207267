import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { Apollo } from "apollo-angular";
import { Router } from "@angular/router";
import { App, AppPopup, Content } from "../types";
import * as Query from "../_graphql/app/queries";
import * as Mutation from "../_graphql/app/mutations";
import { map, switchMap, take } from "rxjs/operators";
import { ApolloQueryResult } from "@apollo/client";


@Injectable({
  providedIn: "root"
})
export class AppService {
  currentDate = new Date();

  // Private
  private _app: BehaviorSubject<App | null> = new BehaviorSubject(null);
  private _popup: BehaviorSubject<AppPopup | null> = new BehaviorSubject(null);
  private title = new BehaviorSubject<string>("App title");
  private title$ = this.title.asObservable();

  /**
   * Constructor
   */

  constructor(
    private apollo: Apollo,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for app
   */
  get app$(): Observable<App> {
    return this._app.asObservable();
  }

  /**
   * Getter for popup
   */
  get popup$(): Observable<AppPopup> {
    return this._popup.asObservable();
  }

  /**
   * ----------------------------------------------------
   * Get App
   * ----------------------------------------------------
   * @method getApp
   */
  getApp(): Observable<App> {
    return this.apollo.query({
      query: Query.app
    })
      .pipe(
        map((result: ApolloQueryResult<App>) => {
            // console.log("-> result", result);
            const app = result.data;
            // console.log("-> app", app);
            // Update the things
            this._app.next(app);
            // Return the things
            return app;
          }
        )
      );
  }


  /**
   * ----------------------------------------------------
   * Get One Page by Navtitle
   * ----------------------------------------------------
   * @method getPopup
   */
  getPopup(): Observable<AppPopup>{
    console.log('this._app: ', this._app);
    return this._app.pipe(
      map(app => {
        const popups = app["popups"].filter(items => (this.currentDate > new Date(items.beginn) && this.currentDate < new Date(items.ende))) || null;
        console.log("popups: ", popups);
        // Find latest popup
        const popup = popups.reduce((a, b) => new Date(a.ende) > new Date(b.ende) ? a : b);
        console.log("appService getPopup popuplatest: ", popup);
        return popup
      }));
    /*
    return this._app.pipe(
      map((app) => {
        console.log("__appService getPopup app", app);

        let popup;
        console.log("__appService getPopup app.popups", app.popups);

        // Find popups
          const popups: AppPopup[] = app.popups.filter(items => (this.currentDate > new Date(items.beginn) && this.currentDate < new Date(items.ende))) || null;
          console.log("popups: ", popups);
          // Find latest popup
          const oldPopup = popups.reduce((a, b) => {
            return new Date(a.ende) < new Date(b.ende) ? a : b;
          });
          const popup = popups.reduce((a, b) => new Date(a.ende) > new Date(b.ende) ? a : b);
          console.log("appService getPopup oldPopup latest: ", oldPopup);
          console.log("appService getPopup popuplatest: ", popup);
          // Update the popup
          this._popup.next(popup);
          console.log("appService getPopup: ", popup);

        // Return the page
        return popups;

      }),
    switchMap((popups) => {

      if (!popups) {
        return throwError("Could not found popups");
      }

      return of(popups);
    });
  )
    ;
      */
  }

  /**
   * Create app
   * @param appData
   */
  createApp(appData): Observable<Content> {
    return this.apollo
      .mutate({
        mutation      : Mutation.addApp,
        variables     : {
          data: appData
        },
        refetchQueries: [{
          query: Query.app
        }]
      }).pipe(
        map((result: any) => {
            const app = result.data.app;
            // Update the things
            this._app.next(app);
            // Return the things
            return app;
          }
        ));
  }

  /**
   * Update content
   * @param appData
   */
  updateApp(id, appData): Observable<Content> {
    return this.apollo
      .mutate({
        mutation      : Mutation.updateApp,
        variables     : {
          id,
          data: appData
        },
        refetchQueries: [{
          query: Query.app
        }]
      }).pipe(
        map((result: any) => {
            const app = result.data.app;
            // Update the things
            this._app.next(app);
            // Return the things
            return app;
          }
        ));
  }

  setTitle(title: string) {
    this.title.next(title);
  }

  getTitle(): Observable<string> {
    return this.title$;
  }
}
