import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-email-alert',
    templateUrl: './email-alert.component.html',
    styleUrls: ['./email-alert.component.css'],
    standalone: false
})
export class EmailAlertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
