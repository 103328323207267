import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const categoryFragments: {
    [ key: string ]: DocumentNode
} = {
    category: gql`
        fragment Category on Category{
            _id
            title
        }

    `,
};
