import { Component, OnInit } from "@angular/core";
import { AuthService } from "@services";
import { distinctUntilChanged } from "rxjs/operators";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.css"],
    standalone: false
})
export class FooterComponent implements OnInit {
  logged: boolean;

  constructor(
    private _authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this._authService.isAuthenticated
      .pipe(
        // Only emit when the current value is different than the last
        distinctUntilChanged()
      )
      .subscribe(isAuthenticated => {
        this.logged = isAuthenticated;
      });
    console.log('Logged: ', this.logged)
  }

}
