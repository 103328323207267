import {gql} from 'apollo-angular';
import {contentFragments} from './fragments';

export const contents = gql`
    query contents{
        contents{
            ...Content

            content {
                ...Content
            }
            contents {
                ...Content
            }
        }
    }
    ${contentFragments['content']}
`;

export const content = gql`
    query content($id: ObjectID!) {
        content(id: $id) {
            ...Content

            content {
                ...Content
            }
            contents {
                ...Content
            }
        }
    }
    ${contentFragments['content']}
`;
