import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/request/queries';
import * as Mutation from '../_graphql/request/mutations';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {
  requests; // List of requests
  request = {};

  constructor(
    private apollo: Apollo
  ) {
  }

  /**
   * ----------------------------------------------------
   * Get One pages
   * ----------------------------------------------------
   * @method getPage
   */
  getRequest(id) {
    this.request = this.apollo
      .watchQuery({
        query: Query.request,
        variables: {
            id}
      })
      .valueChanges
      .pipe(
        map((result: any) => result.data.request),
      );
    return this.request;
  }

  /**
   * ----------------------------------------------------
   * Get All requests
   * ----------------------------------------------------
   * @method getRequests
   */
  getRequests(orderBy?, limit?) {
    this.requests = this.apollo.watchQuery({
      query: Query.requests,
      variables: {
        orderBy,
        limit
      }
    })
      .valueChanges
      .pipe(
        map((result: any) => result.data.requests),
      );
    return this.requests;
  }

  /**
   * Create request
   * @param requestData
   */
  createRequest(requestData) {
    this.apollo
      .mutate({
        mutation: Mutation.addRequest,
        variables: {
          data: requestData
        },
        refetchQueries: [{
          query: Query.requests
        }],
      })
      .subscribe(({ data }) => {
        console.log(data);
      }, (error) => {
        console.log('there was an error sending the query', error);
      });
  }

  /**
   * Update request
   * @param id
   * @param requestData
   */
  updateRequest(id, requestData) {
    this.apollo
      .mutate({
        mutation: Mutation.updateRequest,
        variables: {
          id,
          data: requestData,
        },
        refetchQueries: [{
          query: Query.requests
        }]
      })
      .subscribe(({ data }) => {
        console.log(data);
      }, (error) => {
        console.log(' there was an error sending the query', error);
      });
  }

  /**
   * Delete request
   * @param id
   */
  deleteRequest(id: string) {
    this.apollo
      .mutate({
        mutation: Mutation.deleteRequest,
        variables: {
          id
        },
        refetchQueries: [{
          query: Query.requests
        }]
      })
      .subscribe(({ data }) => {
        console.log(data);
      }, (error) => {
        console.log('there was an error sending the delete query ', error);
      });
  }

}
