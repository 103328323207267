import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const projectFragments: {
    [ key: string ]: DocumentNode
} = {
    project: gql`
        fragment Project on Project{
            _id
            id
            title
            status
            faellig
            start
            ende
            details
            pipeline
            tasks
            events
            files
            owner
            createdBy
        }

    `,
};
