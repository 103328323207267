import {gql} from 'apollo-angular';

import {DocumentNode} from 'graphql';
import { mediaFragments } from '../media/fragments';
import { contactFragments } from '../contact/fragments';

export const userFragments: {
    [key: string]: DocumentNode
} = {
    user: gql`
        fragment User on User{
            _id
            id
            name
            username
            roles
            firstName
            lastName
            email
            emails {
                email
                label
            }
            entryDate
            birthday
            admin
            contact{
                ...Contact
            }
            media{
                ...Media
            }
            frequentContacts
            starredContacts
            contactGroups{
                name
                contacts
            }
            status
            mood
            chatList {
                chatId
                contactId
                name
                unread
                lastMessage
                lastMessageTime
            }
            timetracking
            chatEnabled
            tags
        }

        ${mediaFragments['media']}
        ${contactFragments['contact']}
    `,

    userWithoutContact: gql`
        fragment UserWithoutContact on User {
            _id
            id
            name
            username
            roles
            firstName
            lastName
            email
            emails {
                email
                label
            }
            birthday
            admin
            tags
        }
    `
};
