import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-auth-cover-signup',
    templateUrl: './auth-cover-signup.component.html',
    styleUrls: ['./auth-cover-signup.component.css'],
    standalone: false
})
export class AuthCoverSignupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
