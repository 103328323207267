import {gql} from 'apollo-angular';

export const loginUser = gql`
    query loginUser($data: UserLoginInput!) {
        loginUser(data: $data) {
            token
            user{
                _id
                username
                firstName
                lastName
                email
                roles
                frequentContacts
                starredContacts
                contactGroups {
                    name
                    contacts
                }
            }
        }
    }`;

export const checkToken = gql`
    query checkToken($id: ObjectID!, $token: String!) {
        checkToken(id: $id, token: $token)
    }`;

export const me = gql`
    query me($id: ObjectID!) {
        me(id: $id) {
            email
            username
            firstName
            lastName
        }
    }`;
