import { gql } from 'apollo-angular';

import { DocumentNode } from 'graphql';
import { categoryFragments } from '../category/fragments';
import { userFragments } from '../user/fragments';
import { eventFragments } from '../event/fragments';
import { pageFragments } from '../page/fragments';

export const teaserFragments: {
	[key: string]: DocumentNode
} = {
	teaser: gql`
        fragment Teaser on Teaser{
            _id
            title
            subtitle
            text
            info
            linktype
            link
            show
            categories{
                ...Category
            }
            createdBy{
                ...User
            }

            linkedPage{
                ...Page
            }
            linkedArticle{
                navTitle
            }
            linkedEvent{
                ...Event
            }
            media{
                ...Media
            }
        }
        ${categoryFragments['category']}
        ${userFragments['user']}
        ${eventFragments['event']}
        ${pageFragments['page']}
	`,
};
