import { gql } from 'apollo-angular';
import { requestFragments } from './fragments';

export const addRequest = gql`
    mutation addRequest($data: RequestInput!) {
        addRequest(data: $data) {
            ...Request
        }
    }

    ${requestFragments['request']}
`;

export const updateRequest = gql`
    mutation updateRequest($id: ObjectID!, $data: RequestInput!) {
        updateRequest(id: $id, data: $data) {
            ...Request
        }
    }

    ${requestFragments['request']}
`;

export const deleteRequest = gql`
    mutation deleteRequest($id: ObjectID!) {
        deleteRequest(id: $id) {
            ...Request
        }
    }

    ${requestFragments['request']}
`;
