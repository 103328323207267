import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthCoverLoginComponent } from "./auth/auth-cover-login/auth-cover-login.component";
import { AuthCoverRePasswordComponent } from "./auth/auth-cover-re-password/auth-cover-re-password.component";
import { AuthCoverSignupComponent } from "./auth/auth-cover-signup/auth-cover-signup.component";
import { AuthLoginThreeComponent } from "./auth/auth-login-three/auth-login-three.component";
import { AuthLoginComponent } from "./auth/auth-login/auth-login.component";
import { AuthRePasswordThreeComponent } from "./auth/auth-re-password-three/auth-re-password-three.component";
import { AuthRePasswordComponent } from "./auth/auth-re-password/auth-re-password.component";
import { AuthSignupThreeComponent } from "./auth/auth-signup-three/auth-signup-three.component";
import { AuthSignupComponent } from "./auth/auth-signup/auth-signup.component";

import { MasterPageComponent } from "./core/_components/master-page/master-page.component";
import { EmailAlertComponent } from "./email/email-alert/email-alert.component";
import { EmailConfirmationComponent } from "./email/email-confirmation/email-confirmation.component";
import { EmailInvoiceComponent } from "./email/email-invoice/email-invoice.component";
import { EmailPasswordResetComponent } from "./email/email-password-reset/email-password-reset.component";
import { SwitcherComponent } from "./shared/switcher/switcher.component";
import { HomeComponent } from "./core/home/home.component";
import { BilderComponent } from "./core/bilder/bilder.component";
import { AckerbauComponent } from "./core/ackerbau/ackerbau.component";
import { AgrarserviceComponent } from "./core/agrarservice/agrarservice.component";
import { HofcafeComponent } from "./core/hofcafe/hofcafe.component";
import { BlumenComponent } from "./core/blumen/blumen.component";
import { HofladenComponent } from "./core/hofladen/hofladen.component";
import { ImpressumComponent } from "./core/impressum/impressum.component";
import { DatenschutzComponent } from "./core/datenschutz/datenschutz.component";
import { OeffnungszeitenComponent } from "./core/oeffnungszeiten/oeffnungszeiten.component";

const routes: Routes = [
  {
    path     : "",
    component: MasterPageComponent,
    children : [
      { path: "", component: HomeComponent },
      { path: "home", component: HomeComponent },
      { path: "hofladen", component: HofladenComponent },
      { path: "brot", redirectTo: "/hofladen", pathMatch: "full" },
      { path: "hofcafe", component: HofcafeComponent },
      { path: "besonderes", redirectTo: "/hofcafe", pathMatch: "full" },
      { path: "blumen", component: BlumenComponent },
      { path: "agrarservice", component: AgrarserviceComponent },
      { path: "ackerbau", component: AckerbauComponent },
      { path: "bilder", component: BilderComponent },
      { path: "oeffnungszeiten", component: OeffnungszeitenComponent },
      { path: "datenschutz", component: DatenschutzComponent },
      { path: "impressum", component: ImpressumComponent },
      { path: "#", component: SwitcherComponent }
    ]
  },

  { path: "auth-login", component: AuthLoginComponent },
  { path: "auth-cover-login", component: AuthCoverLoginComponent },
  { path: "auth-cover-re-password", component: AuthCoverRePasswordComponent },
  { path: "auth-cover-signup", component: AuthCoverSignupComponent },
  { path: "auth-login-three", component: AuthLoginThreeComponent },
  { path: "auth-re-password", component: AuthRePasswordComponent },
  { path: "auth-re-password-three", component: AuthRePasswordThreeComponent },
  { path: "auth-signup", component: AuthSignupComponent },
  { path: "auth-signup-three", component: AuthSignupThreeComponent },

  { path: "email-alert", component: EmailAlertComponent },
  { path: "email-confirmation", component: EmailConfirmationComponent },
  { path: "email-invoice", component: EmailInvoiceComponent },
  { path: "email-password-reset", component: EmailPasswordResetComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
