<!-- counter Start -->
<section class="section bg-light">
	<div class="container"></div>
</section>
<section class="section">
	<div class="container pt-200">
		<div class="row align-items-center">
			<div class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
				<div class="section-title mr-lg-5">
					<h4 class="title mb-4">
						Herzlich Willkommen in unserm <span class="text-primary font-weight-bold">Hof- und Gartencafè mit Kinderspielplatz</span>
					</h4>
					<h6>Ankommen, Wohlfühlen, Genießen</h6>
					<p class="text-muted">
						Unser Hofcafè hat einen besonderen Scharm, entstanden in unserer ehemals landwirtschaftlich genutzten Scheune, deren Tore im Dez 2014 durch verglaste Fensterfronten ausgetauscht wurden, und somit ein Isolierter Raum entstand. Im Shabby-Chic Stil und mit alten Möbeln eingerichtet, liebevoll dekoriert, zu einem harmonischen Gesamten, so das so mancher Gast rückmeldete, er habe sich wie zu Hause im Wohnzimmer gefühlt.
						Bei schönem Wetter haben sie die Möglichkeit in unserem gemütlichen Gartencafè zu verweilen. <br>
						Ganz unbeabsichtigt hat sich unser Innenhof zum idealen Aufenthaltsort für Eltern mit Kindern entwickelt, da die Kinder auf dem Spielplatz beschäftigt sind, die Eltern entspannt Kaffee trinken und sich unterhalten können und trotzdem die Kinder im Blick haben.
					</p>
					<p>
						Geöffnet: <br>
						Mittwochs <br>
						09.00-18.00 Uhr Kaffee und Kuchen <br>
						09.00-12.30 Uhr Frühstücksbüfett - nur mit Reservierung <br> <br>
						Samstags 14tägig <br>
						09.00-16.00 Uhr Kaffee und Kuchen <br>
						09.00-12.30 Uhr Frühstücksbüfett - nur mit Reservierung

					</p>
					<!-- a href="javascript:void(0)" class="btn btn-outline-primary">Start Now <i
							class="mdi mdi-chevron-right"></i>
					</a -->
				</div>
			</div>
			<!--end col-->

			<div class="col-md-6 order-1 order-md-2">
				<img src="../../../assets/images/willkommen_besonderes.jpg" class="img-fluid" alt="">
			</div>
			<!--end col-->
		</div>
		<!--end row-->
	</div>
	<!--end container-->

	<app-sociallink-hofgut></app-sociallink-hofgut>
</section>
<!--end section-->

<section class="section">
	<div class="container">
		<div class="row">
			<ul class="col container-filter list-unstyled categories-filter text-center mb-0" id="filter">
				<li class="list-inline-item"><a class="categories border d-block text-dark rounded active"
												(click)="activeCategory('all')"
												[ngClass]="{'active': galleryFilter ==='all'}" data-filter="*">Alle</a>
				</li>
				<li class="list-inline-item" *ngFor="let item of categoryList">
					<a class="categories border d-block text-dark rounded"
					   (click)="activeCategory(item.type)"
					   [ngClass]="{'active': galleryFilter === item.type}"
					>{{item.title}}</a>
				</li>
			</ul>
		</div>
		<!--end row-->

		<div class="row projects-wrapper">
			<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 business" *ngFor="let item of filteredImages">
				<div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
					<img src="{{item.image}}" class="img-fluid work-image" alt="">
					<div class="card-body">
						<div class="content">
							<!-- a href="javascript:void(0)" class="badge badge-primary mr-1" *ngFor="let type of item.types">{{type}}</a -->
							<h5 class="mt-3 text-dark title" [innerHTML]="item.title"></h5>
							<!-- p class="text-muted">This is required when, for example, the final text is not yet
								available.</p>
							<a href="javascript:void(0)" class="text-primary h6">Read More
								<i-feather name="arrow-right" class="fea icon-sm"></i-feather>
							</a -->
						</div>
					</div>
				</div>
			</div>
			<!--end col-->

		</div>
		<!--end row-->
	</div>
	<!--end container-->
</section>
<!--end section-->
