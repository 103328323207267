import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/changelog/queries';
import * as Mutation from '../_graphql/changelog/mutations';

import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ChangelogService {
	changelogs; // List of changelogs
	changelog: any = {};

	constructor(
		private apollo: Apollo,
	) {
	}

	/**
	 * ----------------------------------------------------
	 * Get One Changelog
	 * ----------------------------------------------------
	 * @method getChangelog
	 */
	getChangelog(id) {
		return this.apollo
			.watchQuery({
				query    : Query.changelog,
				variables: {
					id,
				},
			})
			.valueChanges
			.pipe(
				map((result: any) => result.data.changelog),
			);
	}

	/**
	 * ----------------------------------------------------
	 * Get All changelogs
	 * ----------------------------------------------------
	 * @method getChangelog
	 */
	getChangelogs(username?, param?, orderBy?, limit?) {
		return this.apollo.watchQuery({
			query    : Query.changelogs,
			variables: {
				username,
				param,
				orderBy,
				limit,
			},
		})
			.valueChanges
			.pipe(
				map((result: any) => result.data.changelogs),
			);
	}

	/**
	 * Create changelog
	 * @param changelogData
	 */
	createChangelog(changelogData) {
		this.apollo
			.mutate({
				mutation      : Mutation.addChangelog,
				variables     : {
					data: changelogData,
				},
				refetchQueries: [{
					query: Query.changelogs,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the query', error);
			});
	}

	/**
	 * Update changelog
	 * @param id
	 * @param changelogData
	 */
	updateChangelog(id, changelogData) {
		this.apollo
			.mutate({
				mutation      : Mutation.updateChangelog,
				variables     : {
					id,
					data: changelogData,
				},
				refetchQueries: [{
					query: Query.changelogs,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
				return data;
			}, (error) => {
				console.log(' there was an error sending the query', error);
			});
	}

	/**
	 * Delete changelog
	 * @param id
	 */
	trashChangelog(id: string) {
		this.apollo
			.mutate({
				mutation      : Mutation.updateChangelog,
				variables     : {
					id,
					data: {
						deleted: true,
					},
				},
				refetchQueries: [{
					query: Query.changelogs,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}

	/**
	 * Delete changelog
	 * @param id
	 */
	/*
	deleteChangelog(id: string) {
		this.apollo
			.mutate({
				mutation: Mutation.deleteChangelog,
				variables: {
					id
				},
				refetchQueries: [ {
					query: Query.changelogs
				} ]
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}
	 */

}
