import { gql } from 'apollo-angular';
import { devicemodelFragments } from './fragments';

export const devicemodels = gql`
    query devicemodels($orderBy: String, $limit: Int){
        devicemodels(orderBy: $orderBy, limit: $limit){
            ...Devicemodel
        }
    }

    ${devicemodelFragments['devicemodel']}
`;

export const devicemodelsByParametersAndQuery = gql`
    query devicemodelsByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        devicemodelsByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Devicemodel
        }
    }

    ${devicemodelFragments['devicemodel']}
`;

export const devicemodel = gql`
    query devicemodel($id: ObjectID!) {
        devicemodel(id: $id) {
            ...Devicemodel
        }
    }

    ${devicemodelFragments['devicemodel']}
`;
