import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Devicemodel } from '../types';
import { Apollo } from 'apollo-angular';
import * as Query from '../_graphql/devicemodel/queries';
import * as Mutation from '../_graphql/devicemodel/mutations';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
	providedIn: 'root',
})
export class DevicemodelsService {
	// Private
	private _devicemodel: BehaviorSubject<Devicemodel | null> = new BehaviorSubject(null);
	private _devicemodels: BehaviorSubject<Devicemodel[] | null> = new BehaviorSubject(null);

	/**
	 * Constructor
	 */
	constructor(
		private _httpClient: HttpClient,
		private apollo: Apollo,
	) {
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for devicemodel
	 */
	get devicemodel$(): Observable<Devicemodel> {
		return this._devicemodel.asObservable();
	}

	/**
	 * Getter for devicemodels
	 */
	get devicemodels$(): Observable<Devicemodel[]> {
		return this._devicemodels.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Get devicemodels
	 */
	allDevicemodels(param?, orderBy?, limit?): Observable<Devicemodel[]> {
		return this.apollo.query({
			query    : Query.devicemodels,
			variables: {
				param,
				orderBy,
				limit,
			},
		})
			.pipe(
				map((result: any) => {
					const devicemodels = result.data.devicemodels;
					// Update the things
					this._devicemodels.next(devicemodels);
					// Return the things
					return devicemodels;
				}),
			);
	}

	/**
	 * Search devicemodels with given query
	 *
	 * @param params
	 * @param query
	 */
	getDevicemodelsByParametersAndQuery(params: string[], query: string): Observable<Devicemodel[]> {
		return this.apollo.query({
			query    : Query.devicemodelsByParametersAndQuery,
			variables: {
				params,
				query,
			},
		})
			.pipe(
				map((result: any) => {
					const devicemodels = result.data.devicemodelsByParametersAndQuery;
					// Update the things
					this._devicemodels.next(devicemodels);
					// Return the things
					return devicemodels;
				}),
			);
	}

	/**
	 * Get devicemodel by id
	 */
	getDevicemodelById(id: string): Observable<Devicemodel> {
		return this._devicemodels.pipe(
			take(1),
			map((devicemodels) => {
				// Find the devicemodel
				const devicemodel = devicemodels.find(item => item.id === id) || null;
				// Update the devicemodel
				this._devicemodel.next(devicemodel);
				// Return the devicemodel
				return devicemodel;
			}),
			switchMap((devicemodel) => {

				if (!devicemodel) {
					return throwError('Could not found devicemodel with id of ' + id + '!');
				}

				return of(devicemodel);
			}),
		);
	}

	/**
	 * Create devicemodel
	 */
	createDevicemodel(): Observable<Devicemodel> {
		const newDevicemodel = {
			id    : FuseMockApiUtils.guid(),
			title : 'Neues Gerät',
			hersteller: null,
			servicepartner : null,
		};
		return this.devicemodels$.pipe(
			take(1),
			switchMap(devicemodels => this.apollo
				.mutate({
					mutation : Mutation.addDevicemodel,
					variables: {
						data: newDevicemodel,
					},
				}).pipe(
					map((result: any) => {
						// console.log('newDevicemodel: ', newDevicemodel);
						const devicemodel: Devicemodel = result.data.addDevicemodel;
						// console.log('devicemodel: ', devicemodel);
						// Update the devicemodels with the new devicemodel
						this._devicemodels.next([devicemodel, ...devicemodels]);
						// Return the new devicemodel
						return devicemodel;
					}),
				)),
		);
	}

	/**
	 * Update devicemodel
	 *
	 * @param id
	 * @param devicemodelData
	 */
	updateDevicemodel(id: string, devicemodelData: Devicemodel): Observable<Devicemodel> {
		return this.devicemodels$
			.pipe(
				take(1),
				switchMap(devicemodels => this.apollo
					.mutate({
						mutation : Mutation.updateDevicemodel,
						variables: {
							// id: id,
							id,
							data: devicemodelData,
						},
					}).pipe(
						map((result: any) => {

							const updatedDevicemodel = devicemodelData;
							// Find the index of the updated devicemodel
							const index = devicemodels.findIndex(item => item._id === id);

							// Update the devicemodel
							const newDevicemodels = _.cloneDeep(devicemodels);
							newDevicemodels[index] = updatedDevicemodel;

							// Update the devicemodels
							this._devicemodels.next(newDevicemodels);

							// Return the updated devicemodel
							return updatedDevicemodel;
						}),
						switchMap(updatedDevicemodel => this.devicemodel$.pipe(
							take(1),
							filter(item => item && item._id === id),
							tap(() => {

								// Update the devicemodel if it's selected
								this._devicemodel.next(updatedDevicemodel);

								// Return the updated devicemodel
								return updatedDevicemodel;
							}),
						)),
					)),
			);
	}

	/**
	 * Delete the devicemodel
	 *
	 * @param id
	 */
	deleteDevicemodel(id: string): Observable<boolean> {
		return this.devicemodels$.pipe(
			take(1),
			switchMap(devicemodels => this.apollo.mutate(
				{
					mutation : Mutation.addDevicemodel,
					variables: {
						id,
					},
				},
			).pipe(
				map((result: any) => {

					// Find the index of the deleted user
					const index = devicemodels.findIndex(item => item.id === id);

					// Delete the user
					devicemodels.splice(index, 1);

					// Update the users
					this._devicemodels.next(devicemodels);

					// Return the deleted status
					return true;
				}),
			)),
		);
	}


}
