import { gql } from 'apollo-angular';
import { countryFragments } from './fragments';

export const addCountry = gql`
    mutation addCountry($data: CountryInput!) {
        addCountry(data: $data) {
            ...Country
        }
    }

    ${countryFragments['country']}
`;

export const deleteCountry = gql`
    mutation deleteCountry($id: ObjectID!) {
        deleteCountry(id: $id) {
            ...Country
        }
    }

    ${countryFragments['country']}
`;

export const updateCountry = gql`
    mutation updateCountry ($id: ObjectID!, $data: CountryInput!) {
        updateCountry (id: $id, data: $data) {
            ...Country
        }
    }

    ${countryFragments['country']}
`;



