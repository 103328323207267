import { Apollo } from "apollo-angular";
import { Injectable } from "@angular/core";
import * as Query from "../_graphql/content/queries";
import * as Mutation from "../_graphql/content/mutations";

import { map, switchMap, take } from "rxjs/operators";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { Contact, Content } from "../types";

@Injectable({
	providedIn: "root"
})
export class ContentsService {

	// Private
	private _content: BehaviorSubject<Content | null> = new BehaviorSubject(null);
	private _contents: BehaviorSubject<Content[] | null> = new BehaviorSubject(null);

	/**
	 * Constructor
	 */

	constructor(
		private apollo: Apollo
	) {
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for task
	 */
	get content$(): Observable<Content> {
		return this._content.asObservable();
	}

	/**
	 * Getter for tasks
	 */
	get contents$(): Observable<Content[]> {
		return this._contents.asObservable();
	}

	/**
	 * ----------------------------------------------------
	 * Get One Content
	 * ----------------------------------------------------
	 * @method getContent
	 */
	getContent(id: string): Observable<Content> {
		return this.apollo.query({
				query: Query.content,
				variables: {
					id
				}
			})
			.pipe(
				map((result: any) => {
						const content = result.data.content;
						console.log("-> content", content);
						// Update the things
						this._content.next(content);
						// Return the things
						return content;
					}
				)
			);
	}

	/**
	 * Get contact by id
	 */
	getContactById(id: string): Observable<Contact> {
		return this._contents.pipe(
			take(1),
			map((contents) => {
				// Find the contact
				const content = contents.find(item => item.id === id) || null;
				// Update the contact
				this._content.next(content);
				// Return the contact
				return content;
			}),
			switchMap((contact) => {

				if (!contact) {
					return throwError("Could not find contact with id of " + id + "!");
				}

				return of(contact);
			})
		);
	}

	/**
	 * ----------------------------------------------------
	 * Get All contents
	 * ----------------------------------------------------
	 * @method getContents
	 */
	getContents():Observable<Content[]> {
		return this.apollo.query({
			query: Query.contents
		})
			.pipe(
				map((result: any) => {
					const contents = result.data.contents;
					// Update the things
					this._contents.next(contents);
					// Return the things
					return contents;
				})
			);
	}

	/**
	 * Create content
	 * @param contentData
	 */
	createContent(contentData):Observable<Content> {
		return this.apollo
			.mutate({
				mutation: Mutation.addContent,
				variables: {
					data: contentData
				},
				refetchQueries: [{
					query: Query.contents
				}]
			}).pipe(
			map((result: any) => {
					const content = result.data.content;
					// Update the things
					this._content.next(content);
					// Return the things
					return content;
				}
			));
	}

	/**
	 * Update content
	 * @param id
	 * @param contentData
	 */
	updateContent(id, contentData): Observable<Content> {
		return this.apollo
			.mutate({
				mutation: Mutation.updateContent,
				variables: {
					id,
					data: contentData
				},
				refetchQueries: [{
					query: Query.contents
				}]
			}).pipe(
				map((result: any) => {
						const content = result.data.content;
						// Update the things
						this._content.next(content);
						// Return the things
						return content;
					}
				));
	}

	/**
	 * Update content
	 * @param id
	 * @param fileData
	 */
	addFileToContentFilelist(id, fileData) {
		this.apollo
			.mutate({
				mutation: Mutation.addFileToContentFilelist,
				variables: {
					id,
					data: fileData
				},
				refetchQueries: [{
					query: Query.contents
				}]
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log(" there was an error sending the query", error);
			});
	}

	/**
	 * Update content
	 * @param id
	 * @param fileId
	 */
	removeFileFromContentFilelist(id, fileId) {
		console.log("id: ", id);
		console.log("fileId: ", fileId);
		this.apollo
			.mutate({
				mutation: Mutation.removeFileFromContentFilelist,
				variables: {
					id,
					fileId
				},
				refetchQueries: [{
					query: Query.contents
				}]
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log(" there was an error sending the query", error);
			});
	}

	/**
	 * Delete content
	 * @param id
	 */
	deleteContent(id: string):Observable<Content> {
		return this.apollo
			.mutate({
				mutation: Mutation.deleteContent,
				variables: {
					id
				},
				refetchQueries: [{
					query: Query.contents
				}]
			}).pipe(
			map((result: any) => {
					const content = result.data.content;
					// Update the things
					this._content.next(content);
					// Return the things
					return content;
				}
			));
	}

}
