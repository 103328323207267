import { gql } from 'apollo-angular';

import { taskFragments } from './fragments';

export const tasks = gql`
    query tasks($username: String, $param: String, $orderBy: String, $limit: Int){
        tasks(username: $username, param: $param, orderBy: $orderBy, limit: $limit){
            ...Task
        }
    }

    ${taskFragments['task']}
`;

export const tasksByParam = gql`
    query tasksByParam($param: String!, $orderBy: String, $limit: Int){
        tasksByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Task
        }
    }
    ${taskFragments['task']}
`;

export const tasksByParamAndValue = gql`
    query tasksByParamAndValue($param: String!, $value: StringOrInt!, $orderBy: String, $limit: Int){
        tasksByParamAndValue(param: $param, value: $value, orderBy: $orderBy, limit: $limit){
            ...Task
        }
    }
    ${taskFragments['task']}
`;


export const tasksByParametersAndQuery = gql`
    query tasksByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        tasksByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Task
        }
    }
    ${taskFragments['task']}
`;

export const tasksByUsernameAndDate = gql`
    query tasksByUsernameAndDate($username: String, $date: Date, $orderBy: String, $limit: Int){
        tasksByUsernameAndDate(username: $username, date: $date, orderBy: $orderBy, limit: $limit){
            ...Task
        }
    }
    ${taskFragments['task']}
`;

export const tasksByUserAndDate = gql`
    query tasksByUserAndDate($userId: ObjectID!, $date: Date!, $range: String, $orderBy: String, $limit: Int){
        tasksByUserAndDate(userId: $userId, date: $date, range: $range, orderBy: $orderBy, limit: $limit){
            ...Task
        }
    }
    ${taskFragments['task']}
`;

export const task = gql`
    query task($id: ObjectID!) {
        task(id: $id) {
            ...Task
        }
    }
    ${taskFragments['task']}
`;
