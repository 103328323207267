import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/event/queries';
import * as Mutation from '../_graphql/event/mutations';

import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class EventsService {
	events; // List of events
	event: any = {};

	constructor(
		private apollo: Apollo,
	) {
	}

	/**
	 * ----------------------------------------------------
	 * Get One Event
	 * ----------------------------------------------------
	 * @method getEvent
	 */
	getEvent(id) {
		return this.apollo
			.watchQuery({
				query    : Query.eventDetail,
				variables: {
					id,
				},
			})
			.valueChanges
			.pipe(
				map((result: any) => result.data.eventDetail),
			);
	}

	/**
	 * ----------------------------------------------------
	 * Get All events
	 * ----------------------------------------------------
	 * @method getEvent
	 */
	getEvents(username?, param?, orderBy?, limit?) {
		return this.apollo.watchQuery({
			query    : Query.events,
			variables: {
				username,
				param,
				orderBy,
				limit,
			},
		})
			.valueChanges
			.pipe(
				map((result: any) => result.data.events),
			);
	}


	/**
	 * Create event
	 * @param eventData
	 */
	createEvent(eventData) {
		this.apollo
			.mutate({
				mutation      : Mutation.addEvent,
				variables     : {
					data: eventData,
				},
				refetchQueries: [{
					query: Query.events,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the query', error);
			});
	}

	/**
	 * Update event
	 * @param id
	 * @param eventData
	 */
	updateEvent(id, eventData) {
		this.apollo
			.mutate({
				mutation      : Mutation.updateEvent,
				variables     : {
					id,
					data: eventData,
				},
				refetchQueries: [{
					query: Query.events,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
				return data;
			}, (error) => {
				console.log(' there was an error sending the query', error);
			});
	}

	/**
	 * Delete event
	 * @param id
	 */
	trashEvent(id: string) {
		this.apollo
			.mutate({
				mutation      : Mutation.updateEvent,
				variables     : {
					id,
					data: {
						deleted: true,
					},
				},
				refetchQueries: [{
					query: Query.events,
				}],
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}

	/**
	 * Delete event
	 * @param id
	 */

	deleteEvent(id: string) {
		this.apollo
			.mutate({
				mutation: Mutation.deleteEvent,
				variables: {
					id
				},
				refetchQueries: [ {
					query: Query.events
				} ]
			})
			.subscribe(({ data }) => {
				console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}


}
