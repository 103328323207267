import { gql } from "apollo-angular";
import { contactFragments } from "./fragments";

export const addContact = gql`
    mutation addContact($data: ContactInput) {
        addContact(data: $data) {
            ...Contact
        }
    }
    ${contactFragments['contact']}
`;

export const updateContact = gql`
    mutation updateContact($id: ObjectID!, $data: ContactInput!) {
        updateContact(id: $id, data: $data) {
            ...Contact
        }
    }
    ${contactFragments['contact']}
`;

export const trashContact = gql`
    mutation trashContact($id: ObjectID!) {
        trashContact(id: $id) {
            ...Contact
        }
    }
    ${contactFragments['contact']}
`;

export const untrashContact = gql`
    mutation untrashContact($id: ObjectID!) {
        untrashContact(id: $id) {
            ...Contact
        }
    }
    ${contactFragments['contact']}
`;

export const deleteContact = gql`
    mutation deleteContact($id: ObjectID!) {
        deleteContact(id: $id) {
            ...Contact
        }
    }
    ${contactFragments['contact']}
`;
