import { gql } from 'apollo-angular';
import { userFragments } from './fragments';

export const addUser = gql`
    mutation addUser($data: UserInput!) {
        addUser(data: $data) {
            ...User
        }
    }
    
    ${userFragments['user']}
`;

export const deleteUser = gql`
    mutation deleteUser($id: ObjectID!) {
        deleteUser(id: $id) {
            ...User
        }
    }
    
    ${userFragments['user']}
`;

export const updateUser = gql`
    mutation updateUser($id: ObjectID!, $data: UserInput!) {
        updateUser(id: $id, data: $data) {
            ...User
        }
    }
    
    ${userFragments['user']}
`;
