import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import * as Query from '../_graphql/media/queries';
import * as Mutation from '../_graphql/media/mutations';
import { Media } from '../types';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root'
})
export class MediasService {
    // Private
    private _media: BehaviorSubject<Media | null> = new BehaviorSubject(null);
    private _medias: BehaviorSubject<Media[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private apollo: Apollo
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for media
     */
    get media$(): Observable<Media> {
        return this._media.asObservable();
    }
    /**
     * Getter for medias
     */
    get medias$(): Observable<Media[]> {
        return this._medias.asObservable();
    }


    /**
     * Get media by id
     */
    getMediaById(id: string): Observable<Media> {
        return this._medias.pipe(
            take(1),
            map((medias) => {
                // Find the media
                const media = medias.find(item => item.id === id) || null;
                // Update the media
                this._media.next(media);
                // Return the media
                return media;
            }),
            switchMap((media) => {

                if (!media) {
                    return throwError('Could not found media with id of ' + id + '!');
                }

                return of(media);
            }),
        );
    }
    

    /**
     * ----------------------------------------------------
     * Get All Medias
     * ----------------------------------------------------
     * @method getMedias
     */
    getMedias(param?, orderBy?, limit?): Observable<Media[]> {
        return this.apollo.query({
            query    : Query.medias,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const medias = result.data.medias;
                    // Update the things
                    this._medias.next(medias);
                    // Return the things
                    return medias;
                }),
            );
    }

  /**
   * ----------------------------------------------------
   * Get All medias by Type
   * ----------------------------------------------------
   * @method getMediasByType
   */
  getMediasByType(mediatype?, orderBy?, limit?): Observable<Media[]> {
    return this.apollo.query({
      query: Query.mediasByType,
      variables: {
        mediatype,
        orderBy,
        limit
      }
    })
      .pipe(
        map((result: any) => {
          const medias = result.data.mediasByType;
          // Update the things
          this._medias.next(medias);
          // Return the things
          return medias;
        }),
      );
  }

    /**
     * Search medias with given query
     *
     * @param query
     */
    getMediasByHandle(query: string): Observable<Media[]> {
        return this.apollo.query({
            query    : Query.mediasByHandle,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const medias = result.data.mediasByHandle;
                    // Update the things
                    this._medias.next(medias);
                    // Return the things
                    return medias;
                }),
            );
    }

    /**
     * Search medias with given query
     *
     * @param params
     * @param query
     */
    mediasByParametersAndQuery(params: string[], query: string): Observable<Media[]> {
        return this.apollo.query({
            query    : Query.mediasByParametersAndQuery,
            variables: {
                params,
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const medias = result.data.mediasByParametersAndQuery;
                    // Update the things
                    this._medias.next(medias);
                    // Return the things
                    return medias;
                }),
            );
    }

    /**
     * Create media
     *
     * @param mediaData
     */
    createMedia(mediaData?: Media): Observable<Media> {
        const newMedia = {
            id    : FuseMockApiUtils.guid(),
            title : 'Neues Werkzeug',
            serial: null,
            notes : null,
        };
        return this.medias$.pipe(
            take(1),
            switchMap(medias => this.apollo
                .mutate({
                    mutation : Mutation.addMedia,
                    variables: {
                        data: mediaData || newMedia,
                    },
                }).pipe(
                    map((result: any) => {
                        const media: Media = result.data.addMedia;
                        // Update the medias with the new media
                        this._medias.next([media, ...medias]);
                        // Return the new media
                        return media;
                    }),
                )),
        );
    }

    /**
     * Update media
     *
     * @param id
     * @param mediaData
     */
    updateMedia(id: string, mediaData: Media): Observable<Media> {
        return this.medias$
            .pipe(
                take(1),
                switchMap(medias => this.apollo
                    .mutate({
                        mutation : Mutation.updateMedia,
                        variables: {
                            id,
                            data: mediaData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedMedia = mediaData;
                            // Find the index of the updated media
                            const index = medias.findIndex(item => item.id === id);

                            // Update the media
                            const newMedias = _.cloneDeep(medias);
                            newMedias[index] = updatedMedia;

                            // Update the medias
                            this._medias.next(newMedias);

                            // Return the updated media
                            return updatedMedia;
                        }),
                        switchMap(updatedMedia => this.media$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {

                                // Update the media if it's selected
                                this._media.next(updatedMedia);

                                // Return the updated media
                                return updatedMedia;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete media
     * @param id
     */
    trashMedia(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateMedia,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete media
     * @param id
     */
    untrashMedia(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateMedia,
                variables     : {
                    id,
                    data: {
                        deleted: false,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete the media
     *
     * @param id
     */
    deleteMedia(id: string): Observable<boolean> {
        return this.medias$.pipe(
            take(1),
            switchMap(medias => this.apollo
                .mutate({
                    mutation: Mutation.deleteMedia,
                    variables: {
                        id
                    }
                }).pipe(
                map((result: any) => {

                    // Find the index of the deleted media
                    const index = medias.findIndex(item => item.id === id);

                    // Delete the media
                    medias.splice(index, 1);

                    // Update the medias
                    this._medias.next(medias);

                    // Return the deleted status
                    return true;
                }),
            )),
        );
    }

}
