import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const priceFragments: {
    [ key: string ]: DocumentNode
} = {
    price: gql`
        fragment Price on Price{
            _id
            title
            preisinfo
            sparsaison{
                _id
                preis
                infotext
                preisinfo
                buchbar
                buchungsinfo
            }
            nebensaison{
                _id
                preis
                infotext
                preisinfo
                buchbar
                buchungsinfo
            }
            hauptsaison{
                _id
                preis
                infotext
                preisinfo
                buchbar
                buchungsinfo
            }
        }
    `,
};
