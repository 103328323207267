import { gql } from 'apollo-angular';
import { requestFragments } from './fragments';

export const request = gql`
    query request($id: ObjectID!) {
        request(id: $id) {
            ...Request
        }
    }
    ${requestFragments['request']}
`;

export const requests = gql`
    query requests($orderBy: String, $limit: Int){
        requests(orderBy: $orderBy, limit: $limit){
            ...Request
        }
    }
    ${requestFragments['request']}
`;
