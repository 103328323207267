import {Injectable} from '@angular/core';

import {
    Component,
    NgModule,
    NgModuleRef,
    Injector,
    Compiler,
    ViewContainerRef
} from '@angular/core';

import {Subject} from 'rxjs';

@Injectable()
export class SidenavService {
    public subject = new Subject<string>();

    private sidenavVCRef: ViewContainerRef;

    constructor(private compiler: Compiler, private injector: Injector,
                private ngModuleRef: NgModuleRef<any>) {}

    // getSidenavVCRef() { return this.sidenavVCRef; }

    setSidenavVCRef(vc: ViewContainerRef) { this.sidenavVCRef = vc; }

    publish(eventName: string, componentName?: string) {
        console.log('qua', this.subject, eventName);
        if (componentName) {
            this.loadComponent(componentName).then(() => {
                this.subject.next(eventName);
            });
        } else {
            this.subject.next(eventName);
        }
    }

    // Create component on the fly and return its componentRef
    private loadComponent(name: string): Promise<boolean> {

        // create component and modules on the fly
        const template = `<span>I am ${name}</span>`;
        const tmpComp = Component({template})(class {});
        const tmpModule = NgModule({declarations : [ tmpComp ]})(class {});

        // compile the created modules and component,
        // and get a hold of it with a componentRef
        return new Promise(resolve => {
            this.compiler.compileModuleAndAllComponentsAsync(tmpModule)
                .then(factories => {
                    // Compiler will return all factories for corresponding _components
                    // to be created
                    // Just take the first one as it is the only one.
                    const f = factories.componentFactories[0];

                    // create (component) view from its factory and return componentRef
                    const compRef = f.create(this.injector, [], null, this.ngModuleRef);

                    // just detach all previous views from view container
                    this.sidenavVCRef.detach();
                    // insert it to its view container
                    this.sidenavVCRef.insert(compRef.hostView);

                    resolve(true);
                })
                .catch(error => { console.log(error); });
        });
    }
}
