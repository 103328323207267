import { gql } from 'apollo-angular';

import { DocumentNode } from 'graphql';
import { tagFragments } from '../tag/fragments';
import { categoryFragments } from '../category/fragments';

export const mediaFragments: {
    [ key: string ]: DocumentNode
} = {
    media: gql`
        fragment Media on Media{
            _id
            title
            src
            thumb
            beschreibung
            category
            mediatype
            caption
            alt
            link
            directSrc
            quellenangabe
            height
            width
            size
            crop
            border
            show
            categories{
                ...Category
            }
            tags {
                ...Tag
            }
        }

        ${categoryFragments['category']}
        ${tagFragments['tag']}
    `,

};
