import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of, throwError, catchError } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import { Tag, Task, User } from "../types";

import { Apollo } from "apollo-angular";
import * as Query from "../_graphql/task/queries";
import * as Mutation from "../_graphql/task/mutations";
import { FuseMockApiUtils } from "../mock-api";
import * as _ from "lodash";

@Injectable({
    providedIn: "root"
})
export class TasksService {
    // Private
    private _tags: BehaviorSubject<Tag[] | null> = new BehaviorSubject(null);
    private _task: BehaviorSubject<Task | null> = new BehaviorSubject(null);
    private _tasks: BehaviorSubject<Task[] | null> = new BehaviorSubject(null);
    private _users: BehaviorSubject<User[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _apollo: Apollo
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for task
     */
    get task$(): Observable<Task> {
        return this._task.asObservable();
    }

    /**
     * Getter for tasks
     */
    get tasks$(): Observable<Task[]> {
        return this._tasks.asObservable();
    }

    /**
     * Getter for tags
     */
    get tags$(): Observable<Tag[]> {
        return this._tags.asObservable();
    }

    /**
     * Getter for users
     */
    get users$(): Observable<User[]> {
        return this._users.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get tasks
     */
    getTasks(param?, orderBy?, limit?): Observable<Task[]> {
        return this._apollo.query({
            query: Query.tasks,
            variables: {
                param,
                orderBy,
                limit
            }
        })
            .pipe(
                map((result: any) => {
                    const tasks = result.data.tasks;
                    // Update the things
                    this._tasks.next(tasks);
                    // Return the things
                    return tasks;
                })
            );
    }

    /**
     * Search tasks with given query
     *
     * @param params
     * @param query
     */
    getTasksByParametersAndQuery(params: string[], query: string): Observable<Task[]> {
        return this._apollo.query({
            query    : Query.tasksByParametersAndQuery,
            variables: {
                params,
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const tasks = result.data.tasksByParametersAndQuery;
                    // Update the things
                    this._tasks.next(tasks);
                    // Return the things
                    return tasks;
                }),
            );
    }

    /**
     * Get tags
     */
    getTags(): Observable<Tag[]> {
        return this._httpClient.get<Tag[]>("api/apps/tasks/tags").pipe(
            tap((response: any) => {
                this._tags.next(response);
            })
        );
    }

    /**
     * Create tag
     *
     * @param tag
     */
    createTag(tag: Tag): Observable<Tag> {
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this._httpClient.post<Tag>("api/apps/tasks/tag", { tag }).pipe(
                map((newTag) => {

                    // Update the tags with the new tag
                    this._tags.next([...tags, newTag]);

                    // Return new tag from observable
                    return newTag;
                })
            ))
        );
    }

    /**
     * Update the tag
     *
     * @param id
     * @param tag
     */
    updateTag(id: string, tag: Tag): Observable<Tag> {
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this._httpClient.patch<Tag>("api/apps/tasks/tag", {
                id,
                tag
            }).pipe(
                map((updatedTag) => {

                    // Find the index of the updated tag
                    const index = tags.findIndex(item => item.id === id);

                    // Update the tag
                    tags[index] = updatedTag;

                    // Update the tags
                    this._tags.next(tags);

                    // Return the updated tag
                    return updatedTag;
                })
            ))
        );
    }

    /**
     * Delete the tag
     *
     * @param id
     */
    deleteTag(id: string): Observable<boolean> {
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this._httpClient.delete("api/apps/tasks/tag", { params: { id } }).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted tag
                    const index = tags.findIndex(item => item.id === id);

                    // Delete the tag
                    tags.splice(index, 1);

                    // Update the tags
                    this._tags.next(tags);

                    // Return the deleted status
                    return isDeleted;
                }),
                filter(isDeleted => isDeleted),
                switchMap(isDeleted => this.tasks$.pipe(
                    take(1),
                    map((tasks) => {

                        // Iterate through the tasks
                        tasks.forEach((task) => {

                            const tagIndex = task.tags.findIndex(tag => tag === id);

                            // If the task has a tag, remove it
                            if (tagIndex > -1) {
                                task.tags.splice(tagIndex, 1);
                            }
                        });

                        // Return the deleted status
                        return isDeleted;
                    })
                ))
            ))
        );
    }

    /**
     * Update tasks orders
     *
     * @param tasks
     */
    updateTasksOrders(tasks: Task[]): Observable<Task[]> {
        return this._httpClient.patch<Task[]>("api/apps/tasks/order", { tasks });
    }

    /**
     * Get tasks
     */
    getUsers(param?, orderBy?, limit?): Observable<User[]> {
        return this._apollo.query({
            query: Query.tasks,
            variables: {
                param,
                orderBy,
                limit
            }
        })
            .pipe(
                map((result: any) => {
                    const tasks = result.data.tasks;
                    // Update the things
                    this._tasks.next(tasks);
                    // Return the things
                    return tasks;
                })
            );
    }
    /**
     * Search tasks with given query
     *
     * @param query
     */
    searchTasks(query: string): Observable<Task[] | null> {
        return this._httpClient.get<Task[] | null>("api/apps/tasks/search", { params: { query } });
    }

    /**
     * Get task by id
     */
    getTaskById(id: string): Observable<Task> {
        return this._tasks.pipe(
            take(1),
            map((tasks) => {

                // Find the task
                const task = tasks.find(item => item.id === id) || null;

                // Update the task
                this._task.next(task);

                // Return the task
                return task;
            }),
            switchMap((task) => {

                if (!task) {
                    return throwError("Could not found task with id of " + id + "!");
                }

                return of(task);
            })
        );
    }

    /**
     * Create task
     *
     * @param type
     */
    createTask(type: "task" | "section"): Observable<Task> {
        const newTask: Task = {
            id          : FuseMockApiUtils.guid(),
            type        : type,
            title       : '',
            notes       : null,
            completed   : false,
            dueDate     : null,
            priority    : 1,
            tags        : [],
            order       : 0
        };
        return this.tasks$.pipe(
            take(1),
            switchMap(tasks => this._apollo
                .mutate({
                    mutation: Mutation.addTask,
                    variables: {
                        data: newTask
                    }
                }).pipe(
                    map((result: any) => {
                        const newTask = result.data.addTask
                        console.log("newTask: ", newTask);
                        // Update the tasks with the new task
                        this._tasks.next([newTask, ...tasks]);

                        // Return the new task
                        return newTask;
                    })
                ))
        );
    }

    /**
     * Update task
     *
     * @param id
     * @param taskData
     */
    updateTask(id: string, taskData: Task): Observable<Task> {
        return this.tasks$
            .pipe(
                take(1),
                switchMap(tasks => this._apollo
                    .mutate({
                        mutation: Mutation.updateTask,
                        variables: {
                            // id: id,
                            id: taskData._id,
                            data: taskData
                        }
                    }).pipe(
                    map((result: any) => {

                        const updatedTask = taskData;
                        // Find the index of the updated task
                        const index = tasks.findIndex(item => item.id === id);

                        // Update the task
                        const newTasks = _.cloneDeep(tasks)
                        newTasks[index] = updatedTask;

                        // Update the tasks
                        this._tasks.next(newTasks);

                        // Return the updated task
                        return updatedTask;
                    }),
                    switchMap(updatedTask => this.task$.pipe(
                        take(1),
                        filter(item => item && item.id === id),
                        tap(() => {

                            // Update the task if it's selected
                            this._task.next(updatedTask);

                            // Return the updated task
                            return updatedTask;
                        })
                    ))
                ))
            );
    }

    /**
     * Delete the task
     *
     * @param _id
     * @param id
     */
    deleteTask(_id:string, id: string): Observable<boolean> {
        return this.tasks$.pipe(
            take(1),
            switchMap(tasks => this._apollo
                .mutate({
                    mutation: Mutation.deleteTask,
                    variables: {
                        id: _id
                    }
                })
                .pipe(
                map((result: any) => {
                    if (!result) {
                        return false
                    } else {
                        const deletedTask = result.data.addTask
                        // Find the index of the deleted task
                        const index = tasks.findIndex(item => item.id === id);

                        // Delete the task
                        const newTasks = _.cloneDeep(tasks)
                        newTasks.splice(index, 1);

                        // Update the tasks
                        this._tasks.next(newTasks);

                        // Return the deleted status
                        return true;
                    }

                })
            ))
        );
    }
}
