import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const thingFragments: {
    [ key: string ]: DocumentNode
} = {
    thing: gql`
        fragment Thing on Thing{
            _id
            title
            thingType
            thingStatus
            wiederholen {
                cyclus
                days
            }
            categories
            delegated
            text
            notes
            startDate
            dueDate
            completed
            starred
            important
            deleted
            deadline
            show
            relatedThings
            files
            medias
            tags
            delegated
            createUserId
        }

    `,
};
