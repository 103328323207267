import { gql } from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const requestFragments: {
	[key: string]: DocumentNode
} = {
	request: gql`
        fragment Request on Request{
            _id
            name
            email
            telefon
            betreff
            nachricht
            done
            createdAt
        }

	`,
};
