import { gql } from 'apollo-angular';
import { mediaFragments } from './fragments';

export const addMedia = gql`
    mutation addMedia($data: MediaInput!) {
        addMedia(data: $data) {
            ...Media
        }
    }
    ${mediaFragments['media']}
`;

export const updateMedia = gql`
    mutation updateMedia ($id: ObjectID!, $data: MediaInput!) {
        updateMedia (id: $id, data: $data) {
            ...Media
        }
    }
    ${mediaFragments['media']}
`;

export const deleteMedia = gql`
    mutation deleteMedia($id: ObjectID!) {
        deleteMedia(id: $id) {
            ...Media
        }
    }
    ${mediaFragments['media']}
`;



