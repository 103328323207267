import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';
import { devicemodelFragments } from '../devicemodel/fragments';

export const deviceFragments: {
    [ key: string ]: DocumentNode
} = {
    device: gql`
        fragment Device on Device{
            _id
            id
            serial
            model {
                ... Devicemodel
            }
            inventarnummer
            componed
            components
            fixed
            status
            tasks
            changelog
            standorthistory
            things
            notes
            purchase
            baujahr
        }

        ${devicemodelFragments['devicemodel']}
    `,
};
