import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';
import { categoryFragments } from '../category/fragments';
import { userFragments } from '../user/fragments';
import { locationFragments } from '../location/fragments';
import { teaserFragments } from '../teaser/fragments';
import { mediaFragments } from '../media/fragments';
import { tagFragments } from '../tag/fragments';


export const articleFragments: {
    [ key: string ]: DocumentNode
} = {
    article: gql`
        fragment Article on Article{
            _id
            title
            subtitle
            text
            navTitle
            sort
            categories {
                ...Category
            }
            events {
                _id
                title
                category
                text
                articletext
                image
                sorting
                datetimeArchiv
                seasons
                start
                end
                show
                veranstalter
                wetter
            }
            locations{
                ...Location
            }
            medias {
                ...Media
            }
            teaser {
                ...Teaser
            }
            tags {
                ...Tag
            }
            show
            createdAt
            createdBy {
                ...User
            }
        }

        ${categoryFragments['category']}
        ${locationFragments['location']}
        ${mediaFragments['media']}
        ${teaserFragments['teaser']}
        ${tagFragments['tag']}
        ${userFragments['user']}
    `,
};
