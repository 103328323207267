<!-- Team Start -->
<section class="section bg-muted">

	<div class="container mt-60">
		<div class="row justify-content-center">
			<div class="col-12 text-center">
				<div class="section-title mb-4 pb-2">
					<h4 class="title mb-4">Unser Team</h4>
					<!-- p class="text-muted para-desc mx-auto mb-0">Start working with <span
							class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
						awareness, drive traffic, connect.
					</p -->
				</div>
			</div>
			<!--end col-->
		</div>
		<!--end row-->

		<div class="row">
			<div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let member of teamMembers">
				<div class="card team text-center border-0">
					<div class="card-body">
						<div class="position-relative">
							<img [src]="member.image" class="img-fluid avatar avatar-ex-large rounded-circle shadow"
								 alt="">
							<!--end icon-->
						</div>
						<div class="content pt-3 pb-3">
							<h5 class="mb-0 name text-dark" [innerHTML]="member.name"></h5>
							<!-- small class="designation text-muted" [innerHTML]="member.description"></small -->
							<h6 class="text-muted font-weight-normal" [innerHTML]="member.description"></h6>
						</div>
					</div>
				</div>
			</div>
			<!--end col-->

		</div>
		<!--end row-->
	</div>
</section>
<!--end container-->
<!-- Team End -->
