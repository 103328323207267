 <!-- Footer Start -->
 <footer class="footer">
   <div class="container">
     <div class="row">
       <div class="col-lg-9 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
         <h4 class="footer-head mb-10">Kontakt</h4>
         <div class="row">
           <div class="col-lg-12 mb-20">
             <h5 class="text-muted">Daniela und Andreas Streit</h5>
           </div>
           <div class="col-lg-4">
             <h6 class="mb-0">Backstube · Hofladen · Hofgarten</h6>
             <p>Weil‘s einfach anders schmeckt!</p>
             <p class="text-muted">
               Schulstraße 28a<br>
               78256 Steißlingen<br>
               <a href="mailto:hofladen@hofgut-streit.de">hofladen&#64;hofgut-streit.de</a><br>
               mobil Daniela: <a href="tel:00491607056810">+49 (0)160 / 70&nbsp;56&nbsp;810</a>
             </p>
             <p class="text-muted">
               Telefon: <a href="tel:00497738922852">+49&nbsp;(0)7738&nbsp;/&nbsp;92&nbsp;28&nbsp;52</a><br>
               Fax: +49&nbsp;(0)7738&nbsp;/&nbsp;92&nbsp;28&nbsp;50
             </p>
           </div>
           <div class="col-lg-4">
             <h6 class="mb-0">Besondere Schnittblumen</h6>
             <p>von April bis Oktober</p>
             <p class="text-muted">
               Eglisbrunnen 8<br>
               78256 Steißlingen<br>
               <a href="mailto:schnittblumen@hofgut-streit.de">schnittblumen&#64;hofgut-streit.de</a><br>
               mobil Daniela: <a href="tel:00491607056810">+49 (0)160 / 70&nbsp;56&nbsp;810</a>
             </p>
           </div>
           <div class="col-lg-4">
             <h6 class="mb-0">Agrarservice & Ackerbau</h6>
             <p>Landw. Lohnunternehmen Streit</p>
             <p class="text-muted">
               Schulstraße 28a<br>
               78256 Steißlingen<br>
               <a href="mailto:lohnunternehmen@hofgut-streit.de">lu&#64;hofgut-streit.de</a><br>
               mobil Andreas: <a href="tel:00491719904120">+49&nbsp;(0)171&nbsp;/&nbsp;99&nbsp;04&nbsp;120</a>
             </p>
           </div>
         </div>
         <!--end icon-->
       </div>
       <!--end col-->

       <div class="col-lg-3 col-md-3 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
         <h4 class="footer-head">Anfahrt Schnittblumenfeld</h4>
         <img src="../../../assets/images/schnittblumen_anfahrt.png" class="img-fluid" alt="">
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->
 </footer>
 <!--end footer-->
 <footer class="footer footer-bar">
   <div class="container text-center">
     <div class="row align-items-center">
       <div class="col-sm-6">
         <div class="text-sm-left">
           <p class="mb-0">
             ©2024 Hofladen Streit. Alle Rechte vorbehalten.
           </p>
         </div>
       </div>
       <!--end col-->

       <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
         <div class="text-sm-right">
           <p class="mb-0">
             <a routerLink="/datenschutz">Datenschutz</a>
             &nbsp;|&nbsp;
             <a routerLink="/impressum">Impressum</a>
             &nbsp;|&nbsp;
             <a *ngIf="!logged" routerLink="/auth-login"><i class="uil uil-lock"></i></a>
             <a *ngIf="logged" routerLink="/"><i class="uil uil-unlock"></i></a>
           </p>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->
 </footer>
 <!--end footer-->
 <!-- Footer End -->
