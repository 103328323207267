<!-- counter Start -->
<section class="section bg-light">
	<div class="container"></div>
</section>
<section class="section">
	<div class="container pt-200">
		<div class="row align-items-center">
			<div class="col-md-12">
				<div class="section-title mr-lg-5">
					<h1 class="title mb-4">
						<span class="text-primary font-weight-bold">Impressum</span>
					</h1>
					<p>Copyright 2021 hofgut-streit.de</p>

					<p>
						Alle Rechte vorbehalten. Jede Verwertung der Informationen dieser Website ist nur mit
						schriftlicher Genehmigung unsererseits gestattet.
					</p>

					<h4>
						Inhaltlich Verantwortlicher gem § 6 MDStV:
					</h4>

					<p>
						Geschäftsführer Andreas Streit
					</p>

					<h4>
						Inhalt und Bilder:
					</h4>

					<p>
						Daniela Streit</p>

					<h4>
						Verantwortungsbereich:
					</h4>

					<p>
						Das Impressum gilt nur für die Internetpräsenz unter der Adresse:
						http://www.hofgut-streit.de
					</p>

					<h4>
						Abgrenzung:
					</h4>

					<p>
						Die Web-Präsenz ist Teil des WWW und dementsprechend mit fremden, sich jederzeit wandeln
						könnenden Web-Sites verknüpft, die folglich auch nicht diesem Verantwortungsbereich unterliegen
						und für die nachfolgende Informationen nicht gelten. Dass die Links weder gegen Sitten noch
						Gesetze verstoßen, wurde genau ein mal geprüft: bevor sie hier aufgenommen wurden. Solche Links,
						die zu fremden Webprojekten führen, erkennen Sie daran: Es geht bei Links auf fremde Webseiten
						ein neues Browserfenster auf.
					</p>

					<h4>
						Herausgeber:
					</h4>

					<p>
						Andreas Streit
						<br>
						Schulstraße 28a
						<br>
						78256 Steißlingen
						<br>
						<br>
						Telefon: 07738 / 922852
						<br>
						Telefax: 07738 / 922850
						<br>
						e-Mail: info&#64;hofgut-streit.de
						<br>
						Internet: www.hofgut-streit.de
					<br>
						USt.-IdNr.: DE175987578
					</p>

					<h4>
						Wichtig:
					</h4>

					<p>
						Im Falle von Namensrecht-/Domainstreitigkeiten bzw. Abmahnungen z.B. gegen geltendes
						Fernabsatzgesetz bitten wir Sie, zur Vermeidung unnötiger Rechtsstreite und Kosten, uns bereits
						im Vorfeld zu kontaktieren. Die Kostennote einer anwaltlichen Abmahnung ohne vorhergehende
						Kontaktaufnahme wird im Sinne der Schadensminderungspflicht als unbegründet zurückgewiesen.
					</p>
				</div>
			</div>
			<!--end col-->
		</div>
		<!--end row-->
	</div>
	<!--end container-->
</section>
