import {gql} from 'apollo-angular';
import { toolFragments } from './fragments';

export const tools = gql`
    query tools($orderBy: String, $limit: Int){
        tools(orderBy: $orderBy, limit: $limit){
            ...Tool
        }
    }
    
    ${toolFragments['tool']}
`;

export const toolsByParam = gql`
    query toolsByParam($param: String!, $orderBy: String, $limit: Int){
        toolsByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Tool
        }
    }
    
    ${toolFragments['tool']}
`;

export const toolsByQuery = gql`
    query toolsByQuery($query: String!, $orderBy: String, $limit: Int){
        toolsByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...Tool
        }
    }
    ${toolFragments['tool']}
`;


export const toolsByHandle = gql`
    query toolsByHandle($handle: String!, $orderBy: String, $limit: Int){
        toolsByHandle(handle: $handle, orderBy: $orderBy, limit: $limit){
            ...Tool
        }
    }
    
    ${toolFragments['tool']}
`;


export const toolsByParametersAndQuery = gql`
    query toolsByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        toolsByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Tool
        }
    }
    
    ${toolFragments['tool']}
`;

export const tool = gql`
    query tool($id: ObjectID!) {
        tool(id: $id) {
            ...Tool
        }
    }
    
    ${toolFragments['tool']}
`;
