import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Projectgroup } from '../types';
import { Apollo } from 'apollo-angular';
import * as Query from '../_graphql/projectgroup/queries';
import * as Mutation from '../_graphql/projectgroup/mutations';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root',
})
export class ProjectgroupsService {
    // Private
    private _data: BehaviorSubject<any> = new BehaviorSubject(null);
    private _projectgroup: BehaviorSubject<Projectgroup | null> = new BehaviorSubject(null);
    private _projectgroups: BehaviorSubject<Projectgroup[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private apollo: Apollo,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<any>
    {
        return this._data.asObservable();
    }

    /**
     * Getter for projectgroup
     */
    get projectgroup$(): Observable<Projectgroup> {
        return this._projectgroup.asObservable();
    }

    /**
     * Getter for projectgroups
     */
    get projectgroups$(): Observable<Projectgroup[]> {
        return this._projectgroups.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getData(): Observable<any>
    {
        return this._httpClient.get('api/dashboards/projectgroup').pipe(
            tap((response: any) => {
                this._data.next(response);
            })
        );
    }

    /**
     * Get projectgroups
     */
    allProjectgroups(param?, orderBy?, limit?): Observable<Projectgroup[]> {
        return this.apollo.query({
            query    : Query.projectgroups,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const projectgroups = result.data.projectgroups;
                    // Update the things
                    this._projectgroups.next(projectgroups);
                    // Return the things
                    return projectgroups;
                }),
            );
    }

    /**
     * Search projectgroups with given query
     *
     * @param query
     */
    getProjectgroupsByQuery(query: string): Observable<Projectgroup[]> {
        return this.apollo.query({
            query    : Query.projectgroupsByQuery,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const projectgroups = result.data.projectgroupsByQuery;
                    // Update the things
                    this._projectgroups.next(projectgroups);
                    // Return the things
                    return projectgroups;
                }),
            );
    }

    /**
     * Search projectgroups with given query
     *
     * @param params
     * @param query
     */
    projectgroupsByParametersAndQuery(params: string[], query: string): Observable<Projectgroup[]> {
        return this.apollo.query({
            query    : Query.projectgroupsByParametersAndQuery,
            variables: {
                params,
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const projectgroups = result.data.projectgroupsByParametersAndQuery;
                    // Update the things
                    this._projectgroups.next(projectgroups);
                    // Return the things
                    return projectgroups;
                }),
            );
    }

    /**
     * Get projectgroup by id
     */
    getProjectgroupById(id: string): Observable<Projectgroup> {
        return this._projectgroups.pipe(
            take(1),
            map((projectgroups) => {
                // Find the projectgroup
                const projectgroup = projectgroups.find(item => item.id === id) || null;
                // Update the projectgroup
                this._projectgroup.next(projectgroup);
                // Return the projectgroup
                return projectgroup;
            }),
            switchMap((projectgroup) => {

                if (!projectgroup) {
                    return throwError('Could not found projectgroup with id of ' + id + '!');
                }

                return of(projectgroup);
            }),
        );
    }

    /**
     * Create projectgroup
     */
    createProjectgroup(): Observable<Projectgroup> {
        const newProjectgroup = {
            id    : FuseMockApiUtils.guid(),
            title: null,
            ende : null,
        };
        return this.projectgroups$.pipe(
            take(1),
            switchMap(projectgroups => this.apollo
                .mutate({
                    mutation : Mutation.addProjectgroup,
                    variables: {
                        data: newProjectgroup,
                    },
                }).pipe(
                    map((result: any) => {
                        // console.log('newProjectgroup: ', newProjectgroup);
                        const projectgroup: Projectgroup = result.data.addProjectgroup;
                        // console.log('projectgroup: ', projectgroup);
                        // Update the projectgroups with the new projectgroup
                        this._projectgroups.next([projectgroup, ...projectgroups]);
                        // Return the new projectgroup
                        return projectgroup;
                    }),
                )),
        );
    }

    /**
     * Update projectgroup
     *
     * @param id
     * @param projectgroupData
     */
    updateProjectgroup(id: string, projectgroupData: Projectgroup): Observable<Projectgroup> {
        return this.projectgroups$
            .pipe(
                take(1),
                switchMap(projectgroups => this.apollo
                    .mutate({
                        mutation : Mutation.updateProjectgroup,
                        variables: {
                            // id: id,
                            id,
                            data: projectgroupData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedProjectgroup = projectgroupData;
                            // Find the index of the updated projectgroup
                            const index = projectgroups.findIndex(item => item._id === id);

                            // Update the projectgroup
                            const newProjectgroups = _.cloneDeep(projectgroups);
                            newProjectgroups[index] = updatedProjectgroup;

                            // Update the projectgroups
                            this._projectgroups.next(newProjectgroups);

                            // Return the updated projectgroup
                            return updatedProjectgroup;
                        }),
                        switchMap(updatedProjectgroup => this.projectgroup$.pipe(
                            take(1),
                            filter(item => item && item._id === id),
                            tap(() => {

                                // Update the projectgroup if it's selected
                                this._projectgroup.next(updatedProjectgroup);

                                // Return the updated projectgroup
                                return updatedProjectgroup;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete the projectgroup
     *
     * @param id
     */
    deleteProjectgroup(id: string): Observable<boolean> {
        return this.projectgroups$.pipe(
            take(1),
            switchMap(projectgroups => this._httpClient.delete('api/apps/projectgroups/projectgroup', { params: { id } }).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted projectgroup
                    const index = projectgroups.findIndex(item => item.id === id);

                    // Delete the projectgroup
                    projectgroups.splice(index, 1);

                    // Update the projectgroups
                    this._projectgroups.next(projectgroups);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Delete projectgroup
     * @param id
     */
    trashProjectgroup(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateProjectgroup,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                },
                refetchQueries: [{
                    query: Query.projectgroups,
                }],
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete projectgroup
     * @param id
     */
    _deleteProjectgroup(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.deleteProjectgroup,
                variables     : {
                    id,
                },
                refetchQueries: [{
                    query: Query.projectgroups,
                }],
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }
}
