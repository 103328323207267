import { gql } from 'apollo-angular';
import { priceFragments } from './fragments';

export const addPrice = gql`
    mutation addPrice($data: PriceInput!) {
        addPrice(data: $data) {
            ...Price
        }
    }

    ${priceFragments['price']}
`;
export const updatePrice = gql`
    mutation updatePrice($id: ObjectID!, $data: PriceInput!) {
        updatePrice(id: $id, data: $data) {
            ...Price
        }
    }

    ${priceFragments['price']}
`;

export const deletePrice = gql`
    mutation deletePrice($id: ObjectID!) {
        deletePrice(id: $id) {
            ...Price
        }
    }

    ${priceFragments['price']}
`;
