import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/country/queries';
import * as Mutation from '../_graphql/country/mutations';

import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

import { Country } from '../types';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root'
})
export class CountrysService {
    // Private
    private _country: BehaviorSubject<Country | null> = new BehaviorSubject(null);
    private _countrys: BehaviorSubject<Country[] | null> = new BehaviorSubject(null);

    countrys: Observable<Country[]>;
    country: Observable<Country>;

    constructor(
        private apollo: Apollo
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for country
     */
    get country$(): Observable<Country> {
        return this._country.asObservable();
    }
    /**
     * Getter for countrys
     */
    get countrys$(): Observable<Country[]> {
        return this._countrys.asObservable();
    }


    /**
     * Get country by id
     */
    getCountryById(id: string): Observable<Country> {
        return this._countrys.pipe(
            take(1),
            map((countrys) => {
                // Find the country
                const country = countrys.find(item => item.id === id) || null;
                // Update the country
                this._country.next(country);
                // Return the country
                return country;
            }),
            switchMap((country) => {

                if (!country) {
                    return throwError('Could not found country with id of ' + id + '!');
                }

                return of(country);
            }),
        );
    }
    

    /**
     * ----------------------------------------------------
     * Get All Countrys
     * ----------------------------------------------------
     * @method getCountrys
     */
    getCountrys(param?, orderBy?, limit?): Observable<Country[]> {
        return this.apollo.query({
            query    : Query.countrys,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const countrys = result.data.countrys;
                    // Update the things
                    this._countrys.next(countrys);
                    // Return the things
                    return countrys;
                }),
            );
    }

    /**
     * Search countrys with given query
     *
     * @param query
     */
    getCountrysByHandle(query: string): Observable<Country[]> {
        return this.apollo.query({
            query    : Query.countrysByHandle,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const countrys = result.data.countrysByHandle;
                    // Update the things
                    this._countrys.next(countrys);
                    // Return the things
                    return countrys;
                }),
            );
    }

    /**
     * Create country
     */
    createCountry(): Observable<Country> {
        const newCountry = {
            id       : FuseMockApiUtils.guid(),
            avatar   : null,
            firstName: null,
            lastName : 'Neuer Kontakt',
            email    : null,
            birthday : null,
            admin    : false,
            roles    : ['country'],
        };
        console.log('preMutation newCountry: ', newCountry);
        return this.countrys$.pipe(
            take(1),
            switchMap(countrys => this.apollo
                .mutate({
                    mutation : Mutation.addCountry,
                    variables: {
                        data: newCountry,
                    },
                }).pipe(
                    map((result: any) => {
                        console.log('newCountry: ', newCountry);
                        const country: Country = result.data.addCountry;
                        console.log('country: ', country);
                        // Update the countrys with the new country
                        this._countrys.next([country, ...countrys]);
                        // Return the new country
                        return country;
                    }),
                )),
        );
    }


    /**
     * Update country
     *
     * @param id
     * @param countryData
     */
    updateCountry(id: string, countryData: Country): Observable<Country> {
        return this.countrys$
            .pipe(
                take(1),
                switchMap(countrys => this.apollo
                    .mutate({
                        mutation : Mutation.updateCountry,
                        variables: {
                            id,
                            data: countryData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedCountry = countryData;
                            // Find the index of the updated country
                            const index = countrys.findIndex(item => item.id === id);

                            // Update the country
                            const newCountrys = _.cloneDeep(countrys);
                            newCountrys[index] = updatedCountry;

                            // Update the countrys
                            this._countrys.next(newCountrys);

                            // Return the updated country
                            return updatedCountry;
                        }),
                        switchMap(updatedCountry => this.country$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {

                                // Update the country if it's selected
                                this._country.next(updatedCountry);

                                // Return the updated country
                                return updatedCountry;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete country
     * @param id
     */
    trashCountry(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateCountry,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete the country
     *
     * @param id
     */
    deleteCountry(id: string): Observable<boolean> {
        return this.countrys$.pipe(
            take(1),
            switchMap(countrys => this.apollo
                .mutate({
                    mutation: Mutation.deleteCountry,
                    variables: {
                        id
                    }
                }).pipe(
                map((result: any) => {

                    // Find the index of the deleted country
                    const index = countrys.findIndex(item => item.id === id);

                    // Delete the country
                    countrys.splice(index, 1);

                    // Update the countrys
                    this._countrys.next(countrys);

                    // Return the deleted status
                    return true;
                }),
            )),
        );
    }

}
