import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import * as Query from '../_graphql/housing/queries';
import * as Mutation from '../_graphql/housing/mutations';
import { Housing } from '../types';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root'
})
export class HousingsService {
    // Private
    private _housing: BehaviorSubject<Housing | null> = new BehaviorSubject(null);
    private _housings: BehaviorSubject<Housing[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private apollo: Apollo
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for housing
     */
    get housing$(): Observable<Housing> {
        return this._housing.asObservable();
    }
    /**
     * Getter for housings
     */
    get housings$(): Observable<Housing[]> {
        return this._housings.asObservable();
    }


    /**
     * Get housing by id
     */
    getHousingById(id: string): Observable<Housing> {
        return this._housings.pipe(
            take(1),
            map((housings) => {
                // Find the housing
                const housing = housings.find(item => item.id === id) || null;
                // Update the housing
                this._housing.next(housing);
                // Return the housing
                return housing;
            }),
            switchMap((housing) => {

                if (!housing) {
                    return throwError('Could not found housing with id of ' + id + '!');
                }

                return of(housing);
            }),
        );
    }
    

    /**
     * ----------------------------------------------------
     * Get All Housings
     * ----------------------------------------------------
     * @method getHousings
     */
    getHousings(param?, orderBy?, limit?): Observable<Housing[]> {
        return this.apollo.query({
            query    : Query.housings,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const housings = result.data.housings;
                    // Update the things
                    this._housings.next(housings);
                    // Return the things
                    return housings;
                }),
            );
    }

    /**
     * Search housings with given query
     *
     * @param query
     */
    getHousingsByHandle(query: string): Observable<Housing[]> {
        return this.apollo.query({
            query    : Query.housingsByHandle,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const housings = result.data.housingsByHandle;
                    // Update the things
                    this._housings.next(housings);
                    // Return the things
                    return housings;
                }),
            );
    }

    /**
     * Search housings with given query
     *
     * @param params
     * @param query
     */
    housingsByParametersAndQuery(params: string[], query: string): Observable<Housing[]> {
        return this.apollo.query({
            query    : Query.housingsByParametersAndQuery,
            variables: {
                params,
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const housings = result.data.housingsByParametersAndQuery;
                    // Update the things
                    this._housings.next(housings);
                    // Return the things
                    return housings;
                }),
            );
    }

    /**
     * Create housing
     *
     * @param housingData
     */
    createHousing(housingData?: Housing): Observable<Housing> {
        const newHousing = {
            id    : FuseMockApiUtils.guid(),
            title : 'Neues Werkzeug',
            serial: null,
            notes : null,
        };
        return this.housings$.pipe(
            take(1),
            switchMap(housings => this.apollo
                .mutate({
                    mutation : Mutation.addHousing,
                    variables: {
                        data: housingData || newHousing,
                    },
                }).pipe(
                    map((result: any) => {
                        const housing: Housing = result.data.addHousing;
                        // Update the housings with the new housing
                        this._housings.next([housing, ...housings]);
                        // Return the new housing
                        return housing;
                    }),
                )),
        );
    }

    /**
     * Update housing
     *
     * @param id
     * @param housingData
     */
    updateHousing(id: string, housingData: Housing): Observable<Housing> {
        return this.housings$
            .pipe(
                take(1),
                switchMap(housings => this.apollo
                    .mutate({
                        mutation : Mutation.updateHousing,
                        variables: {
                            id,
                            data: housingData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedHousing = housingData;
                            // Find the index of the updated housing
                            const index = housings.findIndex(item => item.id === id);

                            // Update the housing
                            const newHousings = _.cloneDeep(housings);
                            newHousings[index] = updatedHousing;

                            // Update the housings
                            this._housings.next(newHousings);

                            // Return the updated housing
                            return updatedHousing;
                        }),
                        switchMap(updatedHousing => this.housing$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {

                                // Update the housing if it's selected
                                this._housing.next(updatedHousing);

                                // Return the updated housing
                                return updatedHousing;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete housing
     * @param id
     */
    trashHousing(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateHousing,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete housing
     * @param id
     */
    untrashHousing(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateHousing,
                variables     : {
                    id,
                    data: {
                        deleted: false,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete the housing
     *
     * @param id
     */
    deleteHousing(id: string): Observable<boolean> {
        return this.housings$.pipe(
            take(1),
            switchMap(housings => this.apollo
                .mutate({
                    mutation: Mutation.deleteHousing,
                    variables: {
                        id
                    }
                }).pipe(
                map((result: any) => {

                    // Find the index of the deleted housing
                    const index = housings.findIndex(item => item.id === id);

                    // Delete the housing
                    housings.splice(index, 1);

                    // Update the housings
                    this._housings.next(housings);

                    // Return the deleted status
                    return true;
                }),
            )),
        );
    }

}
