import { gql } from 'apollo-angular';

export const signupUser = gql`
    mutation signupUser($data: UserSignUpInput!) {
        signupUser(data: $data) {
            token
            user {
                _id
            }
        }
    }`;

