import { gql } from "apollo-angular";

import { vehicleFragments } from "./fragments";

export const addVehicle = gql`
    mutation addVehicle($data: VehicleInput!) {
        addVehicle(data: $data) {
            ...Vehicle
        }
    }

    ${vehicleFragments['vehicle']}
`;

export const deleteVehicle = gql`
    mutation deleteVehicle($id: ObjectID!) {
        deleteVehicle(id: $id) {
            ...Vehicle
        }
    }

    ${vehicleFragments['vehicle']}
`;

export const updateVehicle = gql`
    mutation updateVehicle ($id: ObjectID!, $data: VehicleInput!) {
        updateVehicle (id: $id, data: $data) {
            ...Vehicle
        }
    }

    ${vehicleFragments['vehicle']}
`;
