import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-auth-re-password-three',
    templateUrl: './auth-re-password-three.component.html',
    styleUrls: ['./auth-re-password-three.component.css'],
    standalone: false
})
export class AuthRePasswordThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
