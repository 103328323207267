import { Apollo } from "apollo-angular";
import { Injectable, OnDestroy } from "@angular/core";
import * as Mutation from "../_graphql/auth/mutations";
import * as UserMutation from "../_graphql/user/mutations";
import * as Query from "../_graphql/auth/queries";
import { Observable, BehaviorSubject, ReplaySubject, throwError, of, Subject } from "rxjs";

import { AuthInterface, User } from "../types";
import { Router } from "@angular/router";

import moment, { Moment } from 'moment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { UsersService } from "./users.service";
import { map, switchMap } from "rxjs/operators";

export const TOKEN_NAME = "id_token";
const helper = new JwtHelperService();

@Injectable({
	providedIn: "root"
})
export class AuthService implements OnDestroy{
	public email;
	public password;
	public user;

	private loginUrl = "/signed-out-redirect";
	private redirectUrl = "/";
	private _authenticated = false;
	private _isAuthenticated = new BehaviorSubject(false);
	private _isLoggedIn = new ReplaySubject(1);
	private _currentUser = new BehaviorSubject({});
	private _currentRoles = new BehaviorSubject([]);

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Setter & getter for access token
	 */
	set accessToken(token: string) {
		localStorage.setItem("accessToken", token);
	}

	get accessToken(): string {
		return localStorage.getItem("accessToken") ?? "";
	}

	get isAuthenticated(): Observable<any> {
		return this._isAuthenticated.asObservable();
	}

	get isLoggedIn(): Observable<any> {
		return this._isLoggedIn.asObservable();
	}

	get currentUser(): Observable<User> {
		return this._currentUser.asObservable();
	}

	get currentRoles(): Observable<any> {
		return this._currentRoles.asObservable();
	}

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private apollo: Apollo,
		private _router: Router,
		private _usersService: UsersService
	) {
		this.apollo = apollo;
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void
	{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(void 0);
		this._unsubscribeAll.complete();
	}

	signIn(credentials: { email: string; password: string }): Observable<any> {
		// Throw error, if the user is already logged in
		console.log("_isAuthenticated: ", this._authenticated);
		if (this._authenticated) {
			return throwError("User is already logged in!");
		}

		return this.apollo
			.query<AuthInterface>({
				query: Query.loginUser,
				variables: {
					data: credentials
				}
			}).pipe(
				switchMap((data: any) => {
					// Set the authenticated flag to true
					this._authenticated = true;

					this._isAuthenticated.next(true);

					// Store the user on the user service
					// this._usersService.user = response.user;

					// Return a new observable with the response
					return of(data);
				})
			);
	}

	async login(userData) {
		// Call the mutation called deleteUser
		await new Promise((resolve, reject) => {
			this.apollo
				.query<AuthInterface>({
					query: Query.loginUser,
					variables: {
						data: userData
					}
				})
				.subscribe({
					next: ({ data }) => {
						this.setSession(data.loginUser);

						this._currentUser.next(data.loginUser.user);
						console.log("LoginUser: ", data.loginUser.user);
						this._isAuthenticated.next(true);
						this._router.navigate([this.redirectUrl]);
						// update data
						resolve({
							success: true,
							message: "User #${_id} signed in successfully"
						});
					},
					error: (errors) => {
						console.log("there was an error sending the query", errors);
						reject({
							success: false,
							message: errors
						});
					}
				});
		});
	}

	/**
	 * Create User
	 */
	async register(userData) {
		console.log('userData: ', userData)
		await new Promise((resolve, reject) => {
			this.apollo
				.mutate<AuthInterface>({
					mutation: Mutation.signupUser,
					variables: {
						data: userData
					}
				})
				.subscribe({
					next: ({ data }) => {
						console.log("Data register: ", data);
						this.setSession(data.signupUser);

						// update data
						resolve({
							success: true,
							message: "User #${_id} signed in successfully"
						});
					},
					error: (errors) => {
						console.log("there was an error sending the query", errors);
						reject({
							success: false,
							message: errors
						});
					}
				});
		});
	}

	async checkToken(id, token) {
		// Call the mutation called checkToken
		await new Promise((resolve, reject) => {
			this.apollo
				.query<AuthInterface>({
					query: Query.checkToken,
					variables: {
						id,
						token
					}
				})
				.subscribe({
					next: ({ data }) => {
						// console.log('Data login: ' + JSON.stringify(data));
						//    this.setSession(data.loginUser);

						//    this._currentUser.next(data.loginUser.users);
						//    this._isAuthenticated.next(true);
						// update data
						resolve({
							success: true,
							message: "Token valid"
						});
					},
					error: (errors) => {
						console.log("there was an error sending the query", errors);
						this.logout();
						reject({
							success: false,
							message: errors
						});
					}
				});
		});
	}

	autoLogin() {
		const user = this.getUser();
		const token = this.getToken();

		// console.log("this.currentRoles: ", this.currentRoles);

		if (user && token) {
			if (this.checkToken(user._id, token)) {
				this.setUser(user);
				console.log("logged in...");

				return;
			}
		}

		// this._router.navigate([this.loginUrl]);
		console.log("not logged in...");
	}

	setRedirectUrl(url: string): void {
		this.redirectUrl = url;
	}

	getLoginUrl(): string {
		return this.loginUrl;
	}

	getToken(): string {
		return localStorage.getItem("id_token");
	}

	getUser(): User {
		return JSON.parse(localStorage.getItem("user"));
	}

	public CurrentUser(): User {
		return this.user;
	}

	getMe(): Promise<any> {
		const user = JSON.parse(localStorage.getItem("user"));
		let me = {};
		return new Promise((resolve, reject) => {
			this._usersService.getUser(user._id)
				.subscribe(user1 => {
					me = user1;
					resolve(me);
				}, reject);

		});
	}

	me() {
		this._currentUser.subscribe(
			(response) => {
				const user = response;
				// const roleExists = response.some(x => x === "admin");
				console.log("me: ", user);
				// console.log("roleExists: ", roleExists);

				return user;
			}
		);
		const user = JSON.parse(localStorage.getItem("user"));
		// Throw error, if the user is already logged in
		// if (!this._authenticated) {
		if (!this.isAuthenticated) {
			// console.log("user: ", user);
			return throwError("Nobody logged in");
		}

		return user;
	}

	/**
	 * Update the user
	 *
	 * @param user
	 */
	update(user: User): Observable<any> {
		return this.apollo
			.mutate({
				mutation: UserMutation.updateUser,
				variables: {
					id: user._id,
					data: user
				}
			}).pipe(
				map((result: any) => {

					const updatedUser = result.data;

					return updatedUser;
				}),
			)
		/*
		this._httpClient.patch<User>('api/common/user', {user}).pipe(
			map((response) => {
				this._user.next(response);
			})
		);
		 */
	}

	setToken(token) {
		localStorage.setItem("id_token", token);
		localStorage.setItem("accessToken", token);
	}

	getTokenExpirationDate(token: string): Date {
		const decodedHeader = helper.decodeToken(token);

		if (decodedHeader.exp === undefined) {
			return null;
		}
		const date = new Date(0);
		date.setUTCSeconds(decodedHeader.exp);

		return date;
	}

	isTokenExpired(token?: string): boolean {
		if (!token) {
			token = this.getToken();
		}
		if (!token) {
			return true;
		}

		const date = this.getTokenExpirationDate(token);
		if (date === undefined) {
			return false;
		}
		return !(date.valueOf() > new Date().valueOf());
		/*
		if (Date.now() >= exp * 1000) {
			return false;
		}
		 */
	}

	public isAdmin():boolean {
		const isAdmin =  this._currentRoles.subscribe(
			(response) => {
				const roles = response;
				const roleExists = response.some(x => x === "admin");
				console.log("roles: ", roles);
				console.log("roleExists: ", roleExists);
			}
		);
		return !!isAdmin
	}

	// TODO: Expiration funktioniert nicht

	public logout() {
		// remove users from local storage to log users out
		localStorage.removeItem("user");
		localStorage.removeItem("id_token");
		localStorage.removeItem("accessToken");
		localStorage.removeItem("expires_at");
		localStorage.removeItem("rememberMe");
		this._isLoggedIn.next(false);
		this._isAuthenticated.next(false);
		this._currentUser.next({});
		this._currentRoles.next([]);

		this._router.navigate([this.loginUrl]);
	}

	private setSession(authResult) {
		const expiresAt = moment().add("1h", "second");

		localStorage.setItem("id_token", authResult.token);
		localStorage.setItem("rememberMe", authResult.rememberMe);
		localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));

		this.setUser(authResult.user);
		return true;
	}

	setUser(user) {
		localStorage.setItem("user", JSON.stringify(user));
		this._isLoggedIn.next(true);
		this._isAuthenticated.next(true);
		this._currentUser.next(user);
		this._currentRoles.next(user.roles);
		return true;
	}
}
