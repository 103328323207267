import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-hofladen',
    templateUrl: './hofladen.component.html',
    styleUrls: ['./hofladen.component.scss'],
    standalone: false
})
export class HofladenComponent implements OnInit {
    navClass = 'bg-white';
    filteredImages;
    galleryFilter = 'all';

    currentDate = new Date();
    date = new Date("2022-06-04T22:00:00.000Z")
    meldungEnde = new Date("2024-12-23T22:00:00.000Z")
    ferienBeginn = new Date("2025-02-27T22:00:00.000Z")
    ferienEnde = new Date("2025-03-11T22:00:00.000Z")

    categoryList = [
        {
            title: 'Süßes',
            type: 'suess'
        },
        {
            title: 'Herzhaftes',
            type: 'herzhaft'
        },
        {
            title: 'Backstube',
            type: 'backstube'
        },
        {
            title: 'Hofladen',
            type: 'hofladen'
        }
    ];

    groupList = [
        {
            title: 'Dinkel',
            sort: 'suess'
        },
        {
            title: 'Dinkel',
            sort: 'suess'
        },
        {
            title: 'Dinkel',
            sort: 'suess'
        },
        {
            title: 'Dinkel',
            sort: 'suess'
        },
    ];

    imageList = [
        {
            image: 'assets/images/brot/brot_hofladen_1.jpg',
            title: 'Impression aus dem Hofladen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'hofladen',
        },
        {
            image: 'assets/images/brot/brot_hofladen_2.jpg',
            title: 'Impression aus dem Hofladen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'hofladen',
        },
        {
            image: 'assets/images/brot/brot_hofladen_3.jpg',
            title: 'Impression aus dem Hofladen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'hofladen',
        },
        {
            image: 'assets/images/brot/brot_hofladen_4.jpg',
            title: 'Impression aus dem Hofladen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'hofladen',
        },
        {
            image: 'assets/images/brot/brot_hofladen_5.jpg',
            title: 'In unserem Hofladen gibt es Honig aus Steißlingen von Karl-Max Schönenberger.',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'hofladen',
        },
        {
            image: 'assets/images/brot/brot_backstube_1.jpg',
            title: 'Herstellung Biskuitteig',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'backstube',
        },
        {
            image: 'assets/images/brot/brot_backstube_2.jpg',
            title: 'Impression aus der Backstube',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'backstube',
        },
        {
            image: 'assets/images/brot/brot_backstube_3.jpg',
            title: 'Impression aus der Backstube',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'backstube',
        },
        {
            image: 'assets/images/brot/brot_backstube_4.jpg',
            title: 'Unser Mehl beziehen wir aus der Steigmühle der Familie Leiber, Engen/Anselfingen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'backstube',
        },
        {
            image: 'assets/images/backstube/apfelrahmkuchen_streusel.jpg',
            title: 'Apfelrahmkuchen mit Streusel',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/bauernbrot_klein.jpg',
            title: 'Bauernbrot',
            description: 'Zutaten: Weizenmehl, Roggenmehl Typ 890 und Weizenvollkornmehl 1700, Ur-salz, Wasser, Frischhefe ',
            variant: 'success',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/berliner.jpg',
            title: 'Berliner',
            description: 'Zutaten: ',
            variant: 'success',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/baguettebrote.jpg',
            title: 'Baguettebrote',
            description: 'Zutaten: Weizenmehl, Roggenmehl Typ 890 und Weizenvollkornmehl 1700, Ur-salz, Wasser,Frischhefe ',
            variant: 'info',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/baguettebroetchen.jpg',
            title: 'Baguettebrötchen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/windbeutel.jpg',
            title: 'Windbeutel',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/zwetschgenkuchen.jpg',
            title: 'Zwetschgenkuchen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/weizenvollkornbrot.jpg',
            title: 'Weizenvollkornbrot',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/nussgipfel.jpg',
            title: 'Nussgipfel',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/nusszopf_zuckerguss.jpg',
            title: 'Nusszopf mit Zuckerguss',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/pizzazunge.jpg',
            title: 'Pizzazunge',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/puddingstreusel.jpg',
            title: 'Puddingstreusel',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },

        {
            image: 'assets/images/backstube/rosinenbroetchen.jpg',
            title: 'Rosinenbrötchen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/saatenbroetchen.jpg',
            title: 'Saatenbrötchen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/saatenbrot.jpg',
            title: 'Saatenbrot',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/spinatquiche.jpg',
            title: 'Spinatquiche',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/bienenstich.jpg',
            title: 'Bienenstich',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/weizenvollkornbaguette_saatenbaguette.jpg',
            title: 'Weizenvollkorn- & Saatenbaguette',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/laugengebaeck.jpg',
            title: 'Laugengebäck',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/laugenbroetchen.jpg',
            title: 'Laugenbrötchen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/karottenschokokuchen.jpg',
            title: 'Karottenschokokuchen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/kaesehimbeerkuchen.jpg',
            title: 'Käsehimbeerkuchen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/herzbuben_nussecken_linzerschnitten.jpg',
            title: 'Herzbuben, Nussecken & Linzerschnitten',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/hefezopf.jpg',
            title: 'Hefezopf',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/donauwelle.jpg',
            title: 'Donauwelle',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'suess',
        },
        {
            image: 'assets/images/backstube/dinkelbrot.jpg',
            title: 'Dinkelbrot',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'herzhaft',
        },
        {
            image: 'assets/images/backstube/broetchen.jpg',
            title: 'Brötchen',
            description: 'Zutaten: ',
            variant: 'primary',
            type: 'herzhaft',
        }
    ];

    constructor(
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.filteredImages = this.imageList;
    }

    openModal(content) {
        this.modalService.open(content, {centered: true});
    }

    activeCategory(category) {
        this.galleryFilter = category;
        if (this.galleryFilter === 'all') {
            this.filteredImages = this.imageList;
        } else {
            this.filteredImages = this.imageList.filter(x => x.type === this.galleryFilter);
        }
    }

}
