import { gql } from 'apollo-angular';

import { pageFragments } from './fragments';
import { sectionFragments } from '../section/fragments';
import { contentFragments } from '../content/fragments';
import { eventFragments } from '../event/fragments';
import { teaserFragments } from '../teaser/fragments';
import { mediaFragments } from '../media/fragments';
import { userFragments } from '../user/fragments';
import { fileFragments } from '../file/fragments';

export const pages = gql`
    query pages {
        pages {
            ...Page
            pid{
                ...Page
            }
            children {
                ...Page
            }
            contents {
                _id
                id
                title
                showTitle
                subtitle
                description
                header
                subheader
                contenttype
                text
                text2
                image
                showMedias
                showLink
                liste {
                    icon
                    listTitle
                    listText
                    listLink
                }
                events {
                    ...Event
                }
                files {
                    title
                    description
                    path
                    filename
                    mimetype
                }
                users {
                    name
                    lastName
                    firstName
                    birthday
                    entryDate
                    media {
                        ...Media
                    }
                }
                media {
                    ...Media
                }
                medias {
                    ...Media
                }
                content {
                    ...Content
                }
                contents {
                    ...Content
                }
                teasers {
                    ...Teaser
                }
            }
            sections {
                ...Section
            }
        }
    }

    ${contentFragments['content']}
    ${eventFragments['event']}
    ${mediaFragments['media']}
    ${pageFragments['page']}
    ${sectionFragments['section']}
    ${teaserFragments['teaser']}
    ${userFragments['user']}
`;

export const pageByNavTitle = gql`
    query pageByNavTitle($navTitle: String!) {
        pageByNavTitle(navTitle: $navTitle) {
            ...Page
            pid{
                ...Page
            }
            children {
                ...Page
            }
            contents {
                _id
                id
                title
                showTitle
                subtitle
                description
                header
                subheader
                contenttype
                text
                text2
                image
                showMedias
                showLink
                liste {
                    icon
                    listTitle
                    listText
                    listLink
                }
                events {
                    ...Event
                }
                users {
                    name
                    lastName
                    firstName
                    birthday
                    entryDate
                    media {
                        ...Media
                    }
                }
                files {
                    ...File
                }
                media {
                    ...Media
                }
                medias {
                    ...Media
                }
                content {
                    ...Content
                }
                contents {
                    ...Content
                }
                teasers {
                    ...Teaser
                }
            }
            sections {
                ...Section
            }
        }
    }

    ${contentFragments['content']}
    ${eventFragments['event']}
    ${fileFragments['file']}
    ${mediaFragments['media']}
    ${pageFragments['page']}
    ${sectionFragments['section']}
    ${teaserFragments['teaser']}
    ${userFragments['user']}
`;

export const pagesByParametersAndQuery = gql`
    query pagesByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int) {
        pagesByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit) {
            ...Page
            pid{
                ...Page
            }
            children {
                ...Page
            }
            contents {
                ...Content
                events {
                    ...Event
                }
                users {
                    ...User
                    media{
                        ...Media
                    }
                }
                media {
                    ...Media
                }
                medias {
                    ...Media
                }
                content {
                    ...Content
                }
                contents {
                    ...Content
                }
                teasers {
                    ...Teaser
                }
            }
            sections {
                ...Section
            }
        }
    }

    ${contentFragments['content']}
    ${eventFragments['event']}
    ${mediaFragments['media']}
    ${pageFragments['page']}
    ${sectionFragments['section']}
    ${teaserFragments['teaser']}
    ${userFragments['user']}
`;

export const page = gql`
    query page($id: ObjectID!) {
        page(id: $id) {
            ...Page

            pid{
                ...Page
            }
            children {
                ...Page
            }

            contents {
                ...Content
                events {
                    ...Event
                }
                users {
                    ...User
                    media{
                        ...Media
                    }
                }
                media {
                    ...Media
                }
                medias {
                    ...Media
                }
                content {
                    ...Content
                }
                contents {
                    ...Content
                }
                teasers {
                    ...Teaser
                }
            }
            sections {
                ...Section
            }
        }
    }

    ${contentFragments['content']}
    ${eventFragments['event']}
    ${mediaFragments['media']}
    ${pageFragments['page']}
    ${sectionFragments['section']}
    ${teaserFragments['teaser']}
    ${userFragments['user']}
`;
