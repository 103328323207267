import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import * as Query from '../_graphql/price/queries';
import * as Mutation from '../_graphql/price/mutations';
import { Price } from '../types';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root'
})
export class PricesService {
    // Private
    private _price: BehaviorSubject<Price | null> = new BehaviorSubject(null);
    private _prices: BehaviorSubject<Price[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private apollo: Apollo
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for price
     */
    get price$(): Observable<Price> {
        return this._price.asObservable();
    }
    /**
     * Getter for prices
     */
    get prices$(): Observable<Price[]> {
        return this._prices.asObservable();
    }


    /**
     * Get price by id
     */
    getPriceById(id: string): Observable<Price> {
        return this._prices.pipe(
            take(1),
            map((prices) => {
                // Find the price
                const price = prices.find(item => item.id === id) || null;
                // Update the price
                this._price.next(price);
                // Return the price
                return price;
            }),
            switchMap((price) => {

                if (!price) {
                    return throwError('Could not found price with id of ' + id + '!');
                }

                return of(price);
            }),
        );
    }
    

    /**
     * ----------------------------------------------------
     * Get All Prices
     * ----------------------------------------------------
     * @method getPrices
     */
    getPrices(param?, orderBy?, limit?): Observable<Price[]> {
        return this.apollo.query({
            query    : Query.prices,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const prices = result.data.prices;
                    // Update the things
                    this._prices.next(prices);
                    // Return the things
                    return prices;
                }),
            );
    }

    /**
     * Search prices with given query
     *
     * @param query
     */
    getPricesByHandle(query: string): Observable<Price[]> {
        return this.apollo.query({
            query    : Query.pricesByHandle,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const prices = result.data.pricesByHandle;
                    // Update the things
                    this._prices.next(prices);
                    // Return the things
                    return prices;
                }),
            );
    }

    /**
     * Search prices with given query
     *
     * @param params
     * @param query
     */
    pricesByParametersAndQuery(params: string[], query: string): Observable<Price[]> {
        return this.apollo.query({
            query    : Query.pricesByParametersAndQuery,
            variables: {
                params,
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const prices = result.data.pricesByParametersAndQuery;
                    // Update the things
                    this._prices.next(prices);
                    // Return the things
                    return prices;
                }),
            );
    }

    /**
     * Create price
     *
     * @param priceData
     */
    createPrice(priceData?: Price): Observable<Price> {
        const newPrice = {
            id    : FuseMockApiUtils.guid(),
            title : 'Neues Werkzeug',
            serial: null,
            notes : null,
        };
        return this.prices$.pipe(
            take(1),
            switchMap(prices => this.apollo
                .mutate({
                    mutation : Mutation.addPrice,
                    variables: {
                        data: priceData || newPrice,
                    },
                }).pipe(
                    map((result: any) => {
                        const price: Price = result.data.addPrice;
                        // Update the prices with the new price
                        this._prices.next([price, ...prices]);
                        // Return the new price
                        return price;
                    }),
                )),
        );
    }

    /**
     * Update price
     *
     * @param id
     * @param priceData
     */
    updatePrice(id: string, priceData: Price): Observable<Price> {
        return this.prices$
            .pipe(
                take(1),
                switchMap(prices => this.apollo
                    .mutate({
                        mutation : Mutation.updatePrice,
                        variables: {
                            id,
                            data: priceData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedPrice = priceData;
                            // Find the index of the updated price
                            const index = prices.findIndex(item => item.id === id);

                            // Update the price
                            const newPrices = _.cloneDeep(prices);
                            newPrices[index] = updatedPrice;

                            // Update the prices
                            this._prices.next(newPrices);

                            // Return the updated price
                            return updatedPrice;
                        }),
                        switchMap(updatedPrice => this.price$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {

                                // Update the price if it's selected
                                this._price.next(updatedPrice);

                                // Return the updated price
                                return updatedPrice;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete price
     * @param id
     */
    trashPrice(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updatePrice,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete price
     * @param id
     */
    untrashPrice(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updatePrice,
                variables     : {
                    id,
                    data: {
                        deleted: false,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete the price
     *
     * @param id
     */
    deletePrice(id: string): Observable<boolean> {
        return this.prices$.pipe(
            take(1),
            switchMap(prices => this.apollo
                .mutate({
                    mutation: Mutation.deletePrice,
                    variables: {
                        id
                    }
                }).pipe(
                map((result: any) => {

                    // Find the index of the deleted price
                    const index = prices.findIndex(item => item.id === id);

                    // Delete the price
                    prices.splice(index, 1);

                    // Update the prices
                    this._prices.next(prices);

                    // Return the deleted status
                    return true;
                }),
            )),
        );
    }

}
