import {gql} from 'apollo-angular';

import {DocumentNode} from 'graphql';

export const calendarFragments: {
    [key: string]: DocumentNode
} = {
    calendar: gql`
        fragment Calendar on Calendar{
            _id
            id
            title
            color
            visible
            users
        }
    `,
};
