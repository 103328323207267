import { gql } from 'apollo-angular';
import { buildingFragments } from './fragments';

export const addBuilding = gql`
    mutation addBuilding($data: BuildingInput!) {
        addBuilding(data: $data) {
            ...Building
        }
    }

    ${buildingFragments['building']}
`;

export const updateBuilding = gql`
    mutation updateBuilding ($id: ObjectID!, $data: BuildingInput!) {
        updateBuilding (id: $id, data: $data) {
            ...Building
        }
    }

    ${buildingFragments['building']}
`;



