import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Vehicle } from '../types';
import { Apollo } from 'apollo-angular';
import * as Query from '../_graphql/vehicle/queries';
import * as Mutation from '../_graphql/vehicle/mutations';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root',
})
export class VehiclesService {
    // Private
    private _vehicle: BehaviorSubject<Vehicle | null> = new BehaviorSubject(null);
    private _vehicles: BehaviorSubject<Vehicle[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private apollo: Apollo,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for vehicle
     */
    get vehicle$(): Observable<Vehicle> {
        return this._vehicle.asObservable();
    }

    /**
     * Getter for vehicles
     */
    get vehicles$(): Observable<Vehicle[]> {
        return this._vehicles.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get vehicles
     */
    allVehicles(param?: string, orderBy?: string, limit?: number): Observable<Vehicle[]> {
        return this.apollo.query({
            query    : Query.vehicles,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const vehicles = result.data.vehicles;
                    // Update the things
                    this._vehicles.next(vehicles);
                    // Return the things
                    return vehicles;
                }),
            );
    }

    /**
     * Search vehicles with given query
     *
     * @param query
     */
    getVehiclesByQuery(query: string): Observable<Vehicle[]> {
        return this.apollo.query({
            query    : Query.vehiclesByQuery,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const vehicles = result.data.vehiclesByQuery;
                    // Update the things
                    this._vehicles.next(vehicles);
                    // Return the things
                    return vehicles;
                }),
            );
    }

    /**
     * Search vehicles with given query
     *
     * @param params
     * @param query
     */
    vehiclesByParametersAndQuery(params: string[], query: string): Observable<Vehicle[]> {
        return this.apollo.query({
            query    : Query.vehiclesByParametersAndQuery,
            variables: {
                params,
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const vehicles = result.data.vehiclesByParametersAndQuery;
                    // Update the things
                    this._vehicles.next(vehicles);
                    // Return the things
                    return vehicles;
                }),
            );
    }

    /**
     * Get vehicle by id
     */
    getVehicleById(id: string): Observable<any> {
        return this._vehicles.pipe(
            take(1),
            map((vehicles) => {
                // Find the vehicle
                const vehicle = vehicles.find(item => item.id === id) || null;
                // Update the vehicle
                this._vehicle.next(vehicle);
                // Return the vehicle
                return vehicle;
            }),
            switchMap((vehicle) => {

                if (!vehicle) {
                    return throwError('Could not found vehicle with id of ' + id + '!');
                }

                return of(vehicle);
            }),
        );
    }

    /**
     * Create vehicle
     */
    createVehicle(): Observable<Vehicle> {
        const newVehicle = {
            id         : FuseMockApiUtils.guid(),
            kennzeichen: null,
            rufname    : 'Neues Fahrzeug',
            notes      : null,
        };
        return this.vehicles$.pipe(
            take(1),
            switchMap(vehicles => this.apollo
                .mutate({
                    mutation : Mutation.addVehicle,
                    variables: {
                        data: newVehicle,
                    },
                }).pipe(
                    map((result: any) => {
                        console.log('newVehicle: ', newVehicle);
                        const vehicle: Vehicle = result.data.addVehicle;
                        console.log('vehicle: ', vehicle);
                        // Update the vehicles with the new vehicle
                        this._vehicles.next([vehicle, ...vehicles]);
                        // Return the new vehicle
                        return vehicle;
                    }),
                )),
        );
    }

    /**
     * Update vehicle
     *
     * @param id
     * @param vehicleData
     */
    updateVehicle(id: string, vehicleData: Vehicle): Observable<Vehicle> {
        return this.vehicles$
            .pipe(
                take(1),
                switchMap(vehicles => this.apollo
                    .mutate({
                        mutation : Mutation.updateVehicle,
                        variables: {
                            // id: id,
                            id,
                            data: vehicleData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedVehicle = vehicleData;
                            // Find the index of the updated vehicle
                            const index = vehicles.findIndex(item => item._id === id);

                            // Update the vehicle
                            const newVehicles = _.cloneDeep(vehicles);
                            newVehicles[index] = updatedVehicle;

                            // Update the vehicles
                            this._vehicles.next(newVehicles);

                            // Return the updated vehicle
                            return updatedVehicle;
                        }),
                        switchMap(updatedVehicle => this.vehicle$.pipe(
                            take(1),
                            filter(item => item && item._id === id),
                            tap(() => {

                                // Update the vehicle if it's selected
                                this._vehicle.next(updatedVehicle);

                                // Return the updated vehicle
                                return updatedVehicle;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete the vehicle
     *
     * @param id
     */
    deleteVehicle(id: string): Observable<boolean> {
        return this.vehicles$.pipe(
            take(1),
            switchMap(vehicles => this._httpClient.delete('api/apps/vehicles/vehicle', { params: { id } }).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted vehicle
                    const index = vehicles.findIndex(item => item.id === id);

                    // Delete the vehicle
                    vehicles.splice(index, 1);

                    // Update the vehicles
                    this._vehicles.next(vehicles);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Delete vehicle
     * @param id
     */
    trashVehicle(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateVehicle,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                },
                refetchQueries: [{
                    query: Query.vehicles,
                }],
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete vehicle
     * @param id
     */
    _deleteVehicle(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.deleteVehicle,
                variables     : {
                    id,
                },
                refetchQueries: [{
                    query: Query.vehicles,
                }],
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }
}
