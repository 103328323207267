import { gql } from 'apollo-angular';
import { projectFragments } from './fragments';

export const addProject = gql`
    mutation addProject($data: ProjectInput!) {
        addProject(data: $data) {
            ...Project
        }
    }

    ${projectFragments['project']}
`;

export const deleteProject = gql`
    mutation deleteProject($id: ObjectID!) {
        deleteProject(id: $id) {
            ...Project
        }
    }

    ${projectFragments['project']}
`;

export const updateProject = gql`
    mutation updateProject ($id: ObjectID!, $data: ProjectInput!) {
        updateProject (id: $id, data: $data) {
            ...Project
        }
    }

    ${projectFragments['project']}
`;



