import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const pageFragments: {
    [ key: string ]: DocumentNode
} = {
    page: gql`
        fragment Page on Page{
            _id
            title
            subtitle
            translate
            type
            icon
            url
            uid
            path
            completepath
            navTitle
        }

    `,
};
