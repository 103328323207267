import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const buildingFragments: {
    [ key: string ]: DocumentNode
} = {
    building: gql`
        fragment Building on Building{
            _id
            id
            title
            serial
            notes
        }

    `,
};
