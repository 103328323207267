import { gql } from 'apollo-angular';
import { appFragments } from './fragments';

export const addApp = gql`
    mutation addApp($data: AppInput!) {
        addApp(data: $data) {
            ...App
        }
    }

    ${appFragments['app']}
`;

export const updateApp = gql`
    mutation updateApp ($id: ObjectID!, $data: AppInput!) {
        updateApp (data: $data) {
            ...App
        }
    }

    ${appFragments['app']}
`;



