import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import * as Query from '../_graphql/category/queries';
import * as Mutation from '../_graphql/category/mutations';
import { Device, Category } from '../types';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
    providedIn: 'root'
})
export class CategorysService {
    // Private
    private _category: BehaviorSubject<Category | null> = new BehaviorSubject(null);
    private _categorys: BehaviorSubject<Category[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private apollo: Apollo
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for category
     */
    get category$(): Observable<Category> {
        return this._category.asObservable();
    }
    /**
     * Getter for categorys
     */
    get categorys$(): Observable<Category[]> {
        return this._categorys.asObservable();
    }


    /**
     * Get category by id
     */
    getCategoryById(id: string): Observable<Category> {
        return this._categorys.pipe(
            take(1),
            map((categorys) => {
                // Find the category
                const category = categorys.find(item => item.id === id) || null;
                // Update the category
                this._category.next(category);
                // Return the category
                return category;
            }),
            switchMap((category) => {

                if (!category) {
                    return throwError('Could not found category with id of ' + id + '!');
                }

                return of(category);
            }),
        );
    }
    

    /**
     * ----------------------------------------------------
     * Get All Categorys
     * ----------------------------------------------------
     * @method getCategorys
     */
    getCategorys(param?, orderBy?, limit?): Observable<Category[]> {
        return this.apollo.query({
            query    : Query.categorys,
            variables: {
                param,
                orderBy,
                limit,
            },
        })
            .pipe(
                map((result: any) => {
                    const categorys = result.data.categorys;
                    // Update the things
                    this._categorys.next(categorys);
                    // Return the things
                    return categorys;
                }),
            );
    }

    /**
     * Search categorys with given query
     *
     * @param query
     */
    getCategorysByHandle(query: string): Observable<Category[]> {
        return this.apollo.query({
            query    : Query.categorysByHandle,
            variables: {
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const categorys = result.data.categorysByHandle;
                    // Update the things
                    this._categorys.next(categorys);
                    // Return the things
                    return categorys;
                }),
            );
    }

    /**
     * Search categorys with given query
     *
     * @param params
     * @param query
     */
    categorysByParametersAndQuery(params: string[], query: string): Observable<Category[]> {
        return this.apollo.query({
            query    : Query.categorysByParametersAndQuery,
            variables: {
                params,
                query,
            },
        })
            .pipe(
                map((result: any) => {
                    const categorys = result.data.categorysByParametersAndQuery;
                    // Update the things
                    this._categorys.next(categorys);
                    // Return the things
                    return categorys;
                }),
            );
    }

    /**
     * Create category
     *
     * @param categoryData
     */
    createCategory(categoryData?: Category): Observable<Category> {
        const newCategory = {
            id    : FuseMockApiUtils.guid(),
            title : 'Neues Werkzeug',
            serial: null,
            notes : null,
        };
        return this.categorys$.pipe(
            take(1),
            switchMap(categorys => this.apollo
                .mutate({
                    mutation : Mutation.addCategory,
                    variables: {
                        data: categoryData || newCategory,
                    },
                }).pipe(
                    map((result: any) => {
                        const category: Category = result.data.addCategory;
                        // Update the categorys with the new category
                        this._categorys.next([category, ...categorys]);
                        // Return the new category
                        return category;
                    }),
                )),
        );
    }

    /**
     * Update category
     *
     * @param id
     * @param categoryData
     */
    updateCategory(id: string, categoryData: Category): Observable<Category> {
        return this.categorys$
            .pipe(
                take(1),
                switchMap(categorys => this.apollo
                    .mutate({
                        mutation : Mutation.updateCategory,
                        variables: {
                            id,
                            data: categoryData,
                        },
                    }).pipe(
                        map((result: any) => {

                            const updatedCategory = categoryData;
                            // Find the index of the updated category
                            const index = categorys.findIndex(item => item.id === id);

                            // Update the category
                            const newCategorys = _.cloneDeep(categorys);
                            newCategorys[index] = updatedCategory;

                            // Update the categorys
                            this._categorys.next(newCategorys);

                            // Return the updated category
                            return updatedCategory;
                        }),
                        switchMap(updatedCategory => this.category$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {

                                // Update the category if it's selected
                                this._category.next(updatedCategory);

                                // Return the updated category
                                return updatedCategory;
                            }),
                        )),
                    )),
            );
    }

    /**
     * Delete category
     * @param id
     */
    trashCategory(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateCategory,
                variables     : {
                    id,
                    data: {
                        deleted: true,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete category
     * @param id
     */
    untrashCategory(id: string) {
        this.apollo
            .mutate({
                mutation      : Mutation.updateCategory,
                variables     : {
                    id,
                    data: {
                        deleted: false,
                    },
                }
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete the category
     *
     * @param id
     */
    deleteCategory(id: string): Observable<boolean> {
        return this.categorys$.pipe(
            take(1),
            switchMap(categorys => this.apollo
                .mutate({
                    mutation: Mutation.deleteCategory,
                    variables: {
                        id
                    }
                }).pipe(
                map((result: any) => {

                    // Find the index of the deleted category
                    const index = categorys.findIndex(item => item.id === id);

                    // Delete the category
                    categorys.splice(index, 1);

                    // Update the categorys
                    this._categorys.next(categorys);

                    // Return the deleted status
                    return true;
                }),
            )),
        );
    }

}
