import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, Subscription, throwError } from "rxjs";
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import * as Query from '../_graphql/page/queries';
import * as Mutation from '../_graphql/page/mutations';
import { Page } from '../types';
import * as _ from 'lodash';
import { FuseMockApiUtils } from '../mock-api';

@Injectable({
	providedIn: 'root',
})
export class PagesService {
	// Private
	private _page: BehaviorSubject<Page | null> = new BehaviorSubject(null);
	private _pages: BehaviorSubject<Page[] | null> = new BehaviorSubject(null);
	private pageSubsriction: Subscription;

	/**
	 * Constructor
	 */
	constructor(
		private apollo: Apollo,
	) {
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for page
	 */
	get page$(): Observable<Page> {
		return this._page.asObservable();
	}

	/**
	 * Getter for pages
	 */
	get pages$(): Observable<Page[]> {
		return this._pages.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------


	/**
	 * Get All Pages
	 * @method getPages
	 */
	getPages(param?, orderBy?, limit?): Observable<Page[]> {
		return this.apollo.query({
			query    : Query.pages,
			variables: {
				param,
				orderBy,
				limit,
			},
		})
			.pipe(
				map((result: any) => {
					// console.log('Result: ', result)
					const pages = result.data.pages;
					console.log('Seiten: ', pages)
					// Update the things
					this._pages.next(pages);
					// Return the things
					return pages;
				}),
			);
	}


	/**
	 * Get page by id
	 */
	getPageById(id: string): Observable<Page> {
		return this._pages.pipe(
			take(1),
			map((pages) => {
				// Find the page
				const page = pages.find(item => item.id === id) || null;
				// Update the page
				this._page.next(page);
				// Return the page
				return page;
			}),
			switchMap((page) => {

				if (!page) {
					return throwError('Could not found page with id of ' + id + '!');
				}

				return of(page);
			}),
		);
	}


	/**
	 * ----------------------------------------------------
	 * Get One Page by Navtitle
	 * ----------------------------------------------------
	 * @method getPages
	 */
	getPageByNavtitle(navTitle: string): Observable<Page> {

		console.log('get Page navTitle: ', navTitle)
		return this._pages.pipe(
			take(1),
			map((pages: Page[]) => {
				console.log('getPageByNavtitle pages: ', pages)
				// Find the page
				const page: Page = pages.find(item => item.navTitle === navTitle) || null;
				// Update the page
				this._page.next(page);
				console.log('getPageByNavtitle page: ', page)
				// Return the page
				return page;
			}),
			switchMap((page:Page) => {

				if (!page) {
					return throwError('Could not found page with navTitle of ' + navTitle + '!');
				}

				return of(page);
			}),
		);
	}


	/**
	 * ----------------------------------------------------
	 * Get One Page by Navtitle
	 * ----------------------------------------------------
	 * @method getPages
	 */
	oldGetPageByNavtitle(navTitle?): Observable<Page> {
		console.log('get Page navTitle: ', navTitle)
		return this.apollo.query({
			query    : Query.pageByNavTitle,
			variables: {
				navTitle
			},
		})
			.pipe(
				map((result: any) => {
					// console.log('result:', result)
					const page = result.data.pageByNavTitle;

					// Update the things
					this._page.next(page);
					console.log('oldGetPageByNavtitle', page)
					// Return the things
					return page;
				}),
				switchMap((page) => {

					if (!page) {
						return throwError('Could not found page with thar NavTitle!');
					}

					return of(page);
				}),
			);
	}

	/**
	 * Search pages with given query
	 *
	 * @param query
	 */

	/*
	getPagesByHandle(query: string): Observable<Page[]> {
		return this.apollo.query({
			query    : Query.pagesByHandle,
			variables: {
				query,
			},
		})
			.pipe(
				map((result: any) => {
					const pages = result.data.pagesByHandle;
					// Update the things
					this._pages.next(pages);
					// Return the things
					return pages;
				}),
			);
	}
	*/

	/**
	 * Search pages with given query
	 *
	 * @param params
	 * @param query
	 */
	pagesByParametersAndQuery(params: string[], query: string): Observable<Page[]> {
		return this.apollo.query({
			query    : Query.pagesByParametersAndQuery,
			variables: {
				params,
				query,
			},
		})
			.pipe(
				map((result: any) => {
					const pages = result.data.pagesByParametersAndQuery;
					// Update the things
					this._pages.next(pages);
					// Return the things
					return pages;
				}),
			);
	}

	/**
	 * Create page
	 *
	 * @param pageData
	 */
	createPage(pageData?: Page): Observable<Page> {
		const newPage = {
			id    : FuseMockApiUtils.guid(),
			title : 'Neues Werkzeug',
			serial: null,
			notes : null,
		};
		return this.pages$.pipe(
			take(1),
			switchMap(pages => this.apollo
				.mutate({
					mutation : Mutation.addPage,
					variables: {
						data: pageData || newPage,
					},
				}).pipe(
					map((result: any) => {
						const page: Page = result.data.addPage;
						// Update the pages with the new page
						this._pages.next([page, ...pages]);
						// Return the new page
						return page;
					}),
				)),
		);
	}

	/**
	 * Update page
	 *
	 * @param id
	 * @param pageData
	 */
	updatePage(id: string, pageData: Page): Observable<Page> {
		return this.pages$
			.pipe(
				take(1),
				switchMap(pages => this.apollo
					.mutate({
						mutation : Mutation.updatePage,
						variables: {
							id,
							data: pageData,
						},
					}).pipe(
						map((result: any) => {

							const updatedPage = pageData;
							// Find the index of the updated page
							const index = pages.findIndex(item => item.id === id);

							// Update the page
							const newPages = _.cloneDeep(pages);
							newPages[index] = updatedPage;

							// Update the pages
							this._pages.next(newPages);

							// Return the updated page
							return updatedPage;
						}),
						switchMap(updatedPage => this.page$.pipe(
							take(1),
							filter(item => item && item.id === id),
							tap(() => {

								// Update the page if it's selected
								this._page.next(updatedPage);

								// Return the updated page
								return updatedPage;
							}),
						)),
					)),
			);
	}

	/**
	 * Delete page
	 * @param id
	 */
	trashPage(id: string) {
		this.apollo
			.mutate({
				mutation : Mutation.updatePage,
				variables: {
					id,
					data: {
						deleted: true,
					},
				},
			})
			.subscribe(({ data }) => {
				// console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}

	/**
	 * Delete page
	 * @param id
	 */
	untrashPage(id: string) {
		this.apollo
			.mutate({
				mutation : Mutation.updatePage,
				variables: {
					id,
					data: {
						deleted: false,
					},
				},
			})
			.subscribe(({ data }) => {
				// console.log(data);
			}, (error) => {
				console.log('there was an error sending the delete query ', error);
			});
	}

	/**
	 * Delete the page
	 *
	 * @param id
	 */
	deletePage(id: string): Observable<boolean> {
		return this.pages$.pipe(
			take(1),
			switchMap(pages => this.apollo
				.mutate({
					mutation : Mutation.deletePage,
					variables: {
						id,
					},
				}).pipe(
					map((result: any) => {

						// Find the index of the deleted page
						const index = pages.findIndex(item => item.id === id);

						// Delete the page
						pages.splice(index, 1);

						// Update the pages
						this._pages.next(pages);

						// Return the deleted status
						return true;
					}),
				)),
		);
	}

}
