import { gql } from 'apollo-angular';
import { articleFragments } from './fragments';

export const addArticle = gql`
    mutation addArticle($data: ArticleInput!) {
        addArticle(data: $data) {
            ...Article
        }
    }
    ${articleFragments['article']}
`;

export const deleteArticle = gql`
    mutation deleteArticle($id: ObjectID!) {
        deleteArticle(id: $id) {
            ...Article
        }
    }
    ${articleFragments['article']}
`;

export const updateArticle = gql`
    mutation updateArticle($id: ObjectID!, $data: ArticleInput!) {
        updateArticle(id: $id, data: $data) {
            ...Article
        }
    }
    ${articleFragments['article']}
`;
