import { gql } from 'apollo-angular';
import { teaserFragments } from './fragments';

export const addTeaser = gql`
    mutation addTeaser($data: TeaserInput!) {
        addTeaser(data: $data) {
            ...Teaser
        }
    }

    ${teaserFragments['teaser']}
`;

export const updateTeaser = gql`
    mutation updateTeaser($id: ObjectID!, $data: TeaserInput!) {
        updateTeaser(id: $id, data: $data) {
            ...Teaser
        }
    }

    ${teaserFragments['teaser']}
`;

export const deleteTeaser = gql`
    mutation deleteTeaser($id: ObjectID!) {
        deleteTeaser(id: $id) {
            ...Teaser
        }
    }

    ${teaserFragments['teaser']}
`;
