import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class EmailService {
  jsonMessage: any;
  messageService: any;

  constructor(
    private _httpClient: HttpClient
  ) {
  }

  public send(message, service) {
    this.messageService = { "service": service };
    console.log("this.messageService: ", this.messageService);
    this.jsonMessage = JSON.stringify(message);
    console.log("this.jsonMessage: ", this.jsonMessage);
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    // Call the mutation called deleteMedia
    // return this.http.post('/send-email/' + service, JSON.stringify(message), {
    if (service === "bewerbung") {
      return this._httpClient.post("/send-bewerbung", JSON.stringify(message), {
        "headers": headers
      }).pipe(map((response) => response.toString()));
    } else {
      return this._httpClient.post("/send-email", JSON.stringify(message), {
        "headers": headers
      }).pipe(map((response) => response.toString()));
    }
  }
}
