<!-- counter Start -->
<section class="section bg-light">
	<div class="container"></div>
</section>

<section class="section">
	<div class="container">
		<div class="row">
			<ul class="col container-filter list-unstyled categories-filter text-center mb-0" id="filter">
				<li class="list-inline-item"><a class="categories border d-block text-dark rounded active"
												(click)="activeCategory('all')"
												[ngClass]="{'active': galleryFilter ==='all'}" data-filter="*">Alle</a>
				</li>
				<li class="list-inline-item" *ngFor="let item of categoryList">
					<a class="categories border d-block text-dark rounded"
												(click)="activeCategory(item.type)"
												[ngClass]="{'active': galleryFilter === item.type}"
												>{{item.title}}</a>
				</li>
			</ul>
		</div>
		<!--end row-->

		<div class="row projects-wrapper">
			<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 business" *ngFor="let item of filteredImages">
				<div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
					<img src="{{item.image}}" class="img-fluid work-image" alt="">
					<!--div class="card-body">
						<div class="content">
							<a href="javascript:void(0)" class="badge badge-primary">{{item.type}}</a>
							<h5 class="mt-3"><a routerLink="/page-case-detail" class="text-dark title">200% Extended
								Facebook
								Reach</a></h5>
							<p class="text-muted">This is required when, for example, the final text is not yet
								available.</p>
							<a href="javascript:void(0)" class="text-primary h6">Read More
								<i-feather name="arrow-right" class="fea icon-sm"></i-feather>
							</a>
						</div>
					</div -->
				</div>
			</div>
			<!--end col-->

		</div>
		<!--end row-->
	</div>
	<!--end container-->
</section>
