import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-agrarservice',
    templateUrl: './agrarservice.component.html',
    styleUrls: ['./agrarservice.component.scss'],
    standalone: false
})
export class AgrarserviceComponent implements OnInit {
    navClass = 'bg-white';
    filteredImages;
    galleryFilter = 'all';

    teamMembers = [
        {
            image: 'assets/_images/client/01.jpg',
            name: 'Andreas Streit',
            description: 'Inhaber'
        },
        {
            image: 'assets/_images/client/02.jpg',
            name: 'Mitarbeiter',
            description: 'Gute Seele 1'
        },
        {
            image: 'assets/_images/client/03.jpg',
            name: 'Mitarbeiterin',
            description: 'Gute Seele 2'
        },
        {
            image: 'assets/images/hund.jpg',
            name: 'Bello',
            description: 'Hofhund'
        },
    ];

    categoryList = [
        {
            title: 'Ackerbau',
            type: 'ackerbau'
        },
        {
            title: 'Futterernte',
            type: 'futterernte'
        },
        {
            title: 'Getreideernte',
            type: 'getreideernte'
        },
        {
            title: 'Kommunaltechnik',
            type: 'kommunaltechnik'
        }
    ];

    imageList = [
        {
            image: 'assets/images/agrarservice/ackerbau/mulchen.jpg',
            title: 'Mulchen',
            description: 'Mit unserer Front/Heck-Mulchkombination verfügen wir über eine schlagkräftige Arbeitsbreite von 5,5m. Durch den Frontanbau wird die Überfahrt der Pflanzen durch den Schlepper vermieden. Der besonders große Mulchrotor sorgt darüber hinaus für beste Zerkleinerung der Pflanzenbestandteile.',
            variant: 'success',
            type: 'ackerbau',
        },
        {
            image: 'assets/images/agrarservice/ackerbau/duengerstreuen.jpg',
            title: 'Düngerstreuen',
            description: 'Die Ausbringung von Kunstdünger wird ebenfalls angeboten.',
            variant: 'warning',
            type: 'ackerbau',
        },
        {
            image: 'assets/images/agrarservice/ackerbau/guelleausbringung.jpg',
            title: 'Gülleausbringung',
            description: 'Für die Gülleausbringung verfügen wir über zwei Pumptankwagen mit 16m3 und 18,5m3 Fassungsvermegen',
            variant: 'primary',
            type: 'ackerbau',
        },
        {
            image: 'assets/images/agrarservice/ackerbau/pfluegen.jpg',
            title: 'Pflügen',
            description: 'Mit dem sechsschar Variopflug erzielen wir auch bei anspruchsvollen Bodenbeschaffenheiten ein hervorragendes Pflugbild.',
            variant: 'info',
            type: 'ackerbau',
        },
        {
            image: 'assets/images/agrarservice/ackerbau/grubbern.jpg',
            title: 'Grubbern',
            description: 'Horsch 4m Grubber',
            variant: 'success',
            type: 'ackerbau',
        },
        {
            image: 'assets/images/agrarservice/ackerbau/kreiselegge.jpg',
            title: 'Kreiselegge',
            description: 'Unsere Kreiselegge ebnet Ihnen den Weg für weitere Arbeitsschritte. Mit einer Arbeitsbreite von 6m erzielen wir ein optimales Saatbeet.',
            variant: 'warning',
            type: 'ackerbau',
        },
        {
            image: 'assets/images/agrarservice/ackerbau/saatbeetkombination.jpg',
            title: 'Saatbeetkombination',
            description: 'Für sandige Böden setzten wir bevorzugt eine 6m Saatbeetkombination ein.',
            variant: 'primary',
            type: 'ackerbau',
        },
        {
            image: 'assets/images/agrarservice/ackerbau/maisaussaat.jpg',
            title: 'Maisaussaat/Engsaat',
            description: 'Drei, 6m breite, präzise Maissägeräte legen Ihr Saatgut exakt an die gewünschte Stelle im Boden. Neben der Tiefenführung und der Einzelkornablage kann auch die Fahrgasse beliebig variiert werden. Der Reihenabstand beträgt dabei 50 oder 75cm.',
            variant: 'success',
            type: 'ackerbau',
        },
        {
            image: 'assets/images/agrarservice/ackerbau/engsaat.jpg',
            title: 'Maisaussaat/Engsaat',
            description: 'Drei, 6m breite, präzise Maissägeräte legen Ihr Saatgut exakt an die gewünschte Stelle im Boden. Neben der Tiefenführung und der Einzelkornablage kann auch die Fahrgasse beliebig variiert werden. Der Reihenabstand beträgt dabei 50 oder 75cm.',
            variant: 'success',
            type: 'ackerbau',
        },


        {
            image: 'assets/images/agrarservice/futterernte/maehen.jpg',
            title: 'Mähen',
            description: 'Ein Butterfly Mähwerk mit einer Arbeitsbreite von 8,5m sowie einer Schwadablagemöglichkeit steht ebenfalls zur Verfügung.',
            variant: 'info',
            type: 'futterernte',
        },
        {
            image: 'assets/images/agrarservice/futterernte/haeckseln.jpg',
            title: 'Häckseln',
            description: 'Die fünf Feldhäcksler können aufgrund des weiten Leistungsspektrums von 450 bis 900 PS auf allen Betriebsgrößen flexibel und äußerst wirtschaftlich eingesetzt werden. Mit den vielseitig einsetzbaren Vorsatzgeräten (PU, GPS, Maispflücker, Maisgebiss) ernten wir auf Ihren Feldern die unterschiedlichsten Kulturen.',
            variant: 'info',
            type: 'futterernte',
        },
        {
            image: 'assets/images/agrarservice/futterernte/silagetransport.jpg',
            title: 'Silagetransport',
            description: 'Mit unseren sechs Häckseltransportgespannen ü 42m3 stellen wir komplette Transportketten.',
            variant: 'info',
            type: 'futterernte',
        },
        {
            image: 'assets/images/agrarservice/futterernte/silageverdichtung.jpg',
            title: 'Silageverdichtung',
            description: 'Mit unseren zwei 15 Tonnen schweren Walzschleppern verdichten wir Ihre Silage.',
            variant: 'info',
            type: 'futterernte',
        },
        {
            image: 'assets/images/agrarservice/futterernte/kurzschnittladewagen.jpg',
            title: 'Kurzschnittladewagen',
            description: 'Besonders für kleinflächige Strukturen zeigt sich der Kurzschnittladewagen als hervorragende Alternative zum Feldhäcksler. Unsere zwei Kurzschnittladewagen verfügen über jeweils 44 Messer und ein DIN Ladevolumen von 42m3. Bei mittlerer Pressung transportieren wir über 80m3.',
            variant: 'info',
            type: 'futterernte',
        },
        {
            image: 'assets/images/agrarservice/futterernte/grosspackenpresse.jpg',
            title: 'Grosspackenpresse',
            description: '23 Messer garantieren beste Schnittqualität. Das Ballenmaß 120x70cm punktet weiterhin als bewährtes Transportmaß.',
            variant: 'info',
            type: 'futterernte',
        },


        {
            image: 'assets/images/agrarservice/getreideernte/maehdrusch.jpg',
            title: 'Mähdrusch',
            description: 'Mit vier Mähdreschern unterschiedlicher Arbeitsbreiten - mit oder ohne Hangausgleich - sind wir am Hang genauso leistungsfähig wie in der Ebene.',
            variant: 'info',
            type: 'getreideernte',
        },
        {
            image: 'assets/images/agrarservice/getreideernte/maisdrusch.jpg',
            title: 'Maisdrusch',
            description: 'Unsere acht-reihigen Maispflüger sind mit einem Unterflurhäcksler ausgestattet und garantieren eine optimale Zerkleinerung der Pflanzenrückstände.',
            variant: 'info',
            type: 'getreideernte',
        },


        {
            image: 'assets/images/agrarservice/kommunaltechnik/wegebau.jpg',
            title: 'Häckseln',
            description: 'Diese Maschine eignet sich für vielseitige effiziente Wegebaumaßnahmen. Wegesanierung. Wegepflege. Wegeinstandhaltung',
            variant: 'info',
            type: 'kommunaltechnik',
        },
        {
            image: 'assets/images/agrarservice/kommunaltechnik/astsaege.jpg',
            title: 'Mähen',
            description: 'Durch den Einsatz der Astsäge können Waldränder mit einer Astdicke von bis zu 20cm problemlos zurückgeschnitten werden.',
            variant: 'info',
            type: 'kommunaltechnik',
        },
    ];

    constructor(
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.filteredImages = this.imageList;
    }

    openModal(content) {
        this.modalService.open(content, { centered: true });
    }

    activeCategory(category) {
        this.galleryFilter = category;
        if (this.galleryFilter === 'all') {
            this.filteredImages = this.imageList;
        } else {
            this.filteredImages = this.imageList.filter(x => x.type === this.galleryFilter);
        }
    }

}
