import {gql} from 'apollo-angular';

import { projectFragments } from './fragments';

export const projects = gql`
    query projects($param: StringOrInt, $orderBy: String, $limit: Int){
        projects(param: $param, orderBy: $orderBy, limit: $limit){
            ...Project
        }
    }

    ${projectFragments['project']}
`;

export const projectsByTag = gql`
    query projectsByTag($tag: String!, $orderBy: String, $limit: Int){
        projectsByTag(tag: $tag, orderBy: $orderBy, limit: $limit){
            ...Project
        }
    }

    ${projectFragments['project']}
`;

export const projectsByQuery = gql`
    query projectsByQuery($query: StringOrInt!, $orderBy: String, $limit: Int){
        projectsByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...Project
        }
    }

    ${projectFragments['project']}
`;

export const projectsByParam = gql`
    query projectsByParam($param: StringOrInt!, $orderBy: String, $limit: Int){
        projectsByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Project
        }
    }

    ${projectFragments['project']}
`;

export const projectsByParamAndValue = gql`
    query projectsByParamAndValue($param: String!, $value: StringOrInt!, $orderBy: String, $limit: Int){
        projectsByParamAndValue(param: $param, value: $value, orderBy: $orderBy, limit: $limit){
            ...Task
        }
    }
    ${projectFragments['project']}
`;


export const projectsByParametersAndQuery = gql`
    query projectsByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        projectsByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Task
        }
    }
    ${projectFragments['project']}
`;

export const project = gql`
    query project($id: ObjectID!) {
        project(id: $id) {
            ...Project
        }
    }

    ${projectFragments['project']}
`;
