import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Query from '../_graphql/thing/queries';
import * as Mutation from '../_graphql/thing/mutations';

import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Thing } from '../types';
import { FuseMockApiUtils } from '../mock-api';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ThingsService {
    private _thing: BehaviorSubject<Thing | null> = new BehaviorSubject(null);
    private _things: BehaviorSubject<Thing[] | null> = new BehaviorSubject(null);

    constructor(
        private apollo: Apollo
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for task
     */
    get thing$(): Observable<Thing> {
        return this._thing.asObservable();
    }

    /**
     * Getter for tasks
     */
    get things$(): Observable<Thing[]> {
        return this._things.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get tasks
     */

    getThings(param?, orderBy?, limit?) {
        return this.apollo.query({
            query: Query.things,
            variables: {
                param,
                orderBy,
                limit
            }
        })
            .pipe(
                map((result: any) => {
                    const things = result.data.things;
                    // Update the things
                    this._things.next(things);
                    // Return the things
                    return things;
                })
            );
    }
    
    /**
     * ----------------------------------------------------
     * Get One Thing
     * ----------------------------------------------------
     * @method getThing
     */
    getThing(id) {
        return  this.apollo
            .query({
                query: Query.thing,
                variables: {
                    id
                }
            })
            .pipe(
                map((result: any) => {
                    const thing = result.data.thing;
                    // Update the things
                    this._thing.next(thing);
                    // Return the things
                    return thing;
                })
            );
    }
    
    /**
     * Get thing by id
     */
    getThingById(id: string): Observable<Thing> {
        return this._things.pipe(
            take(1),
            map((things) => {

                // Find the thing
                const thing = things.find(item => item.id === id) || null;

                // Update the thing
                this._thing.next(thing);

                // Return the thing
                return thing;
            }),
            switchMap((thing) => {

                if (!thing) {
                    return throwError("Could not found thing with id of " + id + "!");
                }

                return of(thing);
            })
        );
    }



    /**
     * Create thing
     *
     * @param type
     */
    createThing(type: string): Observable<Thing> {
        const newThing = {
            id          : FuseMockApiUtils.guid(),
            type        : type,
            title       : '',
            notes       : null,
            completed   : false,
            dueDate     : null,
            priority    : 1,
            tags        : [],
            order       : 0
        };
        return this.things$.pipe(
            take(1),
            switchMap(things => this.apollo
                .mutate({
                    mutation: Mutation.addThing,
                    variables: {
                        data: newThing
                    }
                }).pipe(
                    map((result: any) => {
                        const newThing = result.data.addThing
                        console.log("newThing: ", newThing);
                        // Update the things with the new thing
                        this._things.next([newThing, ...things]);

                        // Return the new thing
                        return newThing;
                    })
                ))
        );
    }

    /**
     * Update thing
     *
     * @param id
     * @param thingData
     */
    updateThing(id: string, thingData: Thing): Observable<Thing> {
        return this.things$
            .pipe(
                take(1),
                switchMap(things => this.apollo
                    .mutate({
                        mutation: Mutation.updateThing,
                        variables: {
                            // id: id,
                            id: thingData._id,
                            data: thingData
                        }
                    }).pipe(
                        map((result: any) => {

                            const updatedThing = thingData;
                            // Find the index of the updated thing
                            const index = things.findIndex(item => item.id === id);

                            // Update the thing
                            const newThings = _.cloneDeep(things)
                            newThings[index] = updatedThing;

                            // Update the things
                            this._things.next(newThings);

                            // Return the updated thing
                            return updatedThing;
                        }),
                        switchMap(updatedThing => this.thing$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {

                                // Update the thing if it's selected
                                this._thing.next(updatedThing);

                                // Return the updated thing
                                return updatedThing;
                            })
                        ))
                    ))
            );
    }
    
    /**
     * Trash thing
     * @param id
     */
    trashThing(id: string) {
        this.apollo
            .mutate({
                mutation : Mutation.updateThing,
                variables: {
                    id,
                    data: {
                        deleted: true,
                    },
                },
            })
            .subscribe(({ data }) => {
                console.log(data);
            }, (error) => {
                console.log('there was an error sending the delete query ', error);
            });
    }

    /**
     * Delete the thing
     *
     * @param _id
     * @param id
     */
    deleteThing(_id:string, id: string): Observable<boolean> {
        return this.things$.pipe(
            take(1),
            switchMap(things => this.apollo
                .mutate({
                    mutation: Mutation.deleteThing,
                    variables: {
                        id: _id
                    }
                })
                .pipe(
                    map((result: any) => {
                        if (!result) {
                            return false
                        } else {
                            const deletedThing = result.data.addThing
                            // Find the index of the deleted thing
                            const index = things.findIndex(item => item.id === id);

                            // Delete the thing
                            const newThings = _.cloneDeep(things)
                            newThings.splice(index, 1);

                            // Update the things
                            this._things.next(newThings);

                            // Return the deleted status
                            return true;
                        }

                    })
                ))
        );
    }

}
