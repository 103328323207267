import {gql} from 'apollo-angular';
import { housingmodelFragments } from './fragments';
import { housingFragments } from '../housing/fragments';

export const housingmodel = gql`
    query housingmodel($id: ObjectID!) {
        housingmodel(id: $id) {
            ...Housingmodel

            housings{
                ...Housing
            }
        }
    }

    ${housingmodelFragments['housingmodel']}
    ${housingFragments['housing']}
`;

export const housingmodels = gql`
    query housingmodels{
        housingmodels{
            ...Housingmodel

            housings{
                ...Housing
            }
        }
    }

    ${housingmodelFragments['housingmodel']}
    ${housingFragments['housing']}
`;


export const housingmodelsByHandle = gql`
    query housingmodelsByHandle($handle: String!, $orderBy: String, $limit: Int){
        housingmodelsByHandle(handle: $handle, orderBy: $orderBy, limit: $limit){
            ...Housingmodel

            housings{
                ...Housing
            }
        }
    }

    ${housingmodelFragments['housingmodel']}
`;

export const housingmodelsByParam = gql`
    query housingmodelsByParam($param: String!, $orderBy: String, $limit: Int){
        housingmodelsByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Housingmodel

            housings{
                ...Housing
            }
        }
    }

    ${housingmodelFragments['housingmodel']}
`;

export const housingmodelsByQuery = gql`
    query housingmodelsByQuery($query: String!, $orderBy: String, $limit: Int){
        housingmodelsByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...Housingmodel

            housings{
                ...Housing
            }
        }
    }

    ${housingmodelFragments['housingmodel']}
`;


export const housingmodelsByParametersAndQuery = gql`
    query housingmodelsByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        housingmodelsByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Housingmodel

            housings{
                ...Housing
            }
        }
    }

    ${housingmodelFragments['housingmodel']}
`;
