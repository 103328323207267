import {gql} from 'apollo-angular';

import { DocumentNode } from 'graphql';

export const projectgroupFragments: {
    [ key: string ]: DocumentNode
} = {
    projectgroup: gql`
        fragment Projectgroup on Projectgroup{
            _id
            id
            title
            projects
            deleted
            owner
            createdBy
        }

    `,
};
