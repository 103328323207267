import { gql } from 'apollo-angular';
import { contentFragments } from './fragments';

export const addContent = gql`
    mutation addContent($data: ContentInput!) {
        addContent(data: $data) {
            ...Content
        }
    }
    ${contentFragments['content']}
`;

export const updateContent = gql`
    mutation updateContent($id: ObjectID!, $data: ContentInput!) {
        updateContent(id: $id, data: $data) {
            ...Content
        }
    }
${contentFragments['content']}
`;

export const deleteContent = gql`
    mutation deleteContent($id: ObjectID!) {
        deleteContent(id: $id) {
            ...Content
        }
    }
${contentFragments['content']}
`;

export const addFileToContentFilelist = gql`
    mutation addFileToContentFilelist($id: ObjectID!, $data: FileInput!) {
        addFileToContentFilelist(id: $id, data: $data) {
            ...Content
        }
    }
${contentFragments['content']}
`;

export const removeFileFromContentFilelist = gql`
    mutation removeFileFromContentFilelist($id: ObjectID!, $fileId: ObjectID!) {
        removeFileFromContentFilelist(id: $id, fileId: $fileId) {
            ...Content
        }
    }
${contentFragments['content']}
`;
