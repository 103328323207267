import { gql } from 'apollo-angular';
import { contactFragments } from './fragments';

export const contact = gql`
    query contact($id: ObjectID!) {
        contact(id: $id) {
            ...Contact
        }
    }
    ${contactFragments['contact']}
`;

export const contacts = gql`
    query contacts($orderBy: String, $limit: Int){
        contacts(orderBy: $orderBy, limit: $limit){
            ...Contact
        }
    }
    ${contactFragments['contact']}
`;

export const contactsByQuery = gql`
    query contactsByQuery($query: StringOrInt){
        contactsByQuery(query: $query){
            ...Contact
        }
    }
    ${contactFragments['contact']}
`;

export const contactsByParametersAndQuery = gql`
    query contactsByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        contactsByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Contact
        }
    }
    ${contactFragments['contact']}
`;

export const contactsAll = gql`
    query contactsAll{
        contactsAll{
            ...Contact
        }
    }
    ${contactFragments['contact']}
`;
