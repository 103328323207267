<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/" class="btn btn-icon btn-soft-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>
<!-- Hero Start -->
<section class="bg-home d-flex align-items-center">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="mr-lg-5">
          <img src="../../../assets/images/user/login.svg" class="img-fluid d-block mx-auto" alt="">
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="card login-page bg-white shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">Login</h4>
            <form class="login-form mt-4" name="signInForm" [formGroup]="signInForm" (ngSubmit)="signIn()" novalidate>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <!-- label>Your Email <span class="text-danger">*</span></label>
                    <i-feather name="user" class="fea icon-sm icons"></i-feather -->
                    <input type="email" class="form-control" placeholder="Email" name="email" required=""
                           formControlName="email">
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <!-- label>Password <span class="text-danger">*</span></label>
                    <i-feather name="key" class="fea icon-sm icons"></i-feather -->
                    <input type="password" class="form-control" placeholder="Password" required=""
                           formControlName="password">
                  </div>
                </div>

                <!-- div class="col-lg-12">
                  <div class="d-flex justify-content-between">
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                        <label class="custom-control-label" for="customCheck1">Remember me</label>
                      </div>
                    </div>
                    <p class="forgot-pass mb-0"><a routerLink="/auth-re-password"
                        class="text-dark font-weight-bold">Forgot
                        password ?</a></p>
                  </div>
                </div -->
                <div class="col-lg-12 mb-0">
                  <button class="btn btn-primary btn-block">Sign in</button>
                </div>
                <!-- div class="col-lg-12 mt-4 text-center">
                  <h6>Or Login With</h6>
                  <ul class="list-unstyled social-icon mb-0 mt-3">
                    <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                  </ul>
                </div -->
                <!-- div class="col-12 text-center">
                  <p class="mb-0 mt-3"><small class="text-dark mr-2">Don't have an account ?</small> <a
                      routerLink="/auth-signup" class="text-dark font-weight-bold">Sign Up</a></p>
                </div -->
              </div>
            </form>
          </div>
        </div>
        <!---->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
