import { Component, OnInit } from "@angular/core";
import { AuthService } from "@services";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-auth-signup",
    templateUrl: "./auth-signup.component.html",
    styleUrls: ["./auth-signup.component.css"],
    standalone: false
})
export class AuthSignupComponent implements OnInit {

  signUpForm: FormGroup;

  // Private
  private _unsubscribeAll: Subject<any>;

  showAlert: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _router: Router
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // Create the form
    this.signUpForm = this._formBuilder.group({
      firstName      : ["Stefan", Validators.required],
      lastName       : ["Schwanz", Validators.required],
      email          : ["stefan@shoch2.net", [Validators.required, Validators.email]],
      password       : ["14170815", Validators.required],
      passwordConfirm: ["14170815", [Validators.required, confirmPasswordValidator]]
      // agreements: ['', Validators.requiredTrue]
    });

    // Update the validity of the 'passwordConfirm' field
    // when the 'password' field changes
    this.signUpForm.get("password").valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.signUpForm.get("passwordConfirm").updateValueAndValidity();
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign up
   */
  signUp(): void {
    let userData;
    console.warn(this.signUpForm.value);
    // Do nothing if the form is invalid
    if (this.signUpForm.invalid) {
      return;
    } else {
      // if valid signup
      // console.log('this.signUpForm.value 1: ', this.signUpForm.value)
      userData = {
        email: this.signUpForm.value.email,
        password: this.signUpForm.value.password
      }
      // console.log('userData: ', userData)
      this._authService.register(userData)
    }

    // Disable the form
    this.signUpForm.disable();

    // Hide the alert
    this.showAlert = false;

    console.log('userData: ', userData)


    /*
      .subscribe(
        (response) => {

          // Navigate to the confirmation required page
          this._router.navigateByUrl('/confirmation-required');
        },
        (response) => {

          // Re-enable the form
          this.signUpForm.enable();

          // Reset the form
          this.signUpNgForm.resetForm();

          // Set the alert
          this.alert = {
            type   : 'error',
            message: 'Something went wrong, please try again.'
          };

          // Show the alert
          this.showAlert = true;
        }
      );
     */
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(void 0);
    this._unsubscribeAll.complete();
  }

}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get("password");
  const passwordConfirm = control.parent.get("passwordConfirm");

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === "") {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
