import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sociallink-hofgut',
    templateUrl: './sociallink-hofgut.component.html',
    styleUrls: ['./sociallink-hofgut.component.scss'],
    standalone: false
})
export class SociallinkHofgutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
