import { gql } from 'apollo-angular';
import { vehicleFragments } from './fragments';

export const vehicles = gql`
    query vehicles($orderBy: String, $limit: Int){
        vehicles(orderBy: $orderBy, limit: $limit){
            ...Vehicle
        }
    }
    ${vehicleFragments['vehicle']}
`;

export const vehiclesByParam = gql`
    query vehiclesByParam($param: String!, $orderBy: String, $limit: Int){
        vehiclesByParam(param: $param, orderBy: $orderBy, limit: $limit){
            ...Vehicle
        }
    }
    ${vehicleFragments['vehicle']}
`;

export const vehiclesByQuery = gql`
    query vehiclesByQuery($query: String!, $orderBy: String, $limit: Int){
        vehiclesByQuery(query: $query, orderBy: $orderBy, limit: $limit){
            ...Vehicle
        }
    }
    ${vehicleFragments['vehicle']}
`;


export const vehiclesByParametersAndQuery = gql`
    query vehiclesByParametersAndQuery($params: [String!], $query: StringOrInt!, $orderBy: String, $limit: Int){
        vehiclesByParametersAndQuery(params: $params, query: $query, orderBy: $orderBy, limit: $limit){
            ...Vehicle
        }
    }
    ${vehicleFragments['vehicle']}
`;

export const vehicle = gql`
    query vehicle($id: ObjectID!) {
        vehicle(id: $id) {
            ...Vehicle
        }
    }
    ${vehicleFragments['vehicle']}
`;
