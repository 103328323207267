import { gql } from 'apollo-angular';
import { eventFragments } from './fragments';

export const event = gql`
    query event($id: ObjectID!) {
        event(id: $id) {
            ...Event

            relatedEvents {
                ...Event
            }
        }
    }

    ${eventFragments['event']}
`;

export const events = gql`
    query events($orderBy: String, $limit: Int){
        events(orderBy: $orderBy, limit: $limit){
            ...Event

            relatedEvents {
                ...Event
            }
        }
    }

    ${eventFragments['event']}
`;

export const eventsByCategory = gql`
    query eventsByCategory($category: String, $orderBy: String, $limit: Int){
        eventsByCategory(category: $category, orderBy: $orderBy, limit: $limit){
            ...Event

            relatedEvents {
                ...Event
            }
        }
    }

    ${eventFragments['event']}
`;

export const eventsByArticle = gql`
    query eventsByArticle($article: ArticleInput, $orderBy: String, $limit: Int){
        eventsByArticle(article: $article, orderBy: $orderBy, limit: $limit){
            ...Event

            relatedEvents {
                ...Event
            }
        }
    }

    ${eventFragments['event']}
`;

export const eventsByArticleId = gql`
    query eventsByArticleId($articleId: String, $orderBy: String, $limit: Int){
        eventsByArticleId(articleId: $articleId, orderBy: $orderBy, limit: $limit){
            ...Event

            relatedEvents {
                ...Event
            }
        }
    }

    ${eventFragments['event']}
`;

export const eventsLatest = gql`
    query eventsLatest($limit: Int){
        eventsLatest(limit: $limit){
            ...Event

            relatedEvents {
                ...Event
            }
        }
    }

    ${eventFragments['event']}
`;

export const eventsLatestByCategory = gql`
    query eventsLatestByCategory($limit: Int, $category: String){
        eventsLatestByCategory(limit: $limit, category: $category){
            ...Event

            relatedEvents {
                ...Event
            }
        }
    }

    ${eventFragments['event']}
`;

export const eventDetail = gql`
    query eventDetail($id: ObjectID!) {
        event(id: $id) {
            ...Event


            relatedEvents {
                ...Event
            }
        }
    }

    ${eventFragments['event']}
`;
