import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-blumen',
    templateUrl: './blumen.component.html',
    styleUrls: ['./blumen.component.scss'],
    standalone: false
})
export class BlumenComponent implements OnInit {
    navClass = 'bg-white';
    filteredImages;
    galleryFilter = 'all';

    categoryList = [
        {
            title: 'April',
            type: 'april'
        },
        {
            title: 'Mai',
            type: 'mai'
        },
        {
            title: 'Juni',
            type: 'juni'
        },
        {
            title: 'Juli',
            type: 'juli'
        },
        {
            title: 'August',
            type: 'august'
        },
        {
            title: 'September',
            type: 'september'
        },
        {
            title: 'Oktober',
            type: 'oktober'
        },
    ];

    itemList = [
        {
            image: 'assets/images/blumen/narzisse.jpg',
            title: 'Narzisse',
            show: true,
            variant: 'warning',
            types: ['april'],
        },
        {
            image: 'assets/images/blumen/tulpe.jpg',
            title: 'Tulpe',
            show: true,
            variant: 'success',
            types: ['april', 'mai'],
        },
        {
            image: 'assets/images/blumen/nachtviole.jpg',
            title: 'Nachtviole',
            show: true,
            variant: 'warning',
            types: ['mai'],
        },
        {
            image: 'assets/images/blumen/zierlauch_allium.jpg',
            title: 'Zierlauch (Allium)',
            show: true,
            variant: 'success',
            types: ['mai', 'juni'],
        },
        {
            image: 'assets/images/blumen/zinnie_2.jpg',
            title: 'Zinnie',
            show: true,
            variant: 'warning',
            types: ['august', 'september'],
        },
        {
            image: 'assets/images/blumen/zinnie_3.jpg',
            title: 'Zinnie',
            show: true,
            variant: 'success',
            types: ['august', 'september'],
        },
        {
            image: 'assets/images/blumen/ringelblume_1.jpg',
            title: 'Ringelblume',
            show: false,
            variant: 'primary',
            types: ['juni'],
        },
        {
            image: 'assets/images/blumen/zierkamille.jpg',
            title: 'Zierkamille',
            show: false,
            variant: 'warning',
            types: ['juni'],
        },
        {
            image: 'assets/images/blumen/ringelblume_2.jpg',
            title: 'Ringelblume',
            show: false,
            variant: 'success',
            types: ['juni'],
        },
        {
            image: 'assets/images/blumen/scabiose.jpg',
            title: 'Scabiose',
            show: false,
            variant: 'primary',
            types: ['juli', 'august', 'september'],
        },
        {
            image: 'assets/images/blumen/lilie_1.jpg',
            title: 'Lilie',
            show: false,
            variant: 'warning',
            types: ['juli'],
        },
        {
            image: 'assets/images/blumen/ringelblume_3.jpg',
            title: 'Ringelblume',
            show: false,
            variant: 'primary',
            types: ['juni'],
        },
        {
            image: 'assets/images/blumen/muschelblume.jpg',
            title: 'Muschelblume',
            show: false,
            variant: 'warning',
            types: ['juli'],
        },
        {
            image: 'assets/images/blumen/lilie_2.jpg',
            title: 'Lilie',
            show: false,
            variant: 'success',
            types: ['juli'],
        },
        {
            image: 'assets/images/blumen/wachsblume.jpg',
            title: 'Wachsblume',
            show: false,
            variant: 'info',
            types: ['juni'],
        },
        {
            image: 'assets/images/blumen/sonnenblume.jpg',
            title: 'Sonnenblume',
            show: false,
            variant: 'primary',
            types: ['juli'],
        },
        {
            image: 'assets/images/blumen/dahlien_1.jpg',
            title: 'Dahlien',
            show: false,
            variant: 'primary',
            types: ['august', 'september'],
        },
        {
            image: 'assets/images/blumen/dahlien_2.jpg',
            title: 'Dahlien',
            show: false,
            variant: 'primary',
            types: ['august', 'september'],
        },
        {
            image: 'assets/images/blumen/glockenblume.jpg',
            title: 'Glockenblume',
            show: false,
            variant: 'primary',
            types: ['juni'],
        },
        {
            image: 'assets/images/blumen/faerberkamille.jpg',
            title: 'Färberkamille',
            show: false,
            variant: 'primary',
            types: ['juni'],
        },
        {
            image: 'assets/images/blumen/schmuckkoerbchen.jpg',
            title: 'Schmuckkörbchen',
            show: false,
            variant: 'primary',
            types: ['august', 'september'],
        },
        {
            image: 'assets/images/blumen/kornblume.jpg',
            title: 'Kornblume',
            show: false,
            variant: 'primary',
            types: ['juni', 'juli'],
        },
        {
            image: 'assets/images/blumen/magerite.jpg',
            title: 'Magerite',
            show: false,
            variant: 'primary',
            types: ['mai', 'juni'],
        },
        {
            image: 'assets/images/blumen/sommeraster.jpg',
            title: 'Sommeraster',
            show: false,
            variant: 'primary',
            types: ['juli', 'august', 'september'],
        },
        {
            image: 'assets/images/blumen/schachbrettblume.jpg',
            title: 'Schachbrettblume',
            show: false,
            variant: 'primary',
            types: ['april'],
        },
        {
            image: 'assets/images/blumen/sonnenhut.jpg',
            title: 'Sonnenhut',
            show: false,
            variant: 'primary',
            types: ['august', 'september'],
        },
        {
            image: 'assets/images/blumen/hasenohr.jpg',
            title: 'Hasenohr',
            show: false,
            variant: 'primary',
            types: ['juni'],
        },
    ];

    constructor() {
    }

    ngOnInit(): void {
        this.filteredImages = this.itemList;

    }

    activeCategory(category) {
        this.galleryFilter = category;
        if (this.galleryFilter === 'all') {
            this.filteredImages = this.itemList;
        } else {
            this.filteredImages = this.itemList.filter(x => x.types.includes(this.galleryFilter));
        }
    }

}
