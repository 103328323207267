import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';

/*
Registering Swiper Web Component
*/
import { register } from "swiper/element/bundle";
register();

@Component({
    selector: 'app-oeffnungszeiten',
    templateUrl: './oeffnungszeiten.component.html',
    styleUrls: ['./oeffnungszeiten.component.scss'],
    standalone: false
})
export class OeffnungszeitenComponent implements OnInit {
    navClass = 'bg-white';
    currentDate = new Date();
    date = new Date("2023-09-06T22:00:00.000Z")
    ferienBeginn = new Date("2024-08-04T22:00:00.000Z")
    ferienEnde = new Date("2024-09-08T22:00:00.000Z")
    jahresEnde = new Date("2024-12-31T22:00:00.000Z")

    sliderItems = [
        {
            image: '../../../assets/images/slider/slider_1.jpg'
        },
        {
            image: '../../../assets/images/slider/slider_2.jpg'
        },
        {
            image: '../../../assets/images/slider/slider_3.jpg'
        },
        {
            image: '../../../assets/images/slider/slider_4.jpg'
        },
        {
            image: '../../../assets/images/slider/slider_5.jpg'
        },
    ];
    oeffnungsItems = [
        {
            day:'Mittwoch',
            date: new Date("2023-09-05T22:00:00.000Z"),
            time1: 'geschlossen'
        },
        {
            day:'Mittwoch',
            date: new Date("2023-09-12T22:00:00.000Z"),
            time1: '09.00 bis 18.00 Uhr',
            text1: 'Kaffee & Kuchen, Frühstücksbuffet klein (09.00 bis 12.30)',
            time2: '11.00 bis 18.00 Uhr',
            text2: 'Verkauf von Backwaren und Kuchen, gesamtes Sortiment'
        },
        {
            day:'Samstag',
            date: new Date("2023-09-15T22:00:00.000Z"),
            time1: '09.00 bis 16.00 Uhr',
            text1: 'Frühstücksbuffet groß (09.00 bis 12.30), Kaffee & Kuchen',
            time2: '09.00 bis 16.00 Uhr',
            text2: 'Verkauf von Broten, Brötchen, Hefezopf und Kuchen, kleines Sortiment'
        },
        {
            day:'Mittwoch',
            date: new Date("2023-09-19T22:00:00.000Z"),
            time1: '09.00 bis 18.00 Uhr',
            text1: 'Kaffee & Kuchen, Frühstücksbuffet klein (09.00 bis 12.30)',
            time2: '11.00 bis 18.00 Uhr',
            text2: 'Verkauf von Backwaren und Kuchen, gesamtes Sortiment'
        },
        {
            day:'Mittwoch',
            date: new Date("2023-09-26T22:00:00.000Z"),
            time1: '09.00 bis 18.00 Uhr',
            text1: 'Kaffee & Kuchen, Frühstücksbuffet klein (09.00 bis 12.30)',
            time2: '11.00 bis 18.00 Uhr',
            text2: 'Verkauf von Backwaren und Kuchen, gesamtes Sortiment'
        },
        {
            day:'Samstag',
            date: new Date("2023-09-29T22:00:00.000Z"),
            time1: '09.00 bis 16.00 Uhr',
            text1: 'Frühstücksbuffet groß (09.00 bis 12.30), Kaffee & Kuchen',
            time2: '09.00 bis 16.00 Uhr',
            text2: 'Verkauf von Broten, Brötchen, Hefezopf und Kuchen, kleines Sortiment'
        },
        {
            day:'Mittwoch',
            date: new Date("2023-10-03T22:00:00.000Z"),
            time1: '09.00 bis 18.00 Uhr',
            text1: 'Kaffee & Kuchen, Frühstücksbuffet klein (09.00 bis 12.30)',
            time2: '11.00 bis 18.00 Uhr',
            text2: 'Verkauf von Backwaren und Kuchen, gesamtes Sortiment'
        },
        {
            day:'Mittwoch',
            date: new Date("2023-10-10T22:00:00.000Z"),
            time1: '09.00 bis 18.00 Uhr',
            text1: 'Kaffee & Kuchen, Frühstücksbuffet klein (09.00 bis 12.30)',
            time2: '11.00 bis 18.00 Uhr',
            text2: 'Verkauf von Backwaren und Kuchen, gesamtes Sortiment'
        },
        {
            day:'Samstag',
            date: new Date("2023-10-13T22:00:00.000Z"),
            time1: '09.00 bis 16.00 Uhr',
            text1: 'Frühstücksbuffet groß (09.00 bis 12.30), Kaffee & Kuchen',
            time2: '09.00 bis 16.00 Uhr',
            text2: 'Verkauf von Broten, Brötchen, Hefezopf und Kuchen, kleines Sortiment'
        },
        {
            day:'Mittwoch',
            date: new Date("2023-10-17T22:00:00.000Z"),
            time1: '09.00 bis 18.00 Uhr',
            text1: 'Kaffee & Kuchen, Frühstücksbuffet klein (09.00 bis 12.30)',
            time2: '11.00 bis 18.00 Uhr',
            text2: 'Verkauf von Backwaren und Kuchen, gesamtes Sortiment'
        },
        {
            day:'Mittwoch',
            date: new Date("2023-10-24T22:00:00.000Z"),
            time1: '09.00 bis 18.00 Uhr',
            text1: 'Kaffee & Kuchen, Frühstücksbuffet klein (09.00 bis 12.30)',
            time2: '11.00 bis 18.00 Uhr',
            text2: 'Verkauf von Backwaren und Kuchen, gesamtes Sortiment'
        },
        {
            day:'Samstag',
            date: new Date("2023-10-27T22:00:00.000Z"),
            time1: '09.00 bis 16.00 Uhr',
            text1: 'Frühstücksbuffet groß (09.00 bis 12.30), Kaffee & Kuchen',
            time2: '09.00 bis 16.00 Uhr',
            text2: 'Verkauf von Broten, Brötchen, Hefezopf und Kuchen, kleines Sortiment'
        },
    ];

    latestOeffnungsItems = this.oeffnungsItems.filter((value) => value.date > this.currentDate)


    /* public config: SwiperOptions = {
        a11y: {enabled: true},
        direction: 'horizontal',
        autoplay: {
            delay: 8000,
        },
        speed: 700,
        slidesPerView: 1,
        keyboard: true,
        navigation: true,
        pagination: false
    };
    */

    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: false,
        pullDrag: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            900: {
                items: 3
            }
        },
        nav: false
    };


    constructor() {
        console.log('Date: ', this.currentDate);
    }

    ngOnInit(): void {
    }

}


